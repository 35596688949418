import React from "react";

import { observer, inject } from "mobx-react";
import FieldsInfo from "./FieldsInfo";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import Table from "~/common/components/Table";

@inject("userStore", "employeesStore", "pointsStore", "auditStore")
@observer
class PageInfo extends React.Component {
  columns = [
    {
      Header: "ID",
      accessor: "id", // String-based value accessors!
      show: false,
      width: "3em",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Audit",
      accessor: "allCorrect",
      Cell: this.renderCell,
    },
  ];

  renderCell({ cell }) {
    const { value } = cell;
    return (
      <div className={value ? "audit-all-correct" : "audit-not-correct"}>
        {value ? <IoIosCheckmarkCircleOutline /> : <IoIosCloseCircleOutline />}
      </div>
    );
  }

  render() {
    return (
      <div className="quest-info-holder">
        <Table
          sortable={true}
          multiSort={true}
          data={this.props.data}
          defaultPageSize={100}
          columns={this.columns}
          showPagination={false}
          SubComponent={({ row }) => {
            return (
              <FieldsInfo
                data={row.original.fields}
                renderCell={this.props.renderCell}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default PageInfo;
