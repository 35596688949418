import { observable, action } from "mobx";

import Server from "~/api/Server";

import SettingsStore from "./SettingsStore";
import UserStore from "~/common/stores/UserStore";
import PlanStore from "~/common/stores/PlanStore";
import EmployeesStore from "~/modules/employees/data/stores/EmloyeesStore";
import PointsStore from "~/modules/points/data/stores/PointsStore";
import MapStore from "~/modules/map/data/stores/MapStore";
import PlanningStore from "~/modules/planning/data/stores/PlanningStore";
import AuditsStore from "~/modules/audits/data/stores/AuditsStore";
import DataViewerStore from "~/modules/dataViewer/data/stores/DataViewerStore";
import MonitoringViewerStore from "~/modules/monitoringViewer/data/stores/MonitoringViewerStore";
import QuestStore from "./QuestStore";
import DirStore from "./DirStore";
import MonStore from "./MonStore";
import JournalsStore from "~/modules/journals/data/stores/JournalsStore";
import ActionPlanStore from "~/modules/actionPlan/data/stores/ActionPlanStore";
import { ProfilesStore } from "~/modules/profiles";

/**
 * Application root store.
 */
export default class RootStore {
  @observable module;

  constructor() {
    this.settings = new SettingsStore(this);
    this.api = new Server(this);
    this.employees = new EmployeesStore(this);
    this.points = new PointsStore(this);
    this.user = new UserStore(this);
    this.planStore = new PlanStore(this);
    this.mapStore = new MapStore(this);
    this.planningStore = new PlanningStore(this);
    this.auditStore = new AuditsStore(this);
    this.dataViewerStore = new DataViewerStore(this);
    this.monitoringViewerStore = new MonitoringViewerStore(this);
    this.questStore = new QuestStore(this);
    this.dirStore = new DirStore(this);
    this.monStore = new MonStore(this);
    this.journalsStore = new JournalsStore(this);
    this.actionPlanStore = new ActionPlanStore(this);
    this.profilesStore = new ProfilesStore(this);
  }

  @action
  setModule(moduleString) {
    this.module = moduleString;
  }
}
