import React from "react";

/**
 * Loading (pending) indicator.
 */
export default function Loading() {
  return (
    <div className="points-preloader">
      <div />
    </div>
  );
}
