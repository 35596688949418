import { observable, action, computed } from "mobx";
import ActionPlan from "../models/ActionPlan";
import Activity from "../models/Activity";
import Answer from "../models/Answer";

export default class ActionPlanStore {
  @observable isPendingData = false;
  @observable pageIsLoading = false;
  @observable activitiesMap = new Map();
  @observable actionPlanMap = new Map();
  @observable answersMap = new Map();
  @observable activitiesMap = new Map();
  @observable activity = null;

  @observable startDate = null;
  @observable endDate = null;

  @observable selectedEmployeeIds = [];
  @observable selectedPointIds = [];

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.setPendingData(true);
    await this.getActivities();
    this.setPendingData(false);
  }

  @action
  setPendingData(pending = false) {
    this.isPendingData = pending;
  }

  @action
  setPagePending(pending = false) {
    this.pageIsLoading = pending;
  }

  @action
  async getActivities() {
    const activitiesData = await this.api.getActivities();
    this.processActivities(activitiesData);
  }

  @action
  processActivities(activitiesData) {
    activitiesData.forEach((itemData) => {
      const activity = new Activity(itemData, this);
      this.activitiesMap.set(activity.id, activity);
    });
  }

  @action
  setDates({ startDate, endDate }) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  setEmployee({ id }) {
    this.selectedEmployeeIds = [id];
  }

  @action
  setPoint({ id }) {
    this.selectedPointIds = [id];
  }

  @action
  changeActivity(activity) {
    this.activity = activity;
  }

  @action
  async loadPage() {
    this.setPagePending(true);
    try {
      const data = await this.api.loadAnswers({
        from:        this.startDate,
        to:          this.endDate,
        employeeIds: this.selectedEmployeeIds,
        pointIds:    this.selectedPointIds,
        activityId:  this.activity && this.activity.id,
      });
      this.processAnswers(data);
    } catch (error) {
      console.warn(error);
    }
    this.setPagePending(false);
  }

  @action
  processAnswers(data) {
    this.actionPlanMap.clear();
    this.answersMap.clear();
    data.forEach((planData) => {
      const newPlan = new ActionPlan(planData, this);
      this.setActionPlan(newPlan);
      planData.answers.forEach((answerData) => {
        const newAnswer = new Answer(answerData, this);
        this.setAnswer(newAnswer);
      });
    });
  }

  @action
  setActionPlan(plan) {
    this.actionPlanMap.set(plan.id, plan);
  }

  @action
  getActionPlanById(id) {
    return this.actionPlanMap.get(id);
  }

  @action
  getActivityById(id) {
    return this.activitiesMap.get(id);
  }

  @action
  getAnswerById(id) {
    return this.answersMap.get(id);
  }

  @action
  setAnswer(answer) {
    this.answersMap.set(answer.id, answer);
  }

  @computed
  get activitiesArray() {
    const array = [];
    this.activitiesMap.forEach((activity) => {
      array.push(activity);
    });
    return array;
  }

  @computed
  get plansArray() {
    const array = [];
    this.actionPlanMap.forEach((plan) => {
      array.push(plan);
    });
    return array;
  }
}
