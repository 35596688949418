import { observable, action, computed } from "mobx";

export default class GalleryStore {
  @observable pending = true;
  @observable pagePending = true;
  @observable params = new Map();
  @observable paramsSelected = new Map();
  @observable items = new Map();
  @observable data;
  @observable config;
  @observable employee = null;
  @observable startDate;
  @observable endDate;
  @observable selectedPointIds = [];

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  setPending(pending) {
    this.pending = pending;
  }

  @action
  setPagePending(pending) {
    this.pagePending = pending;
  }

  @action
  init() {
    if (this.rootStore.user.isLoggedIn) {
      this.fetchParameters();
    }
  }

  @action
  processParams(data) {
    data &&
      data.parameters &&
      data.parameters.forEach((param) => {
        this.params.set(`${param.id}`, {
          ...param,
          type: "file",
        });
      });
  }

  @action
  setPoint({ id }) {
    this.selectedPointIds = [id];
  }

  @action
  setParam(id) {
    if (this.paramsSelected.get(`${id}`)) {
      this.paramsSelected.delete(`${id}`);
    } else {
      this.paramsSelected.set(`${id}`, true);
    }
  }

  @action
  setAllParams() {
    if (this.params.size === this.paramsSelected.size) {
      this.paramsSelected.clear();
    } else {
      this.paramsSelected.clear();

      this.params.forEach((param) => {
        this.setParam(param.id);
      });
    }
  }

  @action
  async fetchParameters() {
    this.setPending(true);
    const paramsData = await this.api.getPhotoParams();
    this.processParams(paramsData);
    this.setPending(false);
  }

  @action
  async fetchData() {
    this.setPending(true);
    this.items.clear();
    let points = null;
    if (this.selectedPointIds.length) {
      points = [];
      this.selectedPointIds.forEach((id) => {
        const point = this.rootStore.points.get(id);
        if (point) {
          points.push(point.code);
        }
      });
    }
    const from = this.startDate.toISOString();
    const to = this.endDate.toISOString();
    const params = Array.from(this.paramsSelected.keys());
    const employees = this.employee ? [this.employee.id] : null;
    const photosData = await this.api.getPhotos(
      from,
      to,
      params,
      employees,
      points
    );

    if (photosData && photosData.images) {
      photosData.images.forEach((item) => {
        this.items.set(`${item.id}`, item);
      });
    }
    this.setPending(false);
  }

  @action
  setDates({ startDate, endDate }) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  changeEmployee(employee) {
    this.employee = employee;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get isPagePending() {
    return this.pagePending;
  }

  @computed
  get isPendingForm() {
    return true;
  }

  @computed
  get canRequest() {
    return !!this.startDate && !!this.endDate && !this.isPending;
  }

  @computed
  get paramsArray() {
    const array = [];
    this.params.forEach((param) => {
      array.push({
        ...param,
        isSelected: !!this.paramsSelected.get(`${param.id}`),
      });
    });
    return array;
  }

  @computed
  get itemsArray() {
    return Array.from(this.items.values());
  }
}
