import { observable } from "mobx";

/**
 * Fact Model.
 */
export default class AuditFactReport {
  auditFactId;
  createdAt;
  id;
  questId;
  reportId;
  @observable values = new Map();

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = data.id;
    this.auditFactId = data.auditFactId;
    this.questId = data.questId;
    this.reportId = data.reportId;
    // this.values;
    const vals = {};
    data.values.forEach((val) => {
      vals[Number(val.parameter.id)] = val;
    });
    this.values.replace(vals);
  }
}
