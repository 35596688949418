import React from "react";
import { observer, inject } from "mobx-react";

import {
  IoMdInformationCircle,
  IoMdList,
  IoMdKeypad,
  IoMdCreate,
  IoMdSwitch,
  IoMdImage,
  IoMdDoneAll,
  IoMdRadioButtonOn,
  IoMdCalendar,
  IoMdBulb,
} from "react-icons/io";

import CheckboxTable from "~/common/components/CheckboxTable";

import "react-tabs/style/react-tabs.css";

@inject("monitoringViewerStore")
@observer
class ParamsCheckboxTable extends React.Component {
  columns = [
    {
      Header: "ID",
      accessor: "id",
      width: 0,
    },
    {
      Header: (
        <div className="base-header">
          <IoMdBulb />
        </div>
      ),
      id: "type",
      accessor: (d) => {
        let type = d.type;
        if (type === "list") {
          type = d.viewConfig;
        }
        switch (type) {
          case "linked":
          case "list_db":
            return <IoMdInformationCircle />;
          case "number":
            return <IoMdKeypad />;
          case "text":
            return <IoMdCreate />;
          case "selectfield":
            return <IoMdList />;
          case "multiselect":
            return <IoMdDoneAll />;
          case "radiogroup":
            return <IoMdRadioButtonOn />;
          case "file":
            return <IoMdImage />;
          case "boolean":
            return <IoMdSwitch />;
          case "date":
            return <IoMdCalendar />;
          default:
            return type;
        }
      },
      sortType: (a, b, colId, desc) => {
        let typea = a.original.type;
        if (typea === "list") {
          typea = a.original.viewConfig;
        }
        let typeb = b.original.type;
        if (typeb === "list") {
          typeb = b.original.viewConfig;
        }
        if (desc) {
          return typeb.localeCompare(typea);
        }
        return typea.localeCompare(typeb);
      },
      maxWidth: 20,
      width: 20,
      textAlign: "center",
    },
    {
      Header: "Parameter",
      accessor: "name",
    },
  ];
  constructor(props) {
    super(props);

    this.toggleSelection = this.toggleSelection.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.toggleAll = this.toggleAll.bind(this);

    this.state = { focusedInput: undefined };
  }

  toggleSelection(row) {
    this.props.monitoringViewerStore.setParam(row.id);
  }

  toggleAll() {
    this.props.monitoringViewerStore.setAllParams();
  }

  isSelected(key) {
    const fact = this.props.monitoringViewerStore.params.get(key);
    return !!fact;
  }

  render() {
    const { monitoringViewerStore } = this.props;
    const checkboxProps = {
      toggleSelection: this.toggleSelection,
      toggleAll: this.toggleAll,
    };

    return (
      <CheckboxTable
        data={monitoringViewerStore.paramsArray}
        columns={this.columns}
        hiddenColumns={["id"]}
        {...checkboxProps}
      />
    );
  }
}

export default ParamsCheckboxTable;
