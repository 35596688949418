import { observable, action } from "mobx";

export default class SettingsStore {
  static MINIMUM_FONTSIZE = 8;
  static DEFAULT_FONTSIZE = 10;
  static LOCALSTORAGE_FONTSIZE_KEY = "URT.Manager.settings.fontSize";
  @observable fontSize = 10;

  constructor(root) {
    this.root = root;
  }

  @action
  setFontSizeDefault() {
    const defaultFontSize =
      parseInt(localStorage.getItem("URT.Manager.settings.fontSize"), 10) ||
      SettingsStore.DEFAULT_FONTSIZE;
    this.setFontSize(defaultFontSize);
    document.body.style.fontSize = `${this.fontSize}px`; // TODO: move to reaction!
    localStorage.setItem(
      SettingsStore.LOCALSTORAGE_FONTSIZE_KEY,
      this.fontSize
    );
  }

  @action
  setFontSize(size) {
    this.fontSize = size;
  }

  /**
   * Changes the font size by a step.
   *
   * @param {number} step - шаг изменения шрифта.
   * @returns null
   */
  @action
  changeFontSize(step) {
    const fontSize = this.fontSize;
    let newFontSize = fontSize + step;
    if (newFontSize <= this.MINIMUM_FONTSIZE) {
      newFontSize = this.MINIMUM_FONTSIZE;
    }
    document.body.style.fontSize = `${newFontSize}px`;
    this.setFontSize(newFontSize);
    localStorage.setItem(SettingsStore.LOCALSTORAGE_FONTSIZE_KEY, newFontSize);
  }
}
