import React from "react";
import "react-dates/initialize";
import { Provider } from "mobx-react";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import RootStore from "~/common/stores/RootStore";

import Main from "./Main";

import "react-dates/lib/css/_datepicker.css";
import "~/App.scss";

import { configure } from "mobx";
configure({ enforceActions: "observed" }); // allow change state only inside @action!

class App extends React.Component {
  constructor(props) {
    super(props);
    this.rootStore = new RootStore();
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider
          rootStore={this.rootStore}
          settingsStore={this.rootStore.settings}
          userStore={this.rootStore.user}
          planningStore={this.rootStore.planningStore}
          employeesStore={this.rootStore.employees}
          pointsStore={this.rootStore.points}
          monStore={this.rootStore.monStore}
          mapStore={this.rootStore.mapStore}
          auditStore={this.rootStore.auditStore}
          questStore={this.rootStore.questStore}
          dataViewerStore={this.rootStore.dataViewerStore}
          monitoringViewerStore={this.rootStore.monitoringViewerStore}
          journalsStore={this.rootStore.journalsStore}
          actionPlanStore={this.rootStore.actionPlanStore}
          profilesStore={this.rootStore.profilesStore}
        >
          <Main />
        </Provider>
      </DndProvider>
    );
  }
}

export default App;
