import React from "react";
import Select from "react-select";
import { IoMdFolderOpen } from "react-icons/io";

import Button from "~/common/components/Button";
import EmployeeTree from "./EmployeeTree";
/**
 * Employees Select Box
 */
class EmployeeAutocompleteSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onToggleTree = this.onToggleTree.bind(this);
    this.state = {};
  }

  onToggleTree() {
    this.setState({
      isTree: !this.state.isTree
    });
  }

  render() {
    const { value, onChange, options } = this.props;
    const { isTree } = this.state;
    return (
      <div className="empl-holder">
        <div className="empl-select-holder">
          <Select
            className="select-user"
            name="employee"
            placeholder="Select employee..."
            value={value}
            onChange={onChange}
            options={options}
          />
          <Button
            className={`button inline solo ${isTree ? "active" : ""}`}
            onClick={this.onToggleTree}
            leftIcon={<IoMdFolderOpen />}
          />
        </div>
        {isTree && (
          <div className="empl-tree-holder">
            <EmployeeTree onSelect={onChange} selectedIds={[value]} />
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeAutocompleteSelect;
