import { computed, observable, action, toJS, reaction } from "mobx";
import { uuid } from "uuidv4";
import moment from "moment";

import Parameter from "./Parameter";
import Value from "./Value";
/**
 * Field Model.
 */
export default class Field {
  id = uuid();
  @observable param;
  @observable image;
  @observable val;
  @observable loading = false;
  @observable reverse = false;
  @observable parent;
  @observable lineIndex;
  store;

  constructor(param, store, parent, lineIndex) {
    this.store = store;
    this.parent = parent;
    this.lineIndex = lineIndex;
    if (param.id) {
      this.id = `${param.id}`;
    }

    this.changeValue = this.changeValue.bind(this);

    this.param = new Parameter(param, store);
    this.reverse  = param.isReverse;

    if (this.type === "file") {
      reaction(() => {
        return this.value;
      }, () => {
        this.loadImage(); 
      });
    }
  }

  @action
  setValue(value = null) {
    let val = value;
    if (this.type === "date" && val) {
      val = moment(value);
    }
    if (this.type === "boolean") {
      val = !!value;
    }
    if (this.type === "table" && value === null) {
      val = [];
    }
    this.val = new Value(val, this.store);
    if (this.type === "file" && val) {
      this.loadImage();
    }
  }

  @action
  setIsLoading(loading = false) {
    this.loading = loading;
  }

  @action
  toggleReverse() {
    this.reverse = !this.reverse;
  }

  @action
  async uploadFile(file) {
    this.setIsLoading(true);
    try {
      const fileData = await this.store.api.uploadImage(file);
      const fileName = file.name;
      const value = fileData.data.files[fileName];
      this.changeValue(value);
    } catch (error) {
      console.warn(error);
    }
    this.setIsLoading(false);
    this.loadImage();
  }
  
  async loadImage() {
    this.setIsLoading(true);
    try {
      const image = await this.store.api.loadImage(this.value);
      this.setImage(image);
    } catch (error) {
      console.warn(error);
    }
    this.setIsLoading(false);
  }

  @action
  setImage(image) {
    this.image = image;
  }

  @action
  changeValue(value = null) {
    if (this.val) {
      this.val.change(value);
    } else {
      this.setValue(value);
    }
    if (this.parent) {
      this.parent.changeTableValues && this.parent.changeTableValues(this.lineIndex, this.id, value);
    }
  }

  @action
  save() {
    if (this.val) {
      this.val.save();
    }
  }

  @action
  changeTableValues(lineIndex, id, value) {
    const newValue = [...this.value.slice()];
    newValue[lineIndex] = { ...newValue[lineIndex], [id]: value };
    this.changeValue(newValue);
  }

  @action
  onCancel() {
    this.val && this.val.revert();
  }

  @action
  onAddLine(top) {
    const newObj = {};
    this.columns.forEach((col) => {
      newObj[col.id] = null;
    });
    let newVal = [...this.value, newObj];
    if ((top || this.isReverse) && !(top && this.isReverse)) {
      newVal = [newObj, ...this.value];
    }
    this.changeValue(newVal);
  }

  @action
  onDeleteLine(index) {
    const newVal = [];
    this.value.forEach((col, i) => {
      if (i !== index) {
        newVal.push(col);
      }
    });
    this.changeValue(newVal);
  }

  @computed
  get label() {
    return this.param && this.param.label;
  }

  @computed
  get validation() {
    return this.param.validate(this.value);
  }

  @computed
  get value() {
    return this.val && this.val.temp;
  }

  @computed
  get isReverse() {
    return this.reverse;
  }

  @computed
  get columns() {
    return this.param && this.param.columns;
  }

  @computed
  get tableData() {
    // от value [{}...{}] - массив строк
    const data = [];
    const value = this.value.slice();
    if (this.isReverse) {
      value.reverse();
    }
    value.forEach((line, i) => {
      const lineIndex = this.isReverse ? value.length - i - 1 : i;
      const lineOutput = {};
      this.columns.forEach((param) => {
        const id = param.id;
        const value = line[id];
        if (param) {
          const field = new Field(param, this.store, this, lineIndex);
          field.setValue(value);
          lineOutput[id] = field;
        }
      });
      data.push(lineOutput);
    });
    return data;
  }

  @computed
  get isLoading() {
    return this.loading;
  }

  @computed
  get output() {
    let output = toJS(this.value);
    if (this.type === "date") {
      output = this.value && this.value.toISOString();
    }
    if (output === undefined) {
      output = null;
    }
    return output;
  }

  @computed
  get isValid() {
    if (this.type === "table") {
      let valid = true;
      this.tableData.forEach((line) => {
        Object.keys(line).forEach((id) => {
          const field = line[id];
          valid = valid && field.isValid;
        });
      });
      return valid;
    }
    return this.validation && this.validation.valid;
  }

  @computed
  get cause() {
    return this.validation && this.validation.cause;
  }

  @computed
  get type() {
    return this.param && this.param.fieldType;
  }

  @computed
  get viewConfig() {
    return this.param && this.param.viewConfig;
  }

  @computed
  get required() {
    return this.param && this.param.required;
  }

  @computed
  get isDisabled() {
    return this.param && this.param.readOnly;
  }

  @computed
  get isChanged() {
    return this.val && this.val.isChanged;
  }
}
