import React, { useState, useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import moment from "moment";

import Event, { computeHeight, computeTop, yToDuration } from "./Event";

import { Plan } from "~/modules/planning/data/models";

const DayDrop = ({
  events,
  startOfDay,
  finishOfDay,
  day,
  employee,
  eventTimeStep,
  minEventDuration,
  planningStore,
}) => {
  const [height, setHeight] = useState(0);
  const [componentTop, setTop] = useState(0);
  const [previewPoint, setPreviewPoint] = useState();
  const [, setPreviewDate] = useState();
  const [previewStart, setPreviewStart] = useState();
  const [previewFinish, setPreviewFinish] = useState();

  const container = useRef(null);

  const computeDuration = (startOfDay, finishOfDay) => {
    if (startOfDay > finishOfDay) {
      return 0;
    }
    return moment.duration(finishOfDay.diff(startOfDay)).asMinutes();
  };

  const collect = (monitor) => {
    return {
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      itemType: monitor.getItemType(),
    };
  };

  const canDrop = () => {
    if (employee) {
      return true;
    } else {
      return false;
    }
  };

  const hover = (item, monitor) => {
    let newDate;
    let newStart;
    let newFinish;
    let newPoint;
    const dayDuration = computeDuration(startOfDay, finishOfDay);
    if (item) {
      // Притащили существующий план
      const top = monitor.getSourceClientOffset().y - componentTop;
      const newStartMinutes = yToDuration(
        top,
        dayDuration,
        eventTimeStep,
        height
      );
      if (item.event instanceof Plan) {
        const event = item.event;
        newPoint = event.point;
        const start = event.start ? event.start : item.startOfDay;
        const finish = event.finish ? event.finish : item.finishOfDay;
        const duration = finish.diff(start, "m");
        newDate = moment(startOfDay);
        newStart = moment(startOfDay).add(newStartMinutes, "m");
        newFinish = moment(newStart).add(duration, "m");
      } else {
        // притащили Point, хотим сделать новый план
        newPoint = item.item || item.point;
        const duration = 60;
        newStart = moment(startOfDay).add(newStartMinutes, "m");
        newFinish = moment(newStart).add(duration, "m");
      }
    }

    setPreviewPoint(newPoint);
    setPreviewDate(newDate);
    setPreviewStart(newStart);
    setPreviewFinish(newFinish);
  };

  const drop = (item, monitor) => {
    if (monitor.didDrop()) {
      return;
    }
    // Притащили существующий план
    const top = monitor.getSourceClientOffset().y - componentTop;
    const dayDuration = computeDuration(startOfDay, finishOfDay);
    const newStartMinutes = yToDuration(
      top,
      dayDuration,
      eventTimeStep,
      height
    );
    const newStart = moment(startOfDay).add(newStartMinutes, "m");

    if (item.event instanceof Plan) {
      const event = item.event;

      if (event.date !== day) {
        event.changeDate(day);
      }
      const start = event.start ? event.start : item.startOfDay;
      const finish = event.finish ? event.finish : item.finishOfDay;
      const duration = finish.diff(start, "m");
      const newFinish = moment(newStart).add(duration, "m");
      event.changeTime(newStart, newFinish);
      event.save();
    } else {
      // притащили Point, хотим сделать новый план
      const duration = 60;
      const newPoint = item.item || item.point;
      const newFinish = moment(newStart).add(duration, "m");
      planningStore.addPlan(newPoint, day, newStart, newFinish);
    }

    return { moved: true };
  };

  const onResize = () => {
    setHeight(container.current.clientHeight);
    setTop(container.current.offsetTop);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    setHeight(container.current.clientHeight);
    setTop(container.current.offsetTop);
  }, []); // [] - fires only on Mount, if undefined - fires every render, if props array fires on props change

  const [collectedProps, dropRef] = useDrop({
    accept: ["POINT_ITEM", "EVENT_ITEM"],
    drop,
    hover,
    canDrop,
    collect,
  });

  const { isOver, canDrop: canDropHere } = collectedProps;

  const dayDuration = computeDuration(startOfDay, finishOfDay);
  const content = events.map((event, i) => {
    return (
      <Event
        key={i}
        event={event}
        minDuration={minEventDuration}
        dayHeight={height}
        startOfDay={startOfDay}
        finishOfDay={finishOfDay}
        dayDuration={dayDuration}
        step={eventTimeStep}
      />
    );
  });

  if (isOver && previewStart && previewFinish) {
    content.push(
      <div
        key={content.length}
        className={`plan-item preview ${!employee ? "red" : ""}`}
        style={{
          position: "absolute",
          left: 0,
          width: "95%",
          margin: ".25rem",
          height: computeHeight(
            previewStart,
            previewFinish,
            dayDuration,
            height
          ),
          top: computeTop(
            startOfDay,
            previewStart,
            dayDuration,
            height,
            previewStart
          ),
        }}
      >
        <div className="draggable-content">
          <div className="planning-event-time-start">
            {previewStart ? previewStart.format("HH:mm") : "Whole day"}
          </div>
          <div className="planning-point-label">{previewPoint.name}</div>
          <div className="planning-point-label">{previewPoint.code}</div>
          <div className="planning-event-time-finish">
            {previewFinish ? previewFinish.format("HH:mm") : "Whole day"}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`day-content ${isOver && canDropHere ? "can-drop" : ""}`}
      ref={container}
    >
      <div ref={dropRef} className={"drop-target-day"}>
        {content}
      </div>
    </div>
  );
};

export default DayDrop;
