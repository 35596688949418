import React, { useMemo } from "react";
import Select from "react-select";
import { IoMdList } from "react-icons/io";
import { observer } from "mobx-react";

const SelectField = observer((props) => {
  const { config } = props;
  const { label, value, isValid, changeValue, isDisabled, param, viewConfig, required } = config;
  const { options } = param;
  const isMulti = viewConfig === "multiselect";

  const onChange = (value) => {
    changeValue(isMulti ? value : [value]);
  };
  
  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  return (
    <div className={`input-div ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          <IoMdList />
        </div>
        <Select
          isMulti={isMulti}
          isClearable={!required}
          isDisabled={isDisabled}
          className="select-field"
          classNamePrefix="select"
          name="id"
          placeholder="Select item..."
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
});

export default SelectField;
