import React from "react";
import { observer, inject } from "mobx-react";
import Zoom from "react-medium-image-zoom";

import "react-medium-image-zoom/dist/styles.css";

@inject("rootStore")
@observer
class Photo extends React.Component {
  constructor(props) {
    super(props);

    this.loadThumb = this.loadThumb.bind(this);

    this.state = {
      image:   undefined,
      thumb:   undefined,
      loading: true,
    };

    this.loadThumb();
  }

  async loadThumb() {
    const { id, rootStore } = this.props;
    const { api } = rootStore;

    const image = await api.loadImage(id);

    this.setState({
      loading: false,
      image,
    });
  }

  render() {
    const { id, name, author, datetime, pos } = this.props;
    const { loading, image } = this.state;
    if (loading) {
      return (
        <div key={id} className="feed-image-card">
          <div className={`feed-image ${name ? "" : "solo"}`}>
            <div className="points-preloader">
              <div />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div key={id} className="feed-image-card">
        <div className={`feed-image ${name ? "" : "solo"}`}>
          <Zoom zoomMargin={40}>
            <img
              src={URL.createObjectURL(image)}
              alt={name}
              className="img"
              style={{
                width:          "20em",
                height:         "20em",
                display:        "flex",
                borderRadius:   "0.5em 0.5em 0 0",
                backgroundSize: "cover",
                flexShrink:     0,
              }}
            />
          </Zoom>
        </div>
        {name && <div className="card-title">{name}</div>}
        {author && <div className="card-author">{author}</div>}
        {datetime && <div className="card-datetime">{datetime}</div>}
        {pos && <div className="card-pos">{pos}</div>}
      </div>
    );
  }
}

export default Photo;
