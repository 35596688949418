import { observable, action } from "mobx";

/**
 * Fact Model.
 */
export default class MonitoringFactItem {
  id;

  @observable values = new Map();

  constructor(id, store) {
    this.store = store;

    this.id = id;
  }

  @action
  addValue(data) {
    const val = {
      valueId: data.id,
      type: data.parameter.type,
      id: data.parameter.id,
      value: data.parameter.value,
    };
    this.values.set(val.id, val);
  }
}
