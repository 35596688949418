import React from "react";
import { IoIosArrowDown, IoIosArrowForward, IoIosRadioButtonOff } from "react-icons/io";

class TreeItem extends React.Component {
  constructor(props) {
    super(props);

    this.onToggleExpanded = this.onToggleExpanded.bind(this);

    this.state = {
      isExpanded: false,
    };
  }

  onToggleExpanded() {
    const { item } = this.props;
    if (item.children) {
      this.setState({
        isExpanded: !this.state.isExpanded,
      });
    }
  }

  iconRender() {
    const { item, iconRender, isSearching } = this.props;
    const render = iconRender && iconRender(item);
    let icon = render || <IoIosRadioButtonOff />;
    const { isExpanded } = this.state;
    const expanded = isExpanded || isSearching;
    if (item.children) {
      icon = expanded ? <IoIosArrowDown /> : <IoIosArrowForward />;
    }
    return icon;
  }

  render() {
    const { item, itemRender, iconRender, isSearching } = this.props;
    const { isExpanded } = this.state;
    const { name, id } = item;
    const expanded = isExpanded || isSearching;
    const children = [];
    if (item.children) {
      item.children.forEach((child) => {
        if (child && (!child.children || child.children.length > 0)) {
          children.push(
            <TreeItem
              isSearching={isSearching}
              key={child.id || child.name}
              item={child}
              itemRender={itemRender}
              iconRender={iconRender}
            />
          );
        }
      });
    }
    const icon = this.iconRender();
    return (
      <div key={id || name} className={"tree-item box"}>
        <div className="item-content">
          <div onClick={this.onToggleExpanded} className="icon-holder">
            {icon}
          </div>
          {itemRender(item)}
        </div>
        {expanded && children.length > 0 && <div className="children">{children}</div>}
      </div>
    );
  }
}

export default TreeItem;
