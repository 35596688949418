import React from "react";

import {
  IoMdClose,
  IoMdPerson,
  IoMdPin,
  IoMdCheckmarkCircle,
} from "react-icons/io";

import moment from "moment";

import { observer, inject } from "mobx-react";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Tooltip,
} from "react-leaflet";
import L from "leaflet";

import { DayPicker, Button } from "~/common/components";
import EmployeeAutocompleteSelect from "~/modules/employees/components/EmployeeAutocompleteSelect";

import { stringifyDateRU } from "~/common/datetime";
import Plan from "~/common/models/Plan";
import Fact from "~/common/models/Fact";

const dayStart = "7:30";
const dayEnd = "20:15";
const dayHeight = 85;
const colors = [
  "#c24b43",
  "#699a4e",
  "#83d1d2",
  "#4371c5",
  "#8b5488",
  "#9C7A97",
  "#C0DA74",
  "#192A51",
];

@inject("rootStore", "mapStore", "employeesStore")
@observer
class UrtMap extends React.Component {
  static svg(type, style, index) {
    const item = {
      flag:
        "<path d=\"M18.383,4.318c-0.374-0.155-0.804-0.069-1.09,0.217c-1.264,1.263-3.321,1.264-4.586,0c-2.045-2.043-5.37-2.043-7.414,0  C5.105,4.722,5,4.977,5,5.242v13c0,0.552,0.447,1,1,1s1-0.448,1-1v-4.553c1.271-0.997,3.121-0.911,4.293,0.26  c2.045,2.043,5.371,2.043,7.414,0C18.895,13.761,19,13.507,19,13.242v-8C19,4.837,18.756,4.473,18.383,4.318z\"/>",
      pin:
        "<path d=\"M17.657,5.304c-3.124-3.073-8.189-3.073-11.313,0c-3.124,3.074-3.124,8.057,0,11.13L12,21.999l5.657-5.565  C20.781,13.361,20.781,8.378,17.657,5.304z M12,13.499c-0.668,0-1.295-0.26-1.768-0.732c-0.975-0.975-0.975-2.561,0-3.536  c0.472-0.472,1.1-0.732,1.768-0.732s1.296,0.26,1.768,0.732c0.975,0.975,0.975,2.562,0,3.536C13.296,13.239,12.668,13.499,12,13.499  z\"/>",
    };
    return `<div class="marker"><div class="marker-index ${type}">${index}</div><svg class="icon" stroke-width="0.3" style="${style}" version="1.2" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">${item[type]}</svg></div>`;
  }

  constructor(props) {
    super(props);

    // this.makeBounds = this.makeBounds.bind(this);
    this.makeInfo = this.makeInfo.bind(this);
    this.makeFactMarker = this.makeFactMarker.bind(this);
    this.makePlanMarker = this.makePlanMarker.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderFacts = this.renderFacts.bind(this);
    this.renderPlans = this.renderPlans.bind(this);
    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.makeMapData = this.makeMapData.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.setCurrentPoint = this.setCurrentPoint.bind(this);
    this.checkMonth = this.checkMonth.bind(this);
    this.isDayBlocked = this.isDayBlocked.bind(this);

    this.markerRefs = {};

    props.mapStore.getBlockedDays(moment());

    this.state = {
      modalIsVisible: false,
      point:          undefined,
    };
  }

  renderButton(user, userInterval, index) {
    const color = colors[index];

    return (
      <div
        key={`${user.id}-${userInterval.interval.string}`}
        className="card"
        onClick={(e) => {
          e.stopPropagation();
          this.props.mapStore.setCurrentInterval(
            user,
            userInterval.interval.string
          );
        }}
      >
        <div className="card-container">
          <div className="date" style={{ backgroundColor: color }}>
            {user.fullName} {stringifyDateRU(userInterval.interval.from)}
          </div>
        </div>
      </div>
    );
  }

  makePlanMarker(plan, user, colorIndex, index) {
    const color = colors[colorIndex];
    const planIcon = L.divIcon({
      html: UrtMap.svg(
        "pin",
        `fill: ${color}; stroke: #000; opacity:0.6;`,
        index
      ),
      iconSize:   [40, 40],
      iconAnchor: [20, 40],
    });

    return (
      <Marker
        ref={(ref) => {
          this.markerRefs[plan.id] = ref;
        }}
        key={plan.id}
        icon={planIcon}
        position={plan.point.coords.asArray}
      >
        <Popup
          onClose={() => {
            this.setCurrentPoint();
          }}
          onOpen={() => {
            this.setCurrentPoint(plan);
          }}
        />
        <Tooltip>
          Plan
          <br /> {user.fullName}
          <br /> {plan.point.name}
          <br /> start: {plan.start.format("DD.MM.YYYY HH:mm")}
          <br /> finish: {plan.finish.format("DD.MM.YYYY HH:mm")}
          <br /> duration:{" "}
          {moment(plan.finish.diff(plan.start)).utc(false)
            .format("HH:mm")}
          <br /> status: {plan.status}
        </Tooltip>
      </Marker>
    );
  }

  makeFactMarker(fact, user, colorIndex, index) {
    const color = colors[colorIndex];
    const visitIcon = L.divIcon({
      html: UrtMap.svg(
        "flag",
        `fill: ${color}; stroke: #000; opacity:0.9; color: ${color};`,
        index
      ),
      iconSize:   [40, 40],
      iconAnchor: [10, 33],
    });

    let coords = null;
    if (fact.finish && fact.finish.coords.asArray) {
      coords = fact.finish.coords.asArray;
    } else if (fact.start && fact.start.coords.asArray) {
      coords = fact.start.coords.asArray;
    }
    if (!coords) {
      return null;
    }
    return (
      <Marker
        ref={(ref) => {
          this.markerRefs[fact.id] = ref;
        }}
        key={fact.id}
        icon={visitIcon}
        position={coords}
      >
        <Popup
          onClose={() => {
            this.setCurrentPoint();
          }}
          onOpen={() => {
            this.setCurrentPoint(fact);
          }}
        />
        <Tooltip>
          Fact
          <br /> {user.fullName}
          <br /> {fact.point && fact.point.name}
          <br /> start: {fact.start.datetime.format("DD.MM.YYYY HH:mm")}
          <br /> finish: {fact.finish.datetime.format("DD.MM.YYYY HH:mm")}
          <br /> duration:{" "}
          {moment(fact.finish.datetime.diff(fact.start.datetime))
            .utc(false)
            .format("HH:mm")}
          <br /> status: {fact.plan && fact.plan.status}
        </Tooltip>
      </Marker>
    );
  }

  renderPlans(plans, color) {
    const planArray = [];

    const momentStartOfDay = moment(dayStart, "HH:mm");
    const momentEndOfDay = moment(dayEnd, "HH:mm");
    const daySizeInMS = momentEndOfDay.diff(momentStartOfDay);

    plans.forEach((plan) => {
      if (plan.start && plan.finish) {
        const planStartNormalized = moment(momentStartOfDay)
          .hours(plan.start.hours())
          .minutes(plan.start.minutes());
        const top =
          (planStartNormalized.diff(momentStartOfDay) / daySizeInMS) *
          dayHeight;
        const height = (plan.finish.diff(plan.start) / daySizeInMS) * dayHeight;
        const title = `${plan.point.name}\nstart: ${plan.start.format(
          "DD.MM.YYYY HH:mm"
        )}\nfinish: ${plan.finish.format(
          "DD.MM.YYYY HH:mm"
        )}\nduration: ${moment(plan.finish.diff(plan.start))
          .utc(false)
          .format("HH:mm")}\nstatus: ${plan.status}`;
        planArray.push(
          <div
            className={`plan ${plan.status}`}
            onClick={(e) => {
              e.stopPropagation();
              if (this.markerRefs[plan.id]) {
                this.markerRefs[plan.id].leafletElement.openPopup();
                this.setCurrentPoint(plan);
              }
            }}
            title={title}
            key={plan.id}
            style={{ height: `${height}vh`, top: `${top}vh` }}
          >
            <div className="point-name">{plan.point.name}</div>
          </div>
        );
      }
    });

    return planArray;
  }

  renderFacts(facts, color) {
    const factArray = [];

    const momentStartOfDay = moment(dayStart, "HH:mm");
    const momentEndOfDay = moment(dayEnd, "HH:mm");
    const daySizeInMS = momentEndOfDay.diff(momentStartOfDay);

    facts.forEach((fact) => {
      if (fact.start && fact.finish) {
        const factStartNormalized = moment(momentStartOfDay)
          .hours(fact.start.datetime.hours())
          .minutes(fact.start.datetime.minutes());
        const top =
          (factStartNormalized.diff(momentStartOfDay) / daySizeInMS) *
          dayHeight;
        const height =
          (fact.finish.datetime.diff(fact.start.datetime) / daySizeInMS) *
          dayHeight;
        const title = `${
          fact.point && fact.point.name
        }\nstart: ${fact.start.datetime.format(
          "DD.MM.YYYY HH:mm"
        )}\nfinish: ${fact.finish.datetime.format(
          "DD.MM.YYYY HH:mm"
        )}\nduration: ${moment(fact.finish.datetime.diff(fact.start.datetime))
          .utc(false)
          .format("HH:mm")}`;
        factArray.push(
          <div
            className="fact done"
            onClick={(e) => {
              e.stopPropagation();
              if (this.markerRefs[fact.id]) {
                this.markerRefs[fact.id].leafletElement.openPopup();
                this.setCurrentPoint(fact);
              }
            }}
            key={fact.id}
            title={title}
            style={{ height: `${height}vh`, top: `${top}vh` }}
          >
            <div className="point-name">{fact.point && fact.point.name}</div>
          </div>
        );
      }
    });

    return factArray;
  }

  makeInfo(user, interval, color) {
    const plans = this.renderPlans(interval.plans, color);
    const facts = this.renderFacts(interval.facts, color);
    const scale = [];

    const momentStartOfDay = moment(dayStart, "HH:mm");
    const momentEndOfDay = moment(dayEnd, "HH:mm");
    const daySizeInMS = momentEndOfDay.diff(momentStartOfDay);
    // eslint-disable-next-line
    for (
      let i = momentStartOfDay.hours() + 1;
      i <= momentEndOfDay.hours();
      i += 1
    ) {
      const hour = moment(momentStartOfDay).hours(i)
        .minutes(0);
      const bottom = (momentEndOfDay.diff(hour) / daySizeInMS) * dayHeight;
      scale.push(
        <div key={i} className="hour" style={{ bottom: `${bottom}vh` }}>
          {hour.format("HH:mm")}
        </div>
      );
    }
    const dayContent = (
      <div className="day-content" style={{ height: `${dayHeight}vh` }}>
        {!!facts.length && <div className="facts">{facts}</div>}
        <div className="scale">{scale}</div>
        {!!plans.length && <div className="plans">{plans}</div>}
      </div>
    );
    return (
      <div className="card">
        <div className="card-container">
          <div className="date">
            <div className="color" style={{ color }}>
              <IoMdPin />
            </div>
            <div className="name">
              {user.fullName} {stringifyDateRU(interval.interval.from)}
            </div>
            <div
              className="close"
              onClick={() => {
                this.props.mapStore.setCurrentInterval(user);
              }}
            >
              <IoMdClose />
            </div>
          </div>
          <div className="legend">
            {!!facts.length && <div>Facts</div>}
            {!!plans.length && <div>Plan</div>}
          </div>
          {dayContent}
        </div>
      </div>
    );
  }

  makeMapData(data) {
    const mapData = data.data;
    const points = [];
    const polys = [];
    const buttons = [];
    let bounds = data.bounds;

    let info = null;
    let colorIndex = 0;
    mapData.forEach((mapUser) => {
      const user = mapUser.user;
      mapUser.intervals.forEach((interval) => {
        const color = colors[colorIndex];
        const button = this.renderButton(user, interval, colorIndex);
        buttons.push(button);

        if (interval.interval.isCurrent) {
          info = this.makeInfo(user, interval, color);

          bounds = interval.bounds;
        }

        if (interval.plans.length) {
          let i = 0;
          interval.plans.forEach((plan) => {
            if (plan.point && plan.point.coords && plan.point.coords.asArray) {
              i += 1;
              const point = this.makePlanMarker(plan, user, colorIndex, i);
              points.push(point);
            }
          });

          polys.push(
            <Polyline
              dashArray="10"
              key={`${colorIndex}-plan-poly`}
              positions={interval.planCoordinates}
              weight={2}
              color={color}
            />
          );
        }

        if (interval.facts.length) {
          let i = 0;
          interval.facts.forEach((fact) => {
            i += 1;
            const point = this.makeFactMarker(fact, user, colorIndex, i);
            points.push(point);
          });

          polys.push(
            <Polyline
              key={`${colorIndex}-fact-poly`}
              positions={interval.factCoordinates}
              weight={2}
              color={color}
            />
          );
        }

        colorIndex += 1;
      });
    });

    return {
      buttons,
      bounds,
      points,
      polys,
      info,
    };
  }

  onEmployeeChange(employee) {
    this.props.mapStore.changeEmployee(employee);
  }

  onPeriodChange(startDate, endDate) {
    this.props.mapStore.changePeriod(startDate, endDate);
  }

  toggleModal() {
    this.setState({
      modalIsVisible: !this.state.modalIsVisible,
      errorString:    "",
    });
    this.props.mapStore.changePeriod(null, null);
    this.props.mapStore.changeEmployee(undefined);
  }

  setCurrentPoint(point) {
    this.setState({
      point,
    });
  }

  checkMonth(month) {
    if (moment(month).month() < 3) {
      this.props.mapStore.getBlockedDays(
        moment(month).subtract(1, "year"),
        true
      );
    } else if (moment(month).month() > 9) {
      this.props.mapStore.getBlockedDays(moment(month).add(1, "year"), true);
    }
  }

  isDayBlocked(date) {
    let isBlocked = true;
    const { blockedDays } = this.props.mapStore;
    isBlocked = blockedDays.get(moment(date).format("YYYY-MM-DD"));
    return isBlocked;
  }

  confirmModal() {
    const { mapStore } = this.props;
    const { employee } = mapStore;
    const { begin, end } = mapStore.period;
    if (employee && begin && end) {
      this.setState({
        modalIsVisible: false,
        errorString:    "",
      });
      this.props.mapStore.addUserInterval();
    } else {
      let errorString = "No employee and date";
      if (!employee && begin && end) {
        errorString = "No employee";
      }
      if (!begin && !end && employee) {
        errorString = "No date";
      }
      errorString += " was entered";

      this.setState({
        errorString,
      });
    }
  }

  render() {
    const { mapStore, employeesStore } = this.props;
    const { begin, end } = mapStore.period;
    const { employee, daysPending } = mapStore;

    const mapData = this.makeMapData(mapStore.mapData);
    const { points, polys, buttons } = mapData;
    let bounds = mapData.bounds;
    const currentInfo = mapData.info;
    const point = this.state.point;
    if (point) {
      if (point instanceof Plan) {
        bounds = [point.point.coords.asArray, point.point.coords.asArray];
      }
      if (point instanceof Fact) {
        const start = point.start.coords.asArray;
        const finish = point.finish.coords.asArray;
        if (finish) {
          bounds = [finish, finish];
        } else if (start) {
          bounds = [start, start];
        }
      }
    }

    const mapboxAccessToken = "pk.eyJ1Ijoic2l0aGFydGhhIiwiYSI6ImNqbWhxenQyczBkNnozcW9tZTg1bmFma3IifQ.HfdyCn07wfvLhMYi9BOYPA";
    return (
      <div className="mapHolder">
        <Map
          bounds={bounds}
          boundsOptions={{
            padding:            [30, 30],
            paddingBottomRight: [currentInfo ? 380 : 30, 30],
            maxZoom:            18,
          }}
          minZoom={3}
          maxZoom={18}
          animate={true}
        >
          <TileLayer 
            maxZoom={18}
            // https://tile.openstreetmap.org/${z}/${x}/${y}.png
            // url="https://osm-service.urt.online/tiles/{z}/{x}/{y}.png"
            // url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png"
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxAccessToken}`}
            // url="https://api.mapbox.com/v4/mapbox.mapbox-streets-v8,mapbox.mapbox-terrain-v2,mapbox.mapbox-bathymetry-v2/{z}/{y}/{x}.vector.pbf?sku=101Yx05yW1vIm&access_token=tk.eyJ1Ijoic2l0aGFydGhhIiwiZXhwIjoxNjkyOTY2MTU3LCJpYXQiOjE2OTI5NjI1NTYsInNjb3BlcyI6WyJlc3NlbnRpYWxzIiwic2NvcGVzOmxpc3QiLCJtYXA6cmVhZCIsIm1hcDp3cml0ZSIsInVzZXI6cmVhZCIsInVzZXI6d3JpdGUiLCJ1cGxvYWRzOnJlYWQiLCJ1cGxvYWRzOmxpc3QiLCJ1cGxvYWRzOndyaXRlIiwic3R5bGVzOnRpbGVzIiwic3R5bGVzOnJlYWQiLCJmb250czpsaXN0IiwiZm9udHM6cmVhZCIsImZvbnRzOndyaXRlIiwic3R5bGVzOndyaXRlIiwic3R5bGVzOmxpc3QiLCJzdHlsZXM6ZG93bmxvYWQiLCJzdHlsZXM6cHJvdGVjdCIsInRva2VuczpyZWFkIiwidG9rZW5zOndyaXRlIiwiZGF0YXNldHM6bGlzdCIsImRhdGFzZXRzOnJlYWQiLCJkYXRhc2V0czp3cml0ZSIsInRpbGVzZXRzOmxpc3QiLCJ0aWxlc2V0czpyZWFkIiwidGlsZXNldHM6d3JpdGUiLCJkb3dubG9hZHM6cmVhZCIsInZpc2lvbjpyZWFkIiwidmlzaW9uOmRvd25sb2FkIiwibmF2aWdhdGlvbjpkb3dubG9hZCIsIm9mZmxpbmU6cmVhZCIsIm9mZmxpbmU6d3JpdGUiLCJzdHlsZXM6ZHJhZnQiLCJmb250czptZXRhZGF0YSIsInNwcml0ZS1pbWFnZXM6cmVhZCIsImRhdGFzZXRzOnN0dWRpbyIsImN1c3RvbWVyczp3cml0ZSIsImNyZWRlbnRpYWxzOnJlYWQiLCJjcmVkZW50aWFsczp3cml0ZSIsImFuYWx5dGljczpyZWFkIl0sImNsaWVudCI6Im1hcGJveC5jb20iLCJsbCI6MTY5Mjk2MjQ3ODcwNCwiaXUiOm51bGwsImVtYWlsIjoiZ3NpdGhhcnRoYUBnbWFpbC5jb20ifQ.k_1NBBwIqs7CuLlpWwmVBQ"
          />
          {polys}
          {points}
        </Map>
        <div className="cards-holder">{buttons}</div>
        <div className="info-holder">{currentInfo}</div>
        {buttons.length < 8 && (
          <div className="addUser" onClick={this.toggleModal}>
            <IoMdPerson />
          </div>
        )}
        {this.state.modalIsVisible && (
          <div className="overlay">
            <div className="modal-user-picker">
              <div className="modal-header">
                Выберите пользователя и дату
                <div className="close" onClick={this.toggleModal}>
                  <IoMdClose />
                </div>
              </div>
              <div className="modal-content">
                <EmployeeAutocompleteSelect
                  value={mapStore.employee}
                  options={employeesStore.employeesArray}
                  onChange={this.onEmployeeChange}
                />
                {!daysPending && (
                  <DayPicker
                    employee={employee}
                    begin={begin}
                    end={end}
                    isDayBlocked={this.isDayBlocked}
                    onMonthClick={this.checkMonth}
                    onChange={this.onPeriodChange}
                  />
                )}
                {daysPending && (
                  <div style={{ padding: "2rem" }}>
                    <div className="points-preloader">
                      <div />
                    </div>
                  </div>
                )}
              </div>
              {this.state.errorString && (
                <div className="modal-error">{this.state.errorString}</div>
              )}
              <Button
                className={"button inline"}
                onClick={this.confirmModal}
                text="Confirm"
                leftIcon={<IoMdCheckmarkCircle />}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UrtMap;
