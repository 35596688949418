import { observable, action, runInAction, computed } from "mobx";
import VisitFact from "../models/VisitFact";
import Audit from "../models/Audit";

export default class AuditsStore {
  @observable isPendingAuditFacts = true;
  @observable isPendingAuditFactData = false;
  @observable audits = new Map();
  @observable facts = new Map();
  @observable visitFacts = new Map();
  @observable currentFact;
  @observable currentVisitFact;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  init() {
    if (this.rootStore.user.isLoggedIn) {
      this.fetchAuditFacts();
    }
  }

  /**
   * Create new AuditFact model instance.
   *
   */
  @action
  factory(auditFact) {
    const {
      id,
      visitFactId,
      authorId,
      pointId,
      createdAt,
      start,
      finish,
    } = auditFact;
    const fact = new Audit(
      id,
      visitFactId,
      authorId,
      pointId,
      createdAt,
      start,
      finish,
      this
    );
    this.facts.set(id, fact);
    return fact;
  }

  /**
   * Create new VisitFact model instance.
   *
   */
  @action
  visitFactFactory(visitFact) {
    const {
      id,
      planId,
      authorId,
      pointId,
      createdAt,
      start,
      finish,
      reportIds,
    } = visitFact;
    const fact = new VisitFact(
      id,
      planId,
      authorId,
      pointId,
      createdAt,
      start,
      finish,
      reportIds,
      this
    );
    this.visitFacts.set(id, fact);
    return fact;
  }

  @action
  async setCurrentFact(id) {
    const currentFact = this.facts.get(id);
    this.isPendingAuditFactData = true;
    this.currentFact = currentFact;
    this.currentVisitFact = undefined;
    const currentVisitFact = await this.getVisitFact(currentFact.factId);
    runInAction(() => {
      this.currentVisitFact = currentVisitFact;
      this.isPendingAuditFactData = false;
    });
  }

  @action
  async getVisitFact(id) {
    let fact = this.visitFacts.get(id);
    if (!fact) {
      fact = await this.fetchAuditFact(id);
    }
    return fact;
  }

  @action
  async fetchAuditFact(id) {
    const factData = await this.api.getVisitFact(id);
    return this.visitFactFactory(factData);
  }
  /**
   * Fetch all audits, create models and store them.
   */
  @action
  async fetchAuditFacts() {
    try {
      this.isPendingAuditFacts = true;
      const auditFacts = await this.api.getAuditFacts();

      // const visitFacts = [];
      auditFacts.forEach((auditFact) => {
        // visitFacts.push(this.api.getVisitFact(auditFact.visitFactId));
        this.factory(auditFact);
      });
      // const visitFactsData = await Promise.all(visitFacts);

      // visitFactsData.forEach((visitFact) => {
      //   this.visitFactFactory(visitFact);
      // });
      this.isPendingAuditFacts = false;

      return this.audits;
    } catch (error) {
      runInAction(() => {
        this.isPendingAuditFacts = false;
      });
      console.error(error);
    }
  }

  @computed
  get auditFactsData() {
    const dataArray = [];
    this.facts.forEach((fact) => {
      dataArray.push({
        ...fact.data,
        isSelected: fact.id === (this.currentFact && this.currentFact.id),
      });
    });

    return dataArray;
  }

  @computed
  get isPendingAudits() {
    let isPending = false;
    this.facts.forEach((audit) => {
      isPending = isPending || audit.isPending;
    });
    return isPending;
  }

  @computed
  get isPendingFacts() {
    let isPending = false;
    this.visitFacts.forEach((fact) => {
      isPending = isPending || fact.isPending;
    });
    return isPending;
  }

  @computed
  get isPending() {
    return this.isPendingAudits; // || this.isPendingFacts;
  }
}
