import React from "react";

import { observer, inject } from "mobx-react";
import SplitPane from "react-split-pane";
import RequestForm from "./RequestForm";
import DataModule from "./DataModule";

@inject("userStore", "journalsStore")
@observer
class JournalsModule extends React.Component {
  render() {
    const { journalsStore } = this.props;
    const { isPending, isPendingForm } = journalsStore;

    return (
      <div className="data-viewer">
        <SplitPane split="vertical" defaultSize={"20em"} primary="first">
          {!isPending && !isPendingForm && <RequestForm />}
          {(isPending || isPendingForm) && ( // TODO: нужно бы унести в Loading компонент
            <div className="points-preloader">
              <div />
            </div>
          )}
          {!isPending && <DataModule />}
        </SplitPane>
      </div>
    );
  }
}

export default JournalsModule;
