import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useExpanded,
  useFlexLayout,
} from "react-table";

import {
  IoMdArrowUp,
  IoMdArrowDown,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";

const Table = ({
  columns,
  data,
  className,
  PaginationComponent,
  sortable,
  loading,
  SubComponent,
  hiddenColumns,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = useMemo(() => {
    return {
      minWidth: 30,
      width:    150,
      maxWidth: 800,
    };
  }, []);

  const memoizedData = useMemo(() => {
    return data;
  }, [data]);

  const memoizedCols = useMemo(() => {
    if (!SubComponent) {
      return columns;
    }
    return [
      {
        // Build our expander column
        id:     "expander", // Make sure it has an ID
        width:  "1em",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <div
              {...getToggleAllRowsExpandedProps({
                style: {
                  flex:           1,
                  display:        "flex",
                  alignItems:     "center",
                  justifyContent: "center",
                },
              })}
            >
              {isAllRowsExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </div>
          );
        },
        Cell: ({ row }) =>
        // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        // to build the toggle for expanding a row
        {
          return (row.original.fields && row.original.fields.length) ||
              row.original.canExpand ? (
                <div
                {...row.getToggleRowExpandedProps({
                  style: {
                    flex:           1,
                    display:        "flex",
                    alignItems:     "center",
                    justifyContent: "center",
                    paddingLeft:    `${row.depth * 2}em`,
                  },
                })}
              >
                {row.isExpanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </div>
            ) : null;
        },
      },
      ...columns,
    ];
  }, [columns, SubComponent]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns:      memoizedCols,
        data:         memoizedData,
        defaultColumn,
        initialState: {
          hiddenColumns: hiddenColumns || [],
        },
      },
      useSortBy,
      useResizeColumns,
      useExpanded,
      useFlexLayout
    );

  // Render the UI for your table
  return (
    <div className={"table-container"}>
      <div className={"table-holder"}>
        {!loading && (
          <div
            className={`table manager-table ${className}`}
            {...getTableProps()}
          >
            <div className={"thead"}>
              {headerGroups.map((headerGroup, i) => {
                return (
                  <div
                    key={i}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map((column, j) => {
                      let sort = null;
                      if (column.isSorted) {
                        if (column.isSortedDesc) {
                          sort = (
                            <div className="base-header">
                              <IoMdArrowUp />
                            </div>
                          );
                        } else {
                          sort = (
                            <div className="base-header">
                              <IoMdArrowDown />
                            </div>
                          );
                        }
                      }
                      return (
                        <div
                          key={j}
                          {...column.getHeaderProps(
                            sortable && column.getSortByToggleProps()
                          )}
                          className="th"
                        >
                          {column.render("Header")}
                          {sort}
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div className={"tbody"} {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div key={i}>
                    <div {...row.getRowProps()} className="tr">
                      {row.cells.map((cell, j) => {
                        return (
                          <div key={j} {...cell.getCellProps()} className="td">
                            {cell.render("Cell")}
                          </div>
                        );
                      })}
                    </div>
                    {SubComponent && row.isExpanded && (
                      <div className="sub-holder">
                        <SubComponent row={row} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {loading && (
          <div className="points-preloader">
            <div />
          </div>
        )}
      </div>

      {PaginationComponent && <PaginationComponent />}
    </div>
  );
};

export default Table;
