import React from "react";

import { DayPickerRangeController } from "react-dates";
import moment from "moment";

export default class DayPicker extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const { begin, end } = props;
    if (begin && begin !== state.begin) {
      return {
        begin,
        end,
        inputValue: begin ? begin.format("DD.MM.YYYY") : "",
      };
    } else {
      return state;
    }
  }

  constructor(props) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);

    this.state = {
      focusedInput: undefined,
      inputValue: "",
    };
  }

  onInputChange(e) {
    const inputValue = e.target.value;
    this.setState({ inputValue });
    if (
      inputValue.match(/^[0-3][0-9]\.([0][1-9]|[1][0-2])\.[0-2][0-9]{3}$/) &&
      moment(e.target.value, "DD.MM.YYYY").isValid()
    ) {
      this.props.onChange(
        moment(e.target.value, "DD.MM.YYYY").add("hours", 12),
        moment(e.target.value, "DD.MM.YYYY").add("hours", 12)
      );
    }
  }

  render() {
    const { begin, end, isDayBlocked, onMonthClick } = this.props;
    return (
      <div className="picker-holder">
        <DayPickerRangeController
          startDate={begin} // momentPropTypes.momentObj or null,
          endDate={end} // momentPropTypes.momentObj or null,
          isDayBlocked={isDayBlocked}
          onDatesChange={({ startDate, endDate }) => {
            // возвращает object, нужно его развернуть
            this.props.onChange(startDate, endDate);
          }} // PropTypes.func.isRequired,
          startDateOffset={(day) => {
            return day;
          }}
          endDateOffset={(day) => {
            return day;
          }}
          onPrevMonthClick={onMonthClick}
          onNextMonthClick={onMonthClick}
          firstDayOfWeek={1}
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => {
            return this.setState({ focusedInput });
          }} // PropTypes.func.isRequired,
          // renderCalendarInfo={() => {}}
        />
        <input
          className="date-holder"
          onChange={this.onInputChange}
          type="text"
          value={this.state.inputValue}
        />
      </div>
    );
  }
}
