import { computed, action, observable } from "mobx";

/**
 * Dir Model.
 */
export default class Revisions {
  @observable latest;
  @observable revisions = new Map();
  // directory
  store;

  constructor(store) {
    this.store = store;
  }

  @action
  addRevision(directory, revision) {
    this.revisions.set(directory.revision, directory);
    if (!revision) {
      this.latest = directory.revision;
    }
  }

  @action
  fillData(data, revision) {
    const rev = this.getRevision(revision);
    if (rev) {
      rev.fillData(data);
    }
  }

  @action
  getRevision(id) {
    if (id) {
      return this.revisions.get(id);
    } else {
      return this.latestRev;
    }
  }

  @computed
  get latestRev() {
    return this.revisions.get(this.latest);
  }
}
