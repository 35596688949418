import React from "react";

import { observer, inject } from "mobx-react";

import {
  IoMdCheckboxOutline,
  IoMdClose,
  IoMdSquareOutline,
  IoMdCheckmark,
} from "react-icons/io";

import { WeekPicker, Button } from "~/common/components";
import { Calendar } from "./components";
import PointsList from "../points/components/PointsList";
import EmployeeAutocompleteSelect from "../employees/components/EmployeeAutocompleteSelect";

@inject("userStore", "planningStore", "employeesStore", "pointsStore")
@observer
class Planning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minEventDuration: 10,
    };

    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.confirmModal = this.confirmModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onModalEmployeeChange = this.onModalEmployeeChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  /**
   * Handle period change.
   *
   * @param {Moment} startDate
   * @param {Moment} endDate
   */
  onPeriodChange(startDate, endDate) {
    this.props.planningStore.changePeriod(startDate, endDate);
  }

  /**
   * Handle Employee selection.
   *
   * @param {object} selectedOption
   */
  onEmployeeChange(employee) {
    this.props.planningStore.changeEmployee(employee);
  }

  confirmModal() {
    const action = this.state.modalAction;
    const employee = this.state.employee;
    if (action === "set-audit") {
      this.props.planningStore.setAudit(employee);
      this.toggleModal();
    }
  }

  toggleModal(action) {
    this.setState({
      modalAction: action,
      modalIsVisible: !this.state.modalIsVisible,
    });
  }

  onModalEmployeeChange(employee) {
    this.setState({ employee });
  }

  render() {
    const { planningStore, employeesStore, userStore } = this.props;
    const { begin, end } = planningStore.period;
    const { isAllSelected, selectedPlans, selectedFacts } = planningStore;
    const perms = userStore.permissions;
    const canApprove =
      perms.get("core.modules.planning.can_approve_plan") &&
      perms.get("visits.change_plan") &&
      selectedPlans.size > 0 &&
      selectedFacts.size <= 0;
    const canDelete =
      perms.get("visits.delete_plan") &&
      selectedPlans.size > 0 &&
      selectedFacts.size <= 0;

    const canAudit =
      perms.get("audits.add_audit") &&
      selectedPlans.size <= 0 &&
      selectedFacts.size > 0;
    const check = isAllSelected ? (
      <IoMdCheckboxOutline />
    ) : (
      <IoMdSquareOutline />
    );
    const content = (
      <div className="content">
        <div className="left">
          <div className="buttons">
            <Button
              className={"button inline"}
              onClick={() => {
                planningStore.changeSelectAll(!isAllSelected);
              }}
              leftIcon={check}
              text="All"
            />
            {perms.get("core.modules.planning.can_approve_plan") &&
              perms.get("visits.change_plan") && (
                <Button
                  className={"button approve"}
                  disabled={!canApprove}
                  onClick={() => {
                    planningStore.changeStatusForSelected("approved");
                  }}
                  text="Approve"
                />
              )}
            {perms.get("core.modules.planning.can_approve_plan") &&
              perms.get("visits.change_plan") && (
                <Button
                  className={"button onapproval"}
                  disabled={!canApprove}
                  onClick={() => {
                    planningStore.changeStatusForSelected("onapproval");
                  }}
                  text="DeApprove"
                />
              )}
            {perms.get("visits.delete_plan") && (
              <Button
                className={"button delete"}
                disabled={!canDelete}
                onClick={() => {
                  planningStore.deleteSelected();
                }}
                text="Delete"
              />
            )}
          </div>
          {perms.get("audits.add_audit") && (
            <div className="buttons" key="setAudit">
              <Button
                className={"button success set-audit"}
                disabled={!canAudit}
                onClick={() => {
                  this.toggleModal("set-audit");
                }}
                text="Set audit"
              />
            </div>
          )}
          <EmployeeAutocompleteSelect
            value={planningStore.employee}
            options={employeesStore.employeesArray}
            onChange={this.onEmployeeChange}
          />
          <WeekPicker begin={begin} end={end} onChange={this.onPeriodChange} />
          <PointsList employee={planningStore.employee} />
        </div>
        <div className="right">
          <Calendar
            range={planningStore.daysRange}
            events={planningStore.events}
            eventTimeStep={10}
            gridStep={planningStore.gridStepMinutes}
            startOfDay={planningStore.workdayStart}
            finishOfDay={planningStore.workdayFinish}
            minEventDuration={planningStore.planMinimumLength}
          />
        </div>
        {this.state.modalIsVisible && (
          <div className="overlay">
            <div className="modal-user-picker">
              <div className="modal-header">
                Выберите пользователя
                <div className="close" onClick={this.toggleModal}>
                  <IoMdClose />
                </div>
              </div>
              <div className="modal-content">
                <EmployeeAutocompleteSelect
                  value={this.state.employee}
                  options={employeesStore.employeesArray}
                  onChange={this.onModalEmployeeChange}
                />
              </div>
              {this.state.errorString && (
                <div className="modal-error">{this.state.errorString}</div>
              )}
              <Button
                className={"button inline"}
                onClick={this.confirmModal}
                leftIcon={<IoMdCheckmark />}
                text="Confirm"
              />
            </div>
          </div>
        )}
      </div>
    );
    return content;
  }
}

export default Planning;
