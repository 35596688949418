import { observable, action, computed } from "mobx";
/**
 * MapPoint data model.
 */
export default class MapUser {
  @observable user;
  @observable plans = new Map();
  @observable facts = new Map();

  @observable currentInterval;

  constructor(user, plans, store) {
    this.user = user;
    this.plans.merge(plans);

    this.store = store;
  }

  getPlansForInterval(from, to) {
    const data = this.plans.get(`${from.toISOString()}-${to.toISOString()}`);
    return data;
  }

  getFactsForInterval(from, to) {
    const data = this.facts.get(`${from.toISOString()}-${to.toISOString()}`);
    return data;
  }

  @action
  setPlansForInterval(from, to, data) {
    this.plans.set(`${from.toISOString()}-${to.toISOString()}`, data);
  }

  @action
  setInterval(interval) {
    this.currentInterval = interval;
  }

  @action
  setFactsForInterval(from, to, data) {
    this.facts.set(`${from.toISOString()}-${to.toISOString()}`, data);
  }

  hasDataForInterval(from, to) {
    return (
      this.plans.get(`${from.toISOString()}-${to.toISOString()}`) &&
      this.facts.get(`${from.toISOString()}-${to.toISOString()}`)
    );
  }

  @computed
  get intervals() {
    const intervals = [];
    this.plans.forEach((value, key) => {
      if (!intervals.includes(key)) {
        intervals.push(key);
      }
    });
    this.facts.forEach((value, key) => {
      if (!intervals.includes(key)) {
        intervals.push(key);
      }
    });

    return intervals.sort();
  }
}
