import React from "react";
import { DragSource } from "react-dnd";

const itemSource = {
  beginDrag(props) {
    return {
      point: props.point,
      index: props.index,
    };
  },
};

const collect = function(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging:        monitor.isDragging(),
  };
};

const dragType = "POINT_ITEM";

class PointsListItem extends React.Component {
  render() {
    const { point, index, isDragging, connectDragSource } = this.props;
    const { code, name } = point;
    return connectDragSource(
      <div
        key={code}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`point-item box ${index % 2 ? "even" : "odd"}`}
      >
        <div className="point-index">{index + 1}</div>
        <div className="point-name">{name}</div>
        <div className="point-code">{code}</div>
      </div>
    );
  }
}

export default DragSource(dragType, itemSource, collect)(PointsListItem); //eslint-disable-line
