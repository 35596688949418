import React from "react";
import { observer, inject } from "mobx-react";
import base64 from "base-64";
import { saveAs } from "file-saver";
import { IoMdCloudDownload, IoMdEye } from "react-icons/io";

import "react-tabs/style/react-tabs.css";

import ParamsCheckboxTable from "./ParamsCheckboxTable";
import { Button } from "~/common/components";
import MonitoringAutocompleteSelect from "./MonitoringAutocompleteSelect";
import FromTo from "~/common/components/FromTo";

@inject(
  "userStore",
  "employeesStore",
  "pointsStore",
  "auditStore",
  "monitoringViewerStore"
)
@observer
class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.onMonChange = this.onMonChange.bind(this);
    this.onGetData = this.onGetData.bind(this);
    this.onGetXcel = this.onGetXcel.bind(this);
    this.setDates = this.setDates.bind(this);

    this.state = {
      focusedInput:     undefined,
      isLoadingFile:    false,
      loadingFileError: false,
      pages:            0,
    };
  }

  onMonChange(mon) {
    this.props.monitoringViewerStore.changeMon(mon);
  }

  onGetData() {
    this.props.monitoringViewerStore.showTheTable(true);
    if (this.props.monitoringViewerStore.showTable) {
      this.props.monitoringViewerStore.loadPage();
    }
  }

  onGetXcel() {
    this.setState({
      isLoadingFile:    true,
      loadingFileError: false,
    });
    const { uid, token } = this.props.userStore;
    const {
      mon,
      selectedParamsArray,
      startDate,
      endDate,
    } = this.props.monitoringViewerStore;
    const from = startDate.toISOString();
    const to = endDate.toISOString();
    let paramsString = "";
    if (selectedParamsArray.length) {
      paramsString = `&param=${selectedParamsArray.join("&param=")}`;
    }
    const downloadUrl = `/api/v4/monitorings/${mon.id}/reports/xlsx?from=${from}&to=${to}&user=${uid}&token=${token}${paramsString}`;
    const options = {
      method:  "GET",
      headers: {
        Authorization: `Basic ${base64.encode(`${uid}:${token}`)}`,
        Accept:        "application/vnd.ms-excel",
      },
    };
    fetch(downloadUrl, options).then(
      (response) => {
        response.blob().then(
          (blob) => {
            saveAs(blob, `${mon.name}.xlsx`);
            this.setState({
              isLoadingFile: false,
            });
          },
          () => {
            this.setState({
              isLoadingFile:    false,
              loadingFileError: true,
            });
          }
        );
      },
      () => {
        this.setState({
          isLoadingFile:    false,
          loadingFileError: true,
        });
      }
    );
  }

  setDates({ startDate, endDate }) {
    const { monitoringViewerStore } = this.props;
    monitoringViewerStore.setDates({ startDate, endDate });
  }

  render() {
    const { monitoringViewerStore } = this.props;
    const {
      canRequest,
      mon,
      monArray,
      startDate,
      endDate,
    } = monitoringViewerStore;
    const { isLoadingFile, loadingFileError } = this.state;

    return (
      <div className="request-form">
        <div className="buttons">
          <Button
            className={"button success inline"}
            disabled={!canRequest}
            onClick={this.onGetData}
            leftIcon={<IoMdEye />}
            text="View data"
          />
          <Button
            className={"button active inline"}
            disabled={!canRequest || isLoadingFile}
            onClick={this.onGetXcel}
            leftIcon={<IoMdCloudDownload />}
            text="Download"
            isLoading={isLoadingFile}
            isError={loadingFileError}
          />
        </div>
        <MonitoringAutocompleteSelect
          value={mon}
          options={monArray}
          onChange={this.onMonChange}
        />
        <FromTo
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.setDates}
        />
        <div className="params-table-holder">
          <ParamsCheckboxTable />
        </div>
      </div>
    );
  }
}

export default RequestForm;
