import { runInAction, action, observable, computed, toJS } from "mobx";

import { parseDateTime } from "~/common/datetime";
import JournalReport from "./JournalReport";

export default class JournalFact {
  @observable isPendingData = false;

  @observable report;
  @observable author;
  @observable point;
  @observable answers = new Map();
  @observable hasAuthor = false;
  @observable hasPoint = false;

  @observable id;
  visitFactId;
  start;
  journalId;
  finish;
  @observable answers = new Map();

  constructor(data, store) {
    this.store = store;

    this.visitFactId = data.visitFact.id;
    this.journalId = data.journalId;
    this.id = data.id;
    this.start = parseDateTime(data.start.datetime);
    this.finish = parseDateTime(data.finish.datetime);

    this.processAnswers(data.answers);

    this.getAuthor(data.visitFact.authorId);
    this.getPoint(data.visitFact.pointId);
  }

  @action
  processAnswers(answers) {
    this.answers.set(1, "answer");
    answers.forEach((answer) => {
      this.answers.set(`${answer.parameterId}`, answer);
    });
  }

  @action
  async getAuthor(id) {
    const author = await this.store.rootStore.employees.get(id);
    runInAction(() => {
      if (author) {
        this.author = author;
        this.hasAuthor = true;
      } else {
        this.hasAuthor = false;
      }
    });
  }

  @action
  async getPoint(id) {
    const point = await this.store.rootStore.points.addPoint(id);
    runInAction(() => {
      this.point = point;
      this.hasPoint = true;
    });
  }

  @action
  async getReport() {
    if (!this.report) {
      this.isPendingData = true;
      const reportData = await this.store.api.getJournalReport(this.id);
      const report = new JournalReport(reportData, this);
      runInAction(() => {
        this.report = report;
        this.isPendingData = false;
      });
    }
  }

  @computed
  get data() {
    return toJS(this.answers);
  }

  @computed
  get isPending() {
    return !(this.hasAuthor && this.hasPoint) || this.isPendingData;
  }

  @computed
  get journal() {
    return this.store.journals.get(this.journalId);
  }

  @computed
  get loading() {
    return this.isPendingData;
  }

  @computed
  get isSelected() {
    return this.id === (this.store.fact && this.store.fact.id);
  }

  @computed
  get label() {
    return this.name;
  }
}
