import { observable, computed, toJS, action } from "mobx";
import { uuid } from "uuidv4";
import Form from "~/common/models/Form";

export default class Profile {
  @observable isPendingData = false;
  @observable id = uuid();
  @observable employeeId;
  @observable name;
  @observable type;
  @observable form;

  constructor(data, employeeId, profileType, store) {
    this.store = store;

    this.name = data.name;
    this.employeeId = employeeId;
    this.type = profileType;
    
    if (data.id) {
      this.id = `${data.id}`;
    }

    this.form = new Form(this.id, this.type.pages, this.store, this.type.mainId, this);
    this.makeForm(data.content);
  }

  @action
  makeForm(values = {}) {
    this.form.setValues(values);
  }

  @action
  async formSubmit(content) {
    await this.store.setProfile(this.employeeId, this.typeId, { content });
    this.form.save();
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get typeId() {
    return this.type && this.type.id;
  }

  @computed
  get values() {
    return toJS(this.data);
  }
}
