import React from "react";
import { observer, inject } from "mobx-react";
import SplitPane from "react-split-pane";
import {
  IoIosCheckmarkCircleOutline,
  IoMdInformationCircle,
  IoMdList,
  IoMdKeypad,
  IoMdCreate,
  IoMdSwitch,
  IoMdImage,
  IoMdDoneAll,
  IoMdRadioButtonOn,
  IoMdPerson,
  IoMdPin,
  IoMdClock,
  IoMdCalendar,
  IoMdBook,
} from "react-icons/io";

import PaginationComponent from "~/modules/audits/PaginationComponent";
import CheckboxTable from "~/common/components/CheckboxTable";
import Table from "~/common/components/Table";

@inject("journalsStore", "userStore")
@observer
class DataModule extends React.Component {
  constructor(props) {
    super(props);

    this.getColumns = this.getColumns.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);

    this.state = {
      loadingFileError: false,
    };
  }

  getColumns() {
    const { journalsStore } = this.props;
    const { page, pageLength, visibleJournal } = journalsStore;

    const cols = [
      {
        Header: "ID",
        accessor: "id", // String-based value accessors!
        width: 50,
      },
      {
        Header: <div className="meta-header">#</div>,
        id: "index",
        width: 50,
        Cell: ({ row }) => {
          return <div>{pageLength * (page - 1) + row.index + 1}</div>;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPin /> Point
          </div>
        ),
        id: "point",
        accessor: (d) => {
          return d.point.name;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPerson /> Author
          </div>
        ),
        id: "author",
        accessor: (d) => {
          return d.author.fullName;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Start
          </div>
        ),
        id: "start",
        accessor: (d) => {
          return d.start.format("DD.MM.YYYY HH:mm");
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Finish
          </div>
        ),
        id: "finish",
        accessor: (d) => {
          return d.finish.format("DD.MM.YYYY HH:mm");
        },
      },
    ];

    if (visibleJournal && visibleJournal.fieldSet) {
      visibleJournal.fieldSet.paramsArray.forEach((param) => {
        const type = this.getType(param);

        cols.push({
          Header: (
            <div className="base-header">
              {type} {param.name}
            </div>
          ),
          id: `${param.id}`,
          accessor: (d) => {
            return d.data[param.id] && d.data[param.id].value;
          },
        });
      });
    }
    return cols;
  }

  renderCell({ row, column }) {
    const rowData = row.original;
    if (!rowData) {
      return null;
    }
    const isAudit = column.id !== "value";
    const data = isAudit
      ? rowData.auditValue && rowData.auditValue.value
      : rowData.value;
    let render = data || null;
    if (isAudit && data === undefined) {
      render = (
        <div className="audit-correct">
          <IoIosCheckmarkCircleOutline />
        </div>
      );
    }
    if (rowData.param.type === "list") {
      if (data) {
        render = data.split(",").map((val, i) => {
          return (
            <div key={i} className="value">
              <div key={i} className="list-value">
                {val}
              </div>
            </div>
          );
        });
      }
    } else if (rowData.param.type === "file") {
      if (data) {
        render = (
          <div className="value">
            <div className="photo-value">
              <img alt={`${data}`} src={`../photo/${data}`} />
            </div>
          </div>
        );
      }
    }
    return render;
  }

  toggleSelection(fact) {
    const { journalsStore } = this.props;
    journalsStore.setFact(fact && fact.id);
  }

  onPageChange(page) {
    const { journalsStore } = this.props;
    journalsStore.loadPage(page + 1);
  }

  getType(param) {
    let type = param.type;
    if (type === "list") {
      type = param.viewConfig;
    }
    switch (type) {
      case "linked":
      case "list_db":
        type = <IoMdInformationCircle />;
        break;
      case "number":
        type = <IoMdKeypad />;
        break;
      case "text":
        type = <IoMdCreate />;
        break;
      case "selectfield":
        type = <IoMdList />;
        break;
      case "multiselect":
        type = <IoMdDoneAll />;
        break;
      case "radiogroup":
        type = <IoMdRadioButtonOn />;
        break;
      case "file":
        type = <IoMdImage />;
        break;
      case "boolean":
        type = <IoMdSwitch />;
        break;
      case "date":
        type = <IoMdCalendar />;
        break;
      case "lib-material-link":
        type = <IoMdBook />;
        break;
      default:
        type = "";
        break;
    }
    return type;
  }

  render() {
    const { journalsStore, userStore } = this.props;
    const {
      pageIsLoading,
      page,
      pages,
      fact,
      factArray,
      hasFacts,
      pageLength,
    } = journalsStore;
    const checkboxProps = {
      toggleSelection: this.toggleSelection,
      selectType: "radio",
    };

    let panels = null;

    if (fact) {
      const { report, isPending } = fact;
      if (report && !isPending) {
        const { events, journal } = report;
        if (events.size) {
          const columns = [
            {
              Header: <div className="meta-header">ID</div>,
              accessor: "id",
              width: "3em",
              id: "id",
            },
            {
              Header: <div className="meta-header">Title</div>,
              accessor: "title",
              id: "title",
            },
            {
              Header: (
                <div className="meta-header">
                  <IoMdClock /> DateTime
                </div>
              ),
              id: "datetime",
              accessor: (d) => {
                return d.datetime.format("DD.MM.YYYY HH:mm");
              },
            },
          ];

          const { eventFieldset, recordFieldset } = journal;
          const eventColumns = [...columns];
          if (eventFieldset) {
            eventFieldset.paramsArray.forEach((param) => {
              const type = this.getType(param);

              eventColumns.push({
                Header: (
                  <div className="base-header">
                    {type} {param.name}
                  </div>
                ),
                id: `${param.id}`,
                accessor: (d) => {
                  return d.data[param.id] && d.data[param.id].value;
                },
              });
            });
          }
          const recordColumns = [...columns];

          if (recordFieldset) {
            recordFieldset.paramsArray.forEach((param) => {
              const type = this.getType(param);

              recordColumns.push({
                Header: (
                  <div className="base-header">
                    {type} {param.name}
                  </div>
                ),
                id: `${param.id}`,
                accessor: (d) => {
                  return d.data[param.id] && d.data[param.id].value;
                },
              });
            });
          }

          panels = (
            <Table
              sortable={true}
              multiSort={true}
              data={report.eventsArray}
              defaultPageSize={100}
              columns={eventColumns}
              showPagination={false}
              SubComponent={({ row }) => {
                return (
                  <Table
                    sortable={true}
                    multiSort={true}
                    data={row.original.recordsArray}
                    defaultPageSize={100}
                    columns={recordColumns}
                    showPagination={false}
                  />
                );
              }}
            />
          );
        }
      }
    }

    return (
      <div className="data-module">
        <SplitPane split="vertical" defaultSize={"50%"} primary="first">
          {pageIsLoading && (
            <div className="points-preloader">
              <div />
            </div>
          )}
          {!pageIsLoading && hasFacts && (
            <div className="journals-table-holder">
              <div className="params-table-holder">
                <CheckboxTable
                  loading={pageIsLoading}
                  data={factArray}
                  columns={this.getColumns()}
                  pageSize={pageLength}
                  page={page}
                  {...checkboxProps}
                />
              </div>
              <PaginationComponent
                onPageChange={this.onPageChange}
                pageSize={pageLength}
                page={page - 1}
                pages={pages}
                showPageJump={true}
                hideDownloadButton={true}
                userStore={userStore}
              />
            </div>
          )}
          {fact && !fact.isPending && panels}
          {fact && fact.isPending && (
            <div className="points-preloader">
              <div />
            </div>
          )}
        </SplitPane>
      </div>
    );
  }
}

export default DataModule;
