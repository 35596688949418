import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { IoMdClipboard } from "react-icons/io";
import { useDrag } from "react-dnd";
import { Resizable } from "re-resizable";
import moment from "moment";

const MAGIC_NUMBER = 0;

const yToDuration = (y, dayDuration, step, availableHeight) => {
  let value = (y * dayDuration) / availableHeight;
  // Этот код позволяет нормализовать ивенты, пришедшие от устройств,
  // в которых время не кратно step.
  // c ним 13:06 будет после резайса 13:00 или 13:10. Без него - 12:56 или 13:16.
  const rest = ~~(value % step);
  if (rest >= step / 2) {
    value += step - rest;
  } else {
    value -= rest;
  }
  return parseInt(value, 10);
};

/**
 * Посчитать высоту элемента события.
 *
 * @param {Moment} start время начала события
 * @param {Moment} finish время окончания события
 * @param {number} availableHeight вся доступная высота
 *
 */
const computeHeight = (start, finish, dayDuration, availableHeight) => {
  let height = availableHeight;
  if (start && finish) {
    const duration = moment.duration(finish.diff(start)).asMinutes();
    height = parseInt((duration * availableHeight) / dayDuration, 10);
  }
  return height;
};

/**
 * Посчитать отступ от начала дня.
 *
 * @param {Moment} dayStart время начала рабочего дня
 * @param {Moment} eventStart время начала события
 * @param {number} dayDuration весь рабочий день в минутах
 * @param {number} dayHeight вся доступная высота
 *
 */
const computeTop = (dayStart, eventStart, dayDuration, dayHeight, date) => {
  let top = 0;
  const trueStartOfDay = moment(date.format("DD.MM.YYYY"), "DD.MM.YYYY");
  trueStartOfDay.hour(dayStart.hour());
  if (eventStart) {
    const duration = moment
      .duration(eventStart.diff(trueStartOfDay))
      .asMinutes();
    top = parseInt((duration * dayHeight) / dayDuration, 10);
  }
  return top;
};

/**
 * dnd section
 */

const Event = observer(
  ({
    event,
    dayHeight,
    startOfDay,
    finishOfDay,
    dayDuration,
    minDuration,
    step,
  }) => {
    const {
      status,
      point,
      id,
      start,
      date,
      finish,
      isSelected,
      isFactSelected,
      fact,
    } = event;

    const availableHeight = dayHeight - MAGIC_NUMBER;
    const computedTop = computeTop(
      startOfDay,
      start,
      dayDuration,
      availableHeight,
      date
    );

    const [height] = useState(
      computeHeight(start, finish, dayDuration, availableHeight)
    );

    const [initialTop, setInitialTop] = useState(computedTop);

    const [top, setTop] = useState(computedTop);

    const enableResize = !(
      status === "done" ||
      status === "approved" ||
      status === "missed" ||
      status === "found"
    );

    useEffect(() => {
      setTop(computeTop(startOfDay, start, dayDuration, availableHeight, date));
    }, [startOfDay, start, dayDuration, availableHeight, date]); // [] - fires only on Mount, if undefined - fires every render,

    const canDrag = () => {
      return !(
        status === "done" ||
        status === "approved" ||
        status === "missed" ||
        status === "found"
      );
    };

    const begin = () => {
      return {
        event,
        startOfDay,
        finishOfDay,
        dayDuration,
        step,
        availableHeight: dayHeight - MAGIC_NUMBER,
      };
    };

    const collect = (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    };

    const [collectedProps, drag] = useDrag({
      item: { id, type: "EVENT_ITEM" },
      canDrag,
      collect,
      begin,
    });

    const { isDragging } = collectedProps;

    const onResize = (e, direction, ref, delta) => {
      let newStart = start ? moment(start) : moment(startOfDay);
      let newFinish = finish ? moment(finish) : moment(finishOfDay);
      if (direction === "bottom") {
        const duration = yToDuration(
          top + ref.clientHeight,
          dayDuration,
          step,
          availableHeight
        );
        newFinish = moment(startOfDay).add(duration, "m");
        if (newFinish > finishOfDay) {
          newFinish = moment(finishOfDay);
        }
      } else if (direction === "top") {
        const duration = yToDuration(
          initialTop - delta.height,
          dayDuration,
          step,
          availableHeight
        );
        newStart = moment(startOfDay).add(duration, "m");
        if (newStart < startOfDay) {
          // проверка чтобы начало не было раньше старта дня
          newStart = moment(startOfDay);
        }
      }
      event.changeTime(newStart, newFinish);
    };

    const onResizeStart = (e, direction, ref) => {
      setInitialTop(ref.offsetTop);
    };

    const onResizeStop = () => {
      event.save(); // сохранить план на сервер
    };

    const onClick = () => {
      event.toggleSelection();
    };

    if (!point) {
      return null;
    }

    return (
      <Resizable
        defaultSize={{ height }}
        key={id}
        className={`plan-item ${
          status === "onapproval" ? "on-approval" : status
        } ${isSelected === true ? "selected" : ""} ${
          isFactSelected ? "fact-selected" : ""
        }`}
        style={{
          opacity: isDragging ? 0.5 : 1,
          position: "absolute",
          left: 0,
          top: `${top}px`,
          width: "100%",
          display: "flex",
          margin: "0 .25rem",
        }}
        enable={{
          top: enableResize,
          bottom: enableResize,
          right: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        minHeight={(minDuration * availableHeight) / dayDuration}
        // maxHeight={availableHeight - top}
        // нет нужды проверять конец рабочего дня,
        // resizable знает что нельзя тянуться дальше размера контейнера
        bounds="parent"
        onResize={onResize}
        // минимальный шаг изменения зависиот от минимальной продолжительности,
        // отмасштабированной в пиксели
        grid={[0, (step * availableHeight) / dayDuration]}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        title={`Status: ${status}\nName: ${point.name}\nCode: ${
          point.code
        }\nFrom: ${start ? start.format("HH:mm") : "Whole day"}\nTo: ${
          finish ? finish.format("HH:mm") : "Whole day"
        }`}
      >
        <div ref={drag} className="draggable-content" onClick={onClick}>
          <div className="planning-event-time-start">
            {start ? start.format("HH:mm") : "Whole day"}
          </div>
          <div className="planning-point-label name">{point.name}</div>
          <div className="planning-point-label code">{point.code}</div>
          <div className="planning-event-time-finish">
            {finish ? finish.format("HH:mm") : "Whole day"}
          </div>
          {fact && fact.auditIds && fact.auditIds.length > 0 && (
            <div className="item-has-audit" title="This visit has audit">
              <IoMdClipboard />
            </div>
          )}
        </div>
      </Resizable>
    );
  }
);

export default Event;
export { computeHeight, computeTop, yToDuration };
