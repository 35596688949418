import { computed, observable, action } from "mobx";
// import Page from "./Page";

/**
 * Dir Model.
 */
export default class Directory {
  id;
  name;
  nameColumn;
  primaryKeyColumn;
  groups;
  revision;

  @observable records = new Map();

  store;

  constructor(data, store) {
    this.store = store;

    this.id = data.id;
    this.revision = data.revision;

    if (!data.pending) {
      this.fillData(data);
    }
  }

  @action
  fillData(data) {
    this.id = data.id;
    this.revision = data.revision;
    this.name = data.name;
    this.nameColumn = data.nameColumn;
    this.primaryKeyColumn = data.primaryKeyColumn;
    this.groups = data.groups;

    data.records.forEach((record) => {
      this.records.set(record[this.primaryKeyColumn], record);
    });
  }

  @computed
  get recordArray() {
    const array = [];
    this.records.forEach((record) => {
      array.push(record);
    });
    return array;
  }
}
