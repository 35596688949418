import React from "react";

import { observer } from "mobx-react";
import Photo from "~/common/components/Photo";
import { IoIosPerson } from "react-icons/io";

@observer
class DataModule extends React.Component {
  render() {
    const { galleryStore } = this.props;

    const { itemsArray, isPending } = galleryStore;

    return (
      <div className="data-module">
        {!!itemsArray.length &&
          itemsArray.map((item) => {
            return (
              <Photo
                key={item.id}
                id={item.id}
                name={item.name}
                author={
                  <div className="user">
                    <IoIosPerson />
                    <div class="user-name">{item.author}</div>
                  </div>
                }
                datetime={`${item.date} ${item.time}`}
                pos={
                  <div className={"point-item box even"}>
                    <div className="point-code">{item.pos_code}</div>
                    <div className="point-name">{item.pos_name}</div>
                  </div>
                }
              />
            );
          })}
        {!itemsArray.length && !isPending && (
          <div className="empty-plan">
            Choose date range and parameter to view data.
          </div>
        )}
      </div>
    );
  }
}

export default DataModule;
