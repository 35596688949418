import React from "react";
import { observable, action, runInAction, computed, toJS } from "mobx";
import moment from "moment";

import {
  IoMdInformationCircle,
  IoMdList,
  IoMdKeypad,
  IoMdCreate,
  IoMdSwitch,
  IoMdImage,
  IoMdDoneAll,
  IoMdRadioButtonOn,
  IoMdPerson,
  IoMdPin,
  IoMdClock,
  IoMdCalendar,
  IoMdRibbon,
} from "react-icons/io";

import Monitoring from "~/common/models/Monitoring";

export default class MonitoringViewerStore {
  @observable isPendingData = true;
  @observable showTable = false;
  @observable showReportTable = false;
  @observable pageIsLoading = false;
  @observable pageReportIsLoading = false;
  @observable mons = new Map();
  @observable point;
  @observable pendingPoints = new Map();
  @observable mon;
  @observable facts = new Map();
  @observable fact;
  @observable totlaItems = 1;
  @observable reportTotlaItems = 1;

  @observable sortedArray = [];
  @observable answers = [];

  @observable totalItems = 0;
  @observable page = 1;
  @observable pageLength = 50;

  @observable reportPage = 1;
  @observable reportPageLength = 50;

  @observable params = new Map();

  @observable startDate;
  @observable endDate;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.isPendingData = true;
    await this.getMons();
    runInAction(() => {
      this.isPendingData = false;
    });
  }

  @action
  async getMons() {
    const data = await this.api.getMonitoringViewerMons();

    data.forEach((monData) => {
      this.mons.set(monData.id, new Monitoring(monData, this));
    });
  }

  @computed
  get pages() {
    return Math.ceil((this.totalItems || 0) / this.pageSize);
  }

  @computed
  get pageSize() {
    return Number(this.pageLength);
  }

  @computed
  get reportPages() {
    return Math.ceil(this.reportTotalItems / this.reportPageSize);
  }

  @computed
  get reportPageSize() {
    return Number(this.reportPageLength);
  }

  @computed
  get monArray() {
    return Array.from(this.mons.values());
  }

  @computed
  get currentFact() {
    return this.fact;
  }

  @computed
  get sorted() {
    return toJS(this.sortedArray);
  }

  @computed
  get factArray() {
    if (!this.fact) {
      return Array.from(this.facts.values());
    }
    const arr = [];
    this.facts.forEach((fact) => {
      arr.push({
        ...fact,
        isSelected: fact.id === this.fact.id,
      });
    });
    return arr;
  }

  @computed
  get canRequest() {
    return !!this.startDate && !!this.endDate && !!this.mon;
  }

  @computed
  get paramsArray() {
    if (!this.mon) {
      return [];
    }
    return this.mon.paramArray.map((param) => {
      return {
        ...param,
        isSelected: !!this.params.get(param.id),
      };
    });
  }

  @computed
  get hasData() {
    return !!this.facts.size;
  }

  @computed
  get hasAnswers() {
    return !!this.answers.size;
  }

  @computed
  get reportData() {
    return toJS(this.answers);
  }

  @computed
  get factColumns() {
    const columns = [
      {
        Header:   "ID",
        accessor: "id", // String-based value accessors!
        width:    60,
        show:     false,
      },
      {
        Header: <div className="meta-header">#</div>,
        id:     "index",
        width:  60,
        Cell:   ({ row }) => {
          return <div>{this.pageSize * (this.page - 1) + row.index + 1}</div>;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPin /> Point
          </div>
        ),
        id:       "point",
        accessor: (d) => {
          return d.point.name;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPerson /> Author
          </div>
        ),
        id:       "author",
        accessor: (d) => {
          return (d.author && d.author.fullName) || "Unknown user";
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Start
          </div>
        ),
        id:       "start",
        accessor: (d) => {
          return d.start.format("DD.MM.YYYY HH:mm");
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Finish
          </div>
        ),
        id:       "finish",
        accessor: (d) => {
          return d.finish.format("DD.MM.YYYY HH:mm");
        },
      },
    ];

    return columns;
  }

  @computed
  get columns() {
    const columns = [
      {
        Header: (
          <div className="meta-header">
            <IoMdCreate /> Label
          </div>
        ),
        minWidth: 250,
        accessor: "label",
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdRibbon /> Reference
          </div>
        ),
        minWidth: 150,
        accessor: "reference",
      },
    ];

    if (this.answers.length && this.answers[0]) {
      Object.keys(this.answers[0].directoryInfo).forEach((infoField) => {
        columns.push({
          Header: (
            <div className="meta-header">
              <IoMdInformationCircle /> {infoField}
            </div>
          ),
          id:       infoField,
          accessor: (d) => {
            return d.directoryInfo[infoField];
          },
        });
      });
    }

    this.params.forEach((param) => {
      let type = param.type;
      if (type === "list") {
        type = param.viewConfig;
      }
      switch (type) {
      case "linked":
      case "list_db":
        type = <IoMdInformationCircle />;
        break;
      case "number":
        type = <IoMdKeypad />;
        break;
      case "text":
        type = <IoMdCreate />;
        break;
      case "selectfield":
        type = <IoMdList />;
        break;
      case "multiselect":
        type = <IoMdDoneAll />;
        break;
      case "radiogroup":
        type = <IoMdRadioButtonOn />;
        break;
      case "file":
        type = <IoMdImage />;
        break;
      case "boolean":
        type = <IoMdSwitch />;
        break;
      case "date":
        type = <IoMdCalendar />;
        break;
      default:
        type = "";
        break;
      }

      columns.push({
        Header: (
          <div>
            {type} {param.name}
          </div>
        ),
        id:       `${param.id}`,
        accessor: (d) => {
          return d.answers[param.id] && d.answers[param.id].value;
        },
      });
    });
    return columns;
  }

  @computed
  get selectedParamsArray() {
    return Array.from(this.params.keys());
  }

  @action
  setDates({ startDate, endDate }) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  onSortedChange(sorted) {
    this.sortedArray.replace(sorted);
  }

  @action
  showTheTable(show) {
    this.showTable = show;
  }

  @action
  showTheReportTable(show) {
    this.showReportTable = show;
  }

  @action
  changeMon(mon) {
    this.params.clear();
    this.mon = mon;
  }

  @action
  setParam(paramId) {
    if (this.params.get(paramId)) {
      this.params.delete(paramId);
    } else {
      this.paramsArray.forEach((param) => {
        if (`${param.id}` === `${paramId}`) {
          this.params.set(param.id, param);
        }
      });
    }
  }

  @action
  setCurrentFact(id) {
    const fact = this.facts.get(id);
    this.fact = fact;
  }

  @action
  setAllParams() {
    if (this.paramsArray.length === this.selectedParamsArray.length) {
      this.params.clear();
    } else {
      this.paramsArray.forEach((param) => {
        this.params.set(param.id, param);
      });
    }
  }

  @action
  async loadPage(pageLength, page = 1) {
    if (!this.pageIsLoading) {
      this.pageIsLoading = true;
      this.page = page;
      if (pageLength) {
        this.pageLength = pageLength;
      }
      await this.getData();
      runInAction(() => {
        this.pageIsLoading = false;
      });
      return this.pages;
    }
  }

  @action
  async loadReportPage(pageLength, page = 1) {
    if (!this.reportPageIsLoading) {
      this.reportPageIsLoading = true;
      this.reportPage = page;
      if (pageLength) {
        this.reportPageLength = pageLength;
      }
      await this.getReportData();
      runInAction(() => {
        this.reportPageIsLoading = false;
      });
      return this.reportPages;
    }
  }

  @action
  async getItem(record) {
    let author = { fullName: "Unknown user" };
    try {
      author = await this.rootStore.employees.get(record.authorId);
    } catch (error) {
      console.warn(error); //eslint-disable-line
    }
    runInAction(() => {
      this.pendingPoints.set(record.pointId, true);
    });
    const point = await this.rootStore.points.addPoint(record.pointId);
    runInAction(() => {
      this.pendingPoints.delete(record.pointId);
    });

    const answer = {
      author,
      start:  moment(record.start),
      finish: moment(record.finish),
      id:     record.id,
      point,
    };
    return answer;
  }

  @action
  async getData() {
    const data = await this.api.getMonViewerReports(
      this.mon.id,
      this.startDate.toISOString(),
      this.endDate.toISOString(),
      this.page,
      this.pageLength
    );
    runInAction(() => {
      this.totalItems = data.totalCount;
    });
    const syncItems = [];
    data.records.forEach((record) => {
      syncItems.push(this.getItem(record));
    });
    const items = await Promise.all(syncItems);
    runInAction(() => {
      this.facts.clear();
      items.forEach((item) => {
        this.facts.set(item.id, item);
      });
    });
    return true;
  }

  @action
  async getReportData() {
    const data = await this.api.getMonViewerReporData(
      this.mon.id,
      this.fact.id
    );

    runInAction(() => {
      this.answers.clear();
      data.records.forEach((record) => {
        const answers = {};
        record.answers.forEach((answer) => {
          answers[answer.parameterId] = answer;
        });
        const item = { ...record, answers };
        this.answers.push(item);
      });
    });
    return true;
  }

  @computed
  get isPending() {
    return (
      // this.pageIsLoading ||
      // this.reportPageIsLoading ||
      this.isPendingData || this.pendingPoints.size > 0
    );
  }
}
