import { observable, action, computed } from "mobx";

import moment from "moment";

/**
 * Plan Model.
 */
export default class Plan {
  id;
  employee;
  point;
  status;
  @observable fact;
  @observable start; // allow only new object always
  @observable finish; // allow only new object always
  @observable planDate; // allow only new object always

  @observable isPending = false;

  @observable
  store;

  constructor(id, employee, point, start, finish, status, date, store) {
    this.id = Number(id);
    this.employee = employee;
    this.point = point;
    this.planDate = date;

    this.start = start ? moment(start) : null;
    this.finish = finish ? moment(finish) : null;

    this.status = status;

    this.store = store;
  }

  @computed
  get isSelected() {
    return this.store.selectedPlans.get(this.id) === true;
  }

  @computed
  get isFactSelected() {
    return this.store.selectedFacts.get(this.id);
  }

  @computed
  get date() {
    return this.start ? moment(this.start).startOf("date") : moment(this.planDate, "YYYY-MM-DD").startOf("date");
  }

  @action
  toggleSelection() {
    if (this.status === "done") {
      this.store.toggleFactSelection(this);
      return;
    }
    this.store.toggleEventSelection(this);
  }

  @action
  setFact(fact) {
    this.fact = fact;
  }

  @action
  changeTime(newStart, newFinish) {
    if (this.start && this.finish) {
      const start = moment(this.start.format("DD.MM.YYYY"), "DD.MM.YYYY");
      const finish = moment(this.finish.format("DD.MM.YYYY"), "DD.MM.YYYY");
      start.set({ hour: newStart.hour(), minute: newStart.minute() });
      finish.set({ hour: newFinish.hour(), minute: newFinish.minute() });
      this.start = start;
      this.finish = finish;
    }
  }

  @action
  changeDate(date) {
    const start = moment(date.format("DD.MM.YYYY"), "DD.MM.YYYY");
    const finish = moment(date.format("DD.MM.YYYY"), "DD.MM.YYYY");
    if (this.start && this.finish) {
      start.set({ hour: this.start.hour(), minute: this.start.minute() });
      finish.set({ hour: this.finish.hour(), minute: this.finish.minute() });
      this.start = start;
      this.finish = finish;
    }
    this.planDate = date.format("YYYY-MM-DD");
  }

  @action
  async save() {
    this.isPending = true;
    await this.store.savePlan(this);
    this.isPending = false;
  }
}
