import React from "react";
import { observer, inject } from "mobx-react";

import { Feed } from "~/modules/feed";
import { Map } from "~/modules/map";
import { Planning } from "~/modules/planning";
import { DataViewer } from "~/modules/dataViewer";
import { MonitoringViewer } from "~/modules/monitoringViewer";
import { Audits } from "~/modules/audits";
import LeftMenu from "./LeftMenu";
import JournalsModule from "~/modules/journals/components/JournalsModule";
import Profiles from "~/modules/profiles/Profiles";
import ActionPlanModule from "~/modules/actionPlan/components/ActionPlanModule";
import Gallery from "~/modules/gallery/Gallery";

/**
 * Главный компонент приложения.
 */
@inject("userStore", "rootStore")
@observer
class Workspace extends React.Component {
  /**
   * @constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = { fontSize: this.DEFAULT_FONTSIZE };
  }

  render() {
    const activeModule = "" || this.props.rootStore.module;

    // Active module
    const emptyModule = (
      <div className="info-no-module">← Choose module to use</div>
    );
    const modules = {
      feed:             <Feed />,
      plan:             <Planning />,
      map:              <Map />,
      audits:           <Audits />,
      dataViewer:       <DataViewer />,
      monitoringViewer: <MonitoringViewer />,
      journals:         <JournalsModule />,
      profiles:         <Profiles />,
      "action-plan":    <ActionPlanModule />,
      gallery:          <Gallery />,
    };
    const content = modules[activeModule] || emptyModule;

    return (
      <div className="main">
        <LeftMenu />
        <div className="content">{content}</div>
      </div>
    );
  }
}

export default Workspace;
