import { computed, observable } from "mobx";
import moment from "moment";

/**
 * ActionPlan Model.
 */
export default class ActionPlan {
  @observable id = null;
  @observable activityId = null;
  @observable store = null;

  @observable comments = null;
  @observable start = null;
  @observable finish = null;
  @observable brand = null;
  @observable pointId = null;
  @observable pointCode = null;
  @observable wasResponded = null;
  @observable answerIds = [];

  constructor(data, store) {
    this.store = store;

    this.id = `${data.id}`;
    this.comments = data.comments;
    if (data.start) {
      this.start = moment(data.start, "DD.MM.YYYY");
    }
    if (data.finish) {
      this.finish = moment(data.finish, "DD.MM.YYYY");
    }

    this.activityId = `${data.activity}`;

    this.answerIds = data.answerIds;
    this.brand = data.brand;
    this.pointId = data.pointId;
    this.pointCode = data.pointCode;
    this.wasResponded = data.wasResponded;
  }

  @computed
  get activity() {
    return this.store.getActivityById(this.activityId);
  }

  @computed
  get title() {
    return this.activity.label;
  }

  @computed
  get label() {
    return this.comments;
  }

  @computed
  get answersNumber() {
    return this.answerIds.length;
  }

  @computed
  get pointName() {
    const point = this.store.rootStore.points.get(this.pointId);
    const name = (point && point.name) || "Unknown point";
    return name;
  }

  @computed
  get answersArray() {
    const answersArray = [];
    this.answerIds.forEach((id) => {
      answersArray.push(this.store.getAnswerById(`${id}`));
    });
    return answersArray;
  }

  @computed
  get value() {
    return this.id;
  }
}
