import { observable, action, computed, runInAction } from "mobx";

import Point from "../models/Point";

/**
 * Store with points.
 */
export default class PointsStore {
  @observable pending = false;
  @observable isUserPending = false;

  @observable isUserPoints = "all";
  @observable filter = "";

  @observable sortField;
  @observable sortDESC;

  @observable userPointIds = [];
  @observable statusPointIds = [];

  @observable pointLevels = new Map();
  @observable pointsByCode = new Map();

  @observable points = new Map();
  @observable groups = [];

  constructor(root) {
    this.root = root;

    this.api = root.api;
    // if (root.user && root.user.isLoggedIn) {
    //   this.fetchPoints();
    // }
  }

  @action
  async init() {
    if (this.root.user.isLoggedIn) {
      await this.fetchPoints();
    }
  }

  @action
  changeIsUserPoints(isUserPoints) {
    this.isUserPoints = isUserPoints;
  }

  @action
  setFilter(filter) {
    this.filter = filter;
  }

  @action
  setSort(field) {
    if (this.sortField === field) {
      this.sortDESC = !this.sortDESC;
    } else {
      this.sortField = field;
      this.sortDESC = undefined;
    }
  }
  /**
   * Get all/user points as Array.
   */
  @computed
  get pointsArray() {
    let points = [];

    const filterLC = this.filter && this.filter.toLowerCase();
    if (this.isUserPoints === "all") {
      if (!this.filter || this.filter.length < 3) {
        points = Array.from(this.points.values());
      } else {
        this.points.forEach((value) => {
          if (
            value.name.toLowerCase().indexOf(filterLC) !== -1 ||
            value.code.toLowerCase().indexOf(filterLC) !== -1
          ) {
            points.push(value);
          }
        });
      }
    } else if (this.isUserPoints === "user") {
      this.userPointIds.forEach((pointId) => {
        const point = this.points.get(pointId);
        if (point) {
          if (
            !this.filter ||
            this.filter.length < 3 ||
            point.name.toLowerCase().indexOf(filterLC) !== -1 ||
            point.code.toLowerCase().indexOf(filterLC) !== -1
          ) {
            points.push(point);
          }
        }
      });
    } else if (this.isUserPoints === "status") {
      this.statusPointIds.forEach((pointId) => {
        const point = this.points.get(pointId);
        if (point) {
          if (
            !this.filter ||
            this.filter.length < 3 ||
            point.name.toLowerCase().indexOf(filterLC) !== -1 ||
            point.code.toLowerCase().indexOf(filterLC) !== -1
          ) {
            points.push(point);
          }
        }
      });
    }

    if (this.sortField) {
      points.sort((a, b) => {
        const result = a[this.sortField] > b[this.sortField];
        if (this.sortDESC) {
          return result ? -1 : 1;
        } else {
          return result ? 1 : -1;
        }
      });
    }

    return points;
  }

  @computed
  get fullPointArray() {
    const points = Array.from(this.points.values());
    return points;
  }
  /**
   * Get Point model by code.
   * @param {string} code
   */

  get(id) {
    let pk = id;
    if (!(typeof id === "number")) {
      pk = Number(id);
    }
    const point = this.points.get(pk);
    return point;
  }

  /**
   * Create new Point model instance.
   *
   * @param {number} id
   * @param {string} code
   * @param {string} name
   */

  @action
  async addPoint(id) {
    const point = this.get(id);
    if (point) {
      return point;
    } else {
      const pointData = await this.fetchPoint(id);
      return pointData;
    }
  }

  @action
  factory(data) {
    const point = new Point(data, this);
    this.points.set(point.id, point);
    this.pointsByCode.set(point.code, point);
    return this.points.get(point.id);
  }

  @action
  processPointTree(data) {
    const obj = this.processPointLevel("root", data);
    this.pointLevels.replace(obj);
  }

  @action
  processPointLevel(id, array) {
    const childrenObj = {};
    array.forEach((item) => {
      if (!item.leaf) {
        const obj = this.processPointLevel(item.text, item.data);
        childrenObj[item.text] = obj;
      } else {
        const point = this.pointsByCode.get(item.code);
        if (point) {
          point.setData(item.directoryData);
          childrenObj[point.id] = point;
        }
      }
    });
    const children = Object.values(childrenObj);
    return { children, name: id };
  }

  @action
  async fetchPoints() {
    try {
      this.pending = true;
      const pointsData = await this.api.getPoints();
      const statuses = await this.api.getStatuses();
      const { points, groups } = pointsData;

      points.forEach((point) => {
        this.factory(point);
      });

      const stausPointIds = [];
      statuses.forEach((point) => {
        stausPointIds.push(point.id);
        this.factory(point);
      });

      runInAction(() => {
        this.groups.replace(groups);
        this.statusPointIds.replace(stausPointIds);
        this.pending = false;
        // return true;
      });
    } catch (error) {
      runInAction(() => {
        this.pending = false;
      });
      console.error(error);
    }
  }

  @action
  async fetchUserPoints(uid) {
    try {
      this.isUserPending = true;
      const userPoints = await this.api.getUserPoints(uid);
      const { points } = userPoints;
      runInAction(() => {
        const tempPointIds = points.map((point) => {
          const storePoint = this.points.get(point.id);
          if (!storePoint) {
            this.factory(point);
          }
          return point.id;
        });
        this.userPointIds.replace(tempPointIds);
        this.isUserPending = false;
        return true;
      });
    } catch (error) {
      runInAction(() => {
        this.isUserPending = false;
      });
      console.warn(error);
    }
  }

  @action
  async fetchPoint(pointId) {
    try {
      const point = await this.api.getPoint(pointId);
      let newPoint;
      runInAction(() => {
        newPoint = this.factory(point);
      });
      return newPoint;
    } catch (error) {
      runInAction(() => {
        this.isUserPending = false;
      });
      console.warn(error);
    }
  }

  processLevel(level, key) {
    let children = [];
    if (level.inRoot) {
      children = [
        { id: "no_cat", name: "No category", children: level.inRoot },
      ];
    }
    const oddKeys = new Set(["id", "name", "inRoot"]);
    Object.keys(level).forEach((key) => {
      if (oddKeys.has(key)) {
        return;
      }
      const obj = level[key];
      if (!obj.id) {
        const child = this.processLevel(obj, key);
        if (child) {
          children.push(child);
        }
      } else {
        if (obj && obj.data) {
          children.push(obj.data);
        }
      }
    });

    return {
      id:   key || level.id,
      name: key || level.name,
      data: level.data,
      children,
    };
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get pointsRoot() {
    const levels = {};
    this.points.forEach((point) => {
      if (!(point.attributes && point.attributes.size)) {
        if (!levels.inRoot) {
          levels.inRoot = [];
        }
        levels.inRoot.push(point);
      } else {
        let branch = levels;
        this.groups.forEach((group) => {
          const key = point.attributes.get(group);
          if (key) {
            if (!branch[key]) {
              branch[key] = {};
            }
            branch = branch[key];
          }
        });
        if (branch) {
          branch[point.id] = { id: point.id, name: point.name, data: point };
        } else {
          if (!levels.inRoot) {
            levels.inRoot = [];
          }
          levels.inRoot.push(point);
        }
      }
    });

    const root = this.processLevel(levels, "root");
    return root;
  }
}
