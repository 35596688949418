import Coordinates from "~/common/models/Coordinates";
import { parseDateTime } from "~/common/datetime";
import { computed, toJS, observable, action, runInAction } from "mobx";
import Report from "./Report";

/**
 * Fact Model.
 */
export default class VisitFact {
  id;
  planId;
  @observable author;
  @observable point;

  @observable hasAuthor = false;
  @observable hasPoint = false;

  createdAt;
  start;
  finish;
  reportIds;

  @observable isPendingReports = true;
  @observable reports;

  constructor(id, planId, authorId, pointId, createdAt, start, finish, reportIds, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = id;
    this.planId = planId;
    this.reportIds = reportIds;
    this.getAuthor(authorId);
    this.getPoint(pointId);
    this.createdAt = parseDateTime(createdAt);

    this.start = {
      datetime: parseDateTime(start.datetime),
      coords:   new Coordinates(start.coords),
    };
    this.finish = {
      datetime: parseDateTime(finish.datetime),
      coords:   new Coordinates(finish.coords),
    };

    this.fetchReports();
  }

  @action
  async getAuthor(id) {
    const author = await this.store.rootStore.employees.get(id);
    runInAction(() => {
      this.author = author;
      this.hasAuthor = true;
    });
  }

  @action
  async getPoint(id) {
    const point = await this.store.rootStore.points.addPoint(id);
    runInAction(() => {
      this.point = point;
      this.hasPoint = true;
    });
  }

  @action
  async fetchReports() {
    this.isPendingReports = true;
    const requests = [];
    this.reportIds.forEach((reportId) => {
      requests.push(this.api.getReport(reportId));
    });

    const resps = await Promise.all(requests);

    const questsReq = [];
    resps.forEach((resp) => {
      if (resp.questId) {
        runInAction(() => {
          questsReq.push(this.store.rootStore.questStore.get(resp.questId));
        });
      } else {
        questsReq.push();
      }
    });

    await Promise.all(questsReq);

    runInAction(() => {
      this.reports = new Map();
      resps.forEach((reportData) => {
        const report = new Report(reportData, this.store);
        this.reports.set(report.id, report);
      });
      this.isPendingReports = false;
    });
  }

  @computed
  get reportArray() {
    const array = [];
    this.reports.forEach((report) => {
      array.push(report);
    });
    return array;
  }

  @computed
  get data() {
    return {
      id:        this.id,
      reportIds: toJS(this.reportIds),
      author:    this.author && `${this.author.lastName} ${this.author.firstName}`,
      planId:    this.factId,
      start:     {
        datetime: this.start.datetime.format("DD.MM.YYYY HH:mm"),
      },
      finish: {
        datetime: this.finish.datetime.format("DD.MM.YYYY HH:mm"),
      },
      point: {
        name: this.point && this.point.name,
        code: this.point && this.point.code,
      },
    };
  }

  @computed
  get hasPending() {
    let isPending = false;
    if (!this.reports) {
      return true;
    }
    this.reports.forEach((report) => {
      isPending = isPending || report.isPending;
    });
    return isPending;
  }

  @computed
  get isPending() {
    return this.hasPending || !(this.hasAuthor && this.hasPoint);
  }
}
