import { computed, observable } from "mobx";

/**
 * Activity Model.
 */
export default class Activity {
  @observable id;
  @observable name;
  @observable store;

  constructor(data, store) {
    this.store = store;

    this.id = `${data.id_}`; // eslint-disable-line
    this.name = data.name;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }
}
