import React, { useCallback, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

import Button from "~/common/components/Button";
import Answer from "./Answer";

const ActionPlanCard = observer((props) => {
  const { planItem } = props;
  const {
    title,
    answersNumber,
    comments,
    brand,
    start,
    finish,
    answersArray,
    pointCode,
    pointName,
  } = planItem;

  const [isAnswersVisible, setAnswersVisible] = useState(false);

  const onToggleAnswers = useCallback(() => {
    setAnswersVisible(!isAnswersVisible);
  }, [isAnswersVisible]);

  const answersRender = useMemo(() => {
    const answersRender = [];
    if (isAnswersVisible) {
      answersArray.forEach((answer) => {
        answersRender.push(<Answer key={`${answer.id}`} answer={answer} />);
      });
    }
    return answersRender;
  }, [answersArray, isAnswersVisible]);

  return (
    <div className="action-plan-card">
      <div className="plan-header">
        <div className="activity-name">{title}</div>
        <div className="period">
          {start.format("DD.MM.YYYY")} -
          {finish ? finish.format("DD.MM.YYYY") : "Infinite"}
        </div>
        {answersNumber > 0 && (
          <div className="answers-count" title="Total answers">
            {answersNumber}
          </div>
        )}
      </div>
      <div className="plan-body">
        <div className="point">
          <div className="point-code">{pointCode}</div>
          <div className="point-name">{pointName}</div>
        </div>
        <div className="title">Brand</div>
        <div className="brand">{brand}</div>
        <div className="title">Comments</div>
        <div className="comments">{comments}</div>
      </div>
      {answersNumber > 0 && (
        <Button
          className={"button inline active"}
          onClick={onToggleAnswers}
          leftIcon={isAnswersVisible ? <IoMdEyeOff /> : <IoMdEye />}
          text={isAnswersVisible ? "Hide answers" : "Show answers"}
        />
      )}
      {answersRender}
    </div>
  );
});

export default ActionPlanCard;
