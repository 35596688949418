import { observable, action, computed, toJS } from "mobx";
import { uuid } from "uuidv4";

import TreeStoreItem from "~/common/models/TreeStoreItem";
/**
 * A Store for Tree component.
 */
export default class TreeStore {
  @observable rootId;
  @observable items = new Map();
  @observable search = "";
  @observable searchFields = [];
  @observable filterValuesMap = new Map();
  @observable filterItems = new Map();
  @observable filterOptions = new Map();
  @observable filterMap = new Map();

  constructor(root, searchFields, filterMap) {
    this.searchFields = searchFields;
    this.init(root, filterMap);
  }

  @action
  init(root, filterMap) {
    if (filterMap) {
      this.filterMap = new Map();
      filterMap.forEach((id) => {
        this.filterMap.set(`${id}`, true);
      });
    }
    this.processItem(root, 0);
  }

  @action
  getItem(id) {
    return this.items.get(id);
  }

  @action
  changeSearchString(value) {
    this.search = value;
  }

  @action
  setFilterValue(id, value) {
    if (!value || !value.length) {
      this.filterValuesMap.delete(`${id}`);
      return;
    }
    return this.filterValuesMap.set(`${id}`, value);
  }

  @action
  addFilterValue(value, filter, uid) {
    const filterOptionsMap = this.filterOptions.get(filter);
    if (filterOptionsMap) {
      filterOptionsMap.set(value, true);
    } else {
      this.filterOptions.set(filter, new Map([[value, true]]));
    }

    const filterItemsMap = this.filterItems.get(value);
    if (filterItemsMap) {
      filterItemsMap.set(uid, true);
    } else {
      this.filterItems.set(value, new Map([[uid, true]]));
    }
  }

  @action
  processItem(item, level) {
    const uid = uuid();
    const childIds = [];
    if (level === 0) {
      this.rootId = uid;
    }
    if (item.children && item.children.length) {
      item.children.forEach((child) => {
        const childId = this.processItem(child, level + 1);
        childIds.push(childId);
      });
      if (level !== 0) {
        this.addFilterValue(item.name, level, uid);
      }
    }
    const treeItem = new TreeStoreItem(
      {
        id: uid,
        childIds,
        level,
        data: { ...item, children: undefined },
      },
      this
    );
    this.items.set(uid, treeItem);

    return uid;
  }

  @computed
  get fields() {
    return toJS(this.searchFields);
  }

  @computed
  get root() {
    const root = this.getItem(this.rootId);
    return root.treeData;
  }

  @computed
  get filters() {
    const filters = [];
    this.filterOptions.forEach((value, filterId) => {
      const options = [];
      value.forEach((val, key) => {
        options.push({ label: key, id: key });
      });
      filters.push({ id: filterId, options });
    });
    return filters.reverse();
  }

  @computed
  get filterValues() {
    return toJS(this.filterValuesMap);
  }
}
