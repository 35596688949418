import React from "react";
import { observer, inject } from "mobx-react";
import { IoIosPerson } from "react-icons/io";

import Tree from "~/common/components/Tree";
import EmployeeItem from "./EmployeeItem";

@inject("employeesStore")
@observer
class EmployeeTree extends React.Component {
  constructor(props) {
    super(props);

    this.itemRender = this.itemRender.bind(this);

    this.state = {};
  }

  itemRender(item) {
    const { onSelect, selectedIds } = this.props;
    return (
      <EmployeeItem selectedIds={selectedIds} onSelect={onSelect} item={item} />
    );
  }

  iconRender() {
    return <IoIosPerson />;
  }

  render() {
    const { employeesStore, filterIds } = this.props;
    const { hasPendingItems, usersRoot } = employeesStore;

    return (
      <Tree
        isPending={hasPendingItems}
        iconRender={this.iconRender}
        filterIds={filterIds}
        searchFields={["name", "firstName", "lastName"]}
        root={usersRoot}
        itemRender={this.itemRender}
      />
    );
  }
}

export default EmployeeTree;
