import React from "react";
import { observer } from "mobx-react";
import {
  IoMdCheckmarkCircleOutline,
  IoMdPerson,
  IoMdRadioButtonOff,
} from "react-icons/io";

const Answer = observer((props) => {
  const { answer } = props;
  const { complies, authorName, comment } = answer;
  return (
    <div className="answer">
      <div className="answer-body">
        <div className={`answer-header ${complies ? "complies" : ""}`}>
          <div className="answer-title">Compiles: </div>
          {complies ? <IoMdCheckmarkCircleOutline /> : <IoMdRadioButtonOff />}
        </div>
        <div className="title">Author</div>
        <div className="author">
          <IoMdPerson /> {authorName}
        </div>
        <div className="title">Comment</div>
        <div className="comment">{comment ? comment : "No comment"}</div>
      </div>
    </div>
  );
});

export default Answer;
