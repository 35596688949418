import React, { useMemo } from "react";
import { IoMdCalculator } from "react-icons/io";
import { observer } from "mobx-react";

const Number = observer((props) => {
  const { config } = props;
  const { label, value, isValid, changeValue, isDisabled, required } = config;

  const onChange = (e) => {
    const rawValue = e.target.value;
    const value = (rawValue || "").replace(",", ".");
    changeValue(value);
  };
  
  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  return (
    <div className={`input-div ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          <IoMdCalculator />
        </div>
        <input
          size={2}
          type="text"
          className="text"
          readOnly={isDisabled}
          value={value || ""}
          onChange={onChange}
        />
      </div>
    </div>
  );
});

export default Number;
