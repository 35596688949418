import { observable, computed } from "mobx";

/**
 * TreeStoreItem data model.
 */
export default class TreeStoreItem {
  @observable id;
  @observable childIds = [];
  @observable level;

  data;

  constructor(props, store) {
    this.store = store;
    this.id = props.id;
    this.level = props.level;

    this.childIds = props.childIds;
    this.isLeaf = this.childIds.length === 0;

    this.data = props.data;
  }

  @computed
  get children() {
    const children = [];
    this.childIds.forEach((id) => {
      const child = this.store.getItem(id);
      if (child) {
        children.push(child);
      }
    });
    return children;
  }

  @computed
  get passes() {
    const passes = true;
    const value = this.store.filterValuesMap.get(`${this.level}`);
    if (this.data.id) {
      if (this.store.filterMap && this.store.filterMap.size) {
        if (!this.store.filterMap.has(`${this.data.id}`)) {
          return false;
        }
      }
    }

    if (value) {
      if (!this.isLeaf) {
        const filtetredItems = this.store.filterItems.get(
          value[0] && value[0].id
        );
        if (!(filtetredItems && filtetredItems.has(this.id))) {
          return false;
        }
      }
    }
    return passes;
  }

  @computed
  get treeData() {
    if (!this.passes) {
      return null;
    }
    const children = [];
    this.children.forEach((child) => {
      const treeData = child.treeData;
      if (treeData) {
        children.push(treeData);
      }
    });
    if (!this.isLeaf && children.length === 0) {
      return null;
    }

    if (this.isLeaf && this.store.search !== "") {
      const search = this.store.search.toLowerCase();
      const searchFields = this.store.fields;
      let notFound = true;
      searchFields.forEach((field) => {
        const fieldValue = this.data[field];
        if (fieldValue) {
          const fieldValueLc = fieldValue.toLowerCase();
          if (fieldValueLc.indexOf(search) !== -1) {
            notFound = false;
          }
        }
      });
      if (notFound) {
        return null;
      }
    }

    const obj = { ...this.data, uid: this.id };
    if (children.length > 0) {
      obj.children = children;
    }
    return obj;
  }
}
