import React from "react";
import { observer } from "mobx-react";

import Button from "~/common/components/Button";
import TreeStore from "~/common/stores/TreeStore";
import TreeItem from "./TreeItem";
import FilterSelect from "./FilterSelect";
import { IoIosFunnel, IoIosSearch } from "react-icons/io";

@observer
class Tree extends React.Component {
  constructor(props) {
    super(props);

    this.onToggleFilter = this.onToggleFilter.bind(this);
    this.onToggleSearch = this.onToggleSearch.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);

    this.store = new TreeStore(props.root, props.searchFields, props.filterIds);

    this.state = {
      isPending:       false,
      isFilterVisible: false,
      isSearchVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.root !== this.props.root || newProps.filterIds !== this.props.filterIds) {
      this.store.init(newProps.root, newProps.filterIds);
    }
  }

  onChangeFilter(id, value) {
    this.store.setFilterValue(id, value);
  }

  onToggleFilter() {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
  }

  onToggleSearch() {}

  onSearchChange(e) {
    this.store.changeSearchString(e.target.value);
  }

  render() {
    const { itemRender, iconRender, isPending } = this.props;
    const { isFilterVisible } = this.state;
    const { root, filters, filterValues, search } = this.store;
    const items = [];
    if (root && root.children && root.children.length > 0) {
      root.children.forEach((item) => {
        if (item && item.children && item.children.length > 0) {
          items.push(
            <TreeItem
              isSearching={!!search}
              iconRender={iconRender}
              key={item.id || item.name}
              item={item}
              itemRender={itemRender}
            />
          );
        }
      });
    }
    const filtersRender = [];

    filters.forEach((filter) => {
      filtersRender.push(
        <FilterSelect
          key={filter.id}
          className="select-filter"
          name="employee"
          placeholder={`Level ${filter.id} ...`}
          filterId={filter.id}
          value={filterValues}
          isMulti={true}
          valueKey={"id"}
          labelKey={"label"}
          onChange={this.onChangeFilter}
          options={filter.options}
        />
      );
    });
    return (
      <div className="tree-component-content">
        <div className="buttons">
          <Button
            title={"Filter"}
            className={`button inline solo ${isFilterVisible ? "active" : ""}`}
            onClick={this.onToggleFilter}
            leftIcon={<IoIosFunnel />}
          />
          <div className="input-icon">
            <IoIosSearch />
          </div>
          <input
            type="text"
            placeholder="Filter"
            onChange={this.onSearchChange}
            value={search}
            className="search-input"
            name="search"
          />
        </div>
        <div className="tree-content">
          {isFilterVisible && <div className="filters">{filtersRender}</div>}
          {isPending && (
            <div className="points-preloader">
              <div />
            </div>
          )}
          {!isPending && <div className="items">{items}</div>}
        </div>
      </div>
    );
  }
}

export default Tree;
