import React from "react";
import { inject, observer } from "mobx-react";

import Workspace from "~/workspace/Workspace";
import Login from "~/workspace/Login";

/**
 * Обертка над Менеджером и Экраном логина.
 * Показывает один из них в зависимости от свойства isLoggedIn.
 */
@inject("userStore")
@observer
class Main extends React.Component {
  render() {
    const isLoggedIn = this.props.userStore.isLoggedIn;
    return <div className="App">{isLoggedIn ? <Workspace /> : <Login />}</div>;
  }
}

export default Main;
