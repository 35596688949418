import React, { useMemo, useRef, useState } from "react";
import { IoMdCreate, IoMdDocument } from "react-icons/io";
import { observer } from "mobx-react";

const Text = observer((props) => {
  const { config } = props;
  const { label, value, isValid, changeValue, isDisabled, required, viewConfig } = config;

  const isTextArea = viewConfig === "textarea";
  
  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  const textareaRef = useRef(null);
  const [height, setHeight] = useState(0);

  const onChange = (e) => {
    const value = e.target.value;
    changeValue(value);
    if (textareaRef && textareaRef.current) {
      const el = textareaRef.current;
      setHeight(el.scrollHeight);
    }
  };

  return (
    <div className={`input-div ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          {isTextArea ? <IoMdDocument /> : <IoMdCreate />}
        </div>
        { !isTextArea && (
          <input
            size={2}
            type="text"
            className="text"
            readOnly={isDisabled}
            value={value || ""}
            onChange={onChange}
          />
        )}
        { isTextArea && (
          <textarea
            style={{ height: `${height}px` }}
            ref={textareaRef}
            className="text"
            readOnly={isDisabled}
            onChange={onChange}
            value={value || ""}
          />
        )}
      </div>
    </div>
  );
});

export default Text;
