import React from "react";
import Select from "react-select";

/**
 * Employees Select Box
 */
const JournalAutocompleteSelect = ({ options, value, onChange }) => {
  return (
    <Select
      className="select-quest"
      classNamePrefix="manager-select"
      name="journal"
      placeholder="Select journal..."
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default JournalAutocompleteSelect;
