import React from "react";
import { observer, inject } from "mobx-react";
import base64 from "base-64";
import { saveAs } from "file-saver";
import { IoMdCloudDownload, IoMdEye } from "react-icons/io";

import "react-tabs/style/react-tabs.css";

import QuestAutocompleteSelect from "./QuestAutocompleteSelect";
import ParamsCheckboxTable from "./ParamsCheckboxTable";
import { Button } from "~/common/components";
import FromTo from "~/common/components/FromTo";

@inject(
  "userStore",
  "employeesStore",
  "pointsStore",
  "auditStore",
  "dataViewerStore"
)
@observer
class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.onQuestChange = this.onQuestChange.bind(this);
    this.onGetData = this.onGetData.bind(this);
    this.onGetXcel = this.onGetXcel.bind(this);
    this.setDates = this.setDates.bind(this);

    this.state = {
      focusedInput: undefined,
      isLoadingFile: false,
      loadingFileError: false,
    };
  }

  onQuestChange(quest) {
    this.props.dataViewerStore.changeQuest(quest);
  }

  onGetData() {
    const { dataViewerStore } = this.props;
    dataViewerStore.loadPage();
  }

  onGetXcel() {
    this.setState({
      isLoadingFile: true,
      loadingFileError: false,
    });
    const { uid, token } = this.props.userStore;
    const {
      quest,
      selectedParamsArray,
      startDate,
      endDate,
      page,
      pageLength,
    } = this.props.dataViewerStore;
    const from = startDate.toISOString();
    const to = endDate.toISOString();
    let paramsString = "";
    if (selectedParamsArray.length) {
      paramsString = `&param=${selectedParamsArray.join("&param=")}`;
    }
    const downloadUrl = `/api/v4/quests/${quest.id}/reports/xlsx?from=${from}&to=${to}&page=${page}&perPage=${pageLength}&user=${uid}&token=${token}${paramsString}`;
    const options = {
      method: "GET",
      headers: {
        Authorization: `Basic ${base64.encode(`${uid}:${token}`)}`,
        Accept: "application/vnd.ms-excel",
      },
    };
    fetch(downloadUrl, options).then(
      (response) => {
        response.blob().then(
          (blob) => {
            saveAs(blob, `${quest.name}.xlsx`);
            this.setState({
              isLoadingFile: false,
            });
          },
          () => {
            this.setState({
              isLoadingFile: false,
              loadingFileError: true,
            });
          }
        );
      },
      () => {
        this.setState({
          isLoadingFile: false,
          loadingFileError: true,
        });
      }
    );
  }

  setDates({ startDate, endDate }) {
    const { dataViewerStore } = this.props;
    dataViewerStore.setDates({ startDate, endDate });
  }

  render() {
    const { dataViewerStore } = this.props;
    const {
      canRequest,
      quest,
      questArray,
      startDate,
      endDate,
    } = dataViewerStore;
    const { isLoadingFile, loadingFileError } = this.state;

    return (
      <div className="request-form">
        <div className="buttons">
          <Button
            className={"button inline success"}
            disabled={!canRequest}
            onClick={this.onGetData}
            leftIcon={<IoMdEye />}
            text="View data"
          />
          <Button
            className={"button inline active"}
            disabled={!canRequest || isLoadingFile}
            onClick={this.onGetXcel}
            leftIcon={<IoMdCloudDownload />}
            text="Download"
            isLoading={isLoadingFile}
            isError={loadingFileError}
          />
        </div>
        <QuestAutocompleteSelect
          value={quest}
          options={questArray}
          onChange={this.onQuestChange}
        />
        <FromTo
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.setDates}
        />
        <div className="params-table-holder">
          <ParamsCheckboxTable />
        </div>
      </div>
    );
  }
}

export default RequestForm;
