import React from "react";
import { observer, inject } from "mobx-react";
import { IoIosPin } from "react-icons/io";

import Tree from "~/common/components/Tree";
import PointIerarchyItem from "./PointIerarchyItem";

@inject("pointsStore")
@observer
class PointsTree extends React.Component {
  constructor(props) {
    super(props);

    this.itemRender = this.itemRender.bind(this);
    this.state = {};
  }

  itemRender(item) {
    const { onSelect, selectedIds } = this.props;
    return (
      <PointIerarchyItem
        selectedIds={selectedIds}
        onSelect={onSelect}
        item={item}
      />
    );
  }

  iconRender() {
    return <IoIosPin />;
  }

  render() {
    const { pointsStore } = this.props;
    const { isPending, pointsRoot } = pointsStore;

    if (isPending) {
      return (
        <div className="points-preloader">
          <div />
        </div>
      );
    }

    return (
      <Tree
        isPending={isPending}
        root={pointsRoot}
        searchFields={["name", "code"]}
        iconRender={this.iconRender}
        itemRender={this.itemRender}
      />
    );
  }
}

export default PointsTree;
