import React from "react";
import moment from "moment";
import { observer } from "mobx-react";

import { stringifyDate } from "~/common/datetime";

import Day from "./Day";

@observer
class Calendar extends React.Component {
  render() {
    const {
      range,
      events,
      minEventDuration,
      eventTimeStep,
      startOfDay,
      finishOfDay,
      gridStep,
    } = this.props;
    const headers = [];
    const hours = [];
    let content = (
      <div className="empty-plan">
        Choose date range and employee to manage plans.
      </div>
    );
    if (range && range.length > 0) {
      const numGrid = finishOfDay.diff(startOfDay, "m") / gridStep; // number of hours
      const start = moment(startOfDay);
      // eslint-disable-next-line
      for (let i = 0; i < numGrid; i += 1) {
        // the cycle to add grid lines
        hours.push(
          <div key={i} className="hour">
            <div className="hour-title">
              {start.add(i ? gridStep : 0, "minutes").format("HH:mm")}
            </div>
          </div>
        );
      }

      const days = [];
      range.forEach((day, i) => {
        const dayEvents = events[stringifyDate(day)];
        headers.push(
          <div key={i} className="day-header">
            <div>{day.format("DD.MM.YYYY")}</div>
            <div>{day.format("dddd")}</div>
          </div>
        );
        days.push(
          <Day
            key={i}
            day={day}
            events={dayEvents}
            index={i}
            minEventDuration={minEventDuration}
            eventTimeStep={eventTimeStep}
            startOfDay={startOfDay}
            finishOfDay={finishOfDay}
          />
        );
      });
      content = days;
    }
    return (
      <div className="plan">
        <div className="header">{headers}</div>
        <div className="content">
          <div className="timescale">{hours}</div>
          <div className="plan-week">{content}</div>
        </div>
      </div>
    );
  }
}

export default Calendar;
