import Coordinates from "~/common/models/Coordinates";
import { runInAction, action, observable, computed } from "mobx";
import { parseDateTime } from "~/common/datetime";
import MonitoringFact from "./MonitoringFact";

/**
 * Fact Model.
 */
export default class Report {
  id;
  @observable author;
  @observable point;
  @observable quest;

  @observable hasAuthor = false;
  @observable hasPoint = false;
  @observable hasQuest = false;

  name;
  createdAt;
  start;
  finish;

  @observable values = new Map();
  @observable monitorings = new Map();

  store;
  constructor(data, store) {
    this.store = store;

    this.id = data.id;
    this.name = data.name;

    this.getAuthor(data.authorId);
    this.getPoint(data.pointId);
    this.getQuest(data.questId);

    this.getMonitorings(data.monitoringReports);

    this.values.replace(data.values);

    this.start = {
      datetime: parseDateTime(data.start.datetime),
      coords: new Coordinates(data.start.coords),
    };
    this.finish = {
      datetime: parseDateTime(data.finish.datetime),
      coords: new Coordinates(data.finish.coords),
    };
  }

  @action
  async getAuthor(id) {
    const author = await this.store.rootStore.employees.get(id);
    runInAction(() => {
      this.author = author;
      this.hasAuthor = true;
    });
  }

  @action
  async getPoint(id) {
    const point = await this.store.rootStore.points.addPoint(id);
    runInAction(() => {
      this.point = point;
      this.hasPoint = true;
    });
  }

  @action
  async getQuest(id) {
    const quest = await this.store.rootStore.questStore.get(id);
    runInAction(() => {
      this.quest = quest;
      this.hasQuest = true;
    });
  }

  @action
  async getMonitorings(monitorings) {
    monitorings.forEach((mon) => {
      const monitoring = new MonitoringFact(mon, this.store);
      this.monitorings.set(mon.monitoringId, monitoring);
    });
  }

  @computed
  get valuesObj() {
    const vals = {};
    this.values.forEach((val, i) => {
      if (val.type !== "file") {
        vals[i] = val.value;
      } else {
        vals[i] = val.id;
      }
    });
    return vals;
  }

  @computed
  get monArray() {
    const array = [];
    this.monitorings.forEach((mon) => {
      array.push(mon);
    });
    return array;
  }

  @computed
  get isPendingMonitorings() {
    let isPending = false;
    this.monitorings.forEach((mon) => {
      isPending = mon.isPending || isPending;
    });
    return isPending;
  }

  @computed
  get isPending() {
    return this.isPendingMonitorings || !(this.hasAuthor && this.hasPoint && this.hasQuest);
  }
}
