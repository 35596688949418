import React from "react";

import { DayPickerRangeController } from "react-dates";

export default class WeekPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: undefined,
    };
  }

  render() {
    const { begin, end } = this.props;
    return (
      <DayPickerRangeController
        startDate={begin} // momentPropTypes.momentObj or null,
        endDate={end} // momentPropTypes.momentObj or null,
        onDatesChange={({ startDate, endDate }) => {
          // возвращает object, нужно его развернуть
          this.props.onChange(startDate, endDate);
        }} // PropTypes.func.isRequired,
        startDateOffset={(day) => {
          return day.subtract(day.isoWeekday() - 1, "days");
        }}
        endDateOffset={(day) => {
          return day.add(7 - day.isoWeekday(), "days");
        }}
        firstDayOfWeek={1}
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => {
          return this.setState({ focusedInput });
        }} // PropTypes.func.isRequired,
        // renderCalendarInfo={() => {}}
      />
    );
  }
}
