import * as mobx from "mobx";
import moment from "moment";

import { stringifyDate, stringifyTime } from "~/common/datetime";

import { Plan } from "../models";
// import Fact from "~/common/models/Fact";

const { observable, action, runInAction, computed, reaction } = mobx;

class PlanningStore {
  @observable planStore;

  @observable workdayStart = moment("08:00:00", "HH:mm:ss");
  @observable workdayFinish = moment("20:00:00", "HH:mm:ss");
  @observable planMinimumLength = 10;
  @observable gridStepMinutes = 60;

  @observable employee; // сотрудник, кому планируем визиты, экземпляр Employee
  @observable.struct period = { begin: null, end: null }; // период планирования

  @observable plans = new Map(); // планы текущего пользователя по дням
  @observable facts = new Map(); // факты

  @observable isAllSelected = false; // выделены все возможные
  @observable selectedPlans = new Map();
  @observable selectedFacts = new Map();

  constructor(root) {
    this.api = root.api;
    this.root = root;
    this.planStore = root.planStore;

    // Реакция на изменение this.employee и this.period. Если все параметры есть - запустим viewPlan
    this.viewPlansReaction = reaction(
      () =>
      // Реагируем на
      {
        return [this.employee, this.period.begin, this.period.end];
      },
      (args) => {
        // Реакция это показать планы
        const [employee, begin, end] = args;
        if (employee && begin && end) {
          if (root.user && root.user.isLoggedIn) {
            this.fetchPlans(employee, begin, end);
          }
        }
      },
      {
        equals: (prev, next) => {
          // Реакция только при изменении (если false)
          // FYI: Можно не использовать если @observable это @observable.struct
          // see: https://mobx.js.org/best/store.html
        },
      }
    );
  }

  @action
  async getConfig() {
    const config = await this.api.getPlanningConfig();
    runInAction(() => {
      this.workdayStart = moment(config.workdayStart, "HH:mm:ss");
      this.workdayFinish = moment(config.workdayFinish, "HH:mm:ss");
      this.planMinimumLength = config.planMinimumLength;
      this.gridStepMinutes = config.gridStep;
    });
  }

  @action
  init() {
    if (this.root.user.isLoggedIn) {
      this.getConfig();
    } // Реакция на изменение this.employee и this.period. Если все параметры есть - запустим viewPlan
    this.viewPlansReaction = reaction(
      () =>
      // Реагируем на
      {
        return [this.employee, this.period.begin, this.period.end];
      },
      (args) => {
        // Реакция это показать планы
        const [employee, begin, end] = args;
        if (employee && begin && end) {
          if (this.root.user.isLoggedIn) {
            this.getConfig();
            this.fetchPlans(employee, begin, end);
          }
        }
      },
      {
        equals: (prev, next) => {
          // Реакция только при изменении (если false)
          // FYI: Можно не использовать если @observable это @observable.struct
          // see: https://mobx.js.org/best/store.html
        },
      }
    );
  }

  /**
   * Get entity by type and id from store.
   *
   * @param {string} id
   */
  get(type, id) {
    let entityType = type;
    if (type === "Plan") {
      entityType = "plans";
    }
    if (type === "Fact") {
      entityType = "facts";
    }
    const store = this[entityType];
    if (!store) {
      throw new Error(`Unknown PlanningStore entity type: ${type}`);
    }
  }

  @action
  changeSelectAll(val) {
    this.isAllSelected = val;
    this.selectedPlans.clear();
    this.selectedFacts.clear();
    if (val) {
      this.plans.forEach((plan) => {
        if (plan.status !== "done") {
          this.selectedPlans.set(plan.id, val);
        }
      });
    }
  }
  /**
   * Decode Plan from json response.
   *
   * @param {object} ds
   * @returns {Plan}
   */
  @action
  async decodePlanFromJSON(ds) {
    const id = parseInt(ds.id, 10);
    const assigned = this.employee;
    const point = await this.root.points.addPoint(ds.point.id);
    const start = ds.start && ds.start.datetime ? ds.start.datetime : null;
    const finish = ds.finish && ds.finish.datetime ? ds.finish.datetime : null;
    let status = ds.status;
    if (status === "on approval") {
      status = "onapproval";
    }

    const newPlanItem = new Plan(
      id,
      assigned,
      point,
      start,
      finish,
      status,
      ds.date,
      this
    );
    const storedPlanItem = this.planStore.addPlan(newPlanItem);
    return storedPlanItem;
  }

  /**
   * Список дней для планирования.
   */
  @computed
  get daysRange() {
    if (!this.period.begin && !this.period.end) {
      return [];
    }

    const days = [];
    const { begin, end } = this.period;

    let day = moment(begin).add(-1, "days"); // копируем объект чтобы не поменять его
    // getting rest of the dates between begin & end dates
    // eslint-disable-next-line
    while (day < end) {
      days.push(day);
      day = moment(day.add(1, "days"));
    }
    return days;
  }

  /**
   * Сами планы объектом.
   *
   * Отдает только попадающие в текущий период дат.
   */
  @computed
  get events() {
    const plans = {};
    // готовим ключи для this.plans
    this.daysRange.forEach((date) => {
      plans[stringifyDate(date)] = []; // ключи только строки
    });
    this.plans.forEach((plan) => {
      const key = stringifyDate(plan.date);

      if (key in plans) {
        plans[key].push(plan);
      }
    });
    return plans;
  }

  /**
   * Toggle Event selection.
   *
   * @param {Plan} plan
   */
  @action
  toggleEventSelection(plan) {
    if (this.selectedPlans.get(plan.id)) {
      this.selectedPlans.delete(plan.id);
    } else {
      this.selectedPlans.set(plan.id, true);
    }
  }

  @action
  toggleFactSelection(plan) {
    if (this.selectedFacts.get(plan.id)) {
      this.selectedFacts.delete(plan.id);
    } else {
      this.selectedFacts.set(plan.id, plan);
    }
  }

  get selectedIds() {
    const ids = [];
    this.selectedPlans.forEach((v, k) => {
      if (v === true) {
        ids.push(k);
      }
    });
    return ids;
  }

  get selectedFactIds() {
    const ids = [];
    this.selectedFacts.forEach((v, k) => {
      ids.push(v.fact.id);
    });
    return ids;
  }

  async changeStatusForSelected(status) {
    const selected = this.selectedIds;
    const result = await this.api.changeStatus(
      this.employee.id,
      selected,
      status
    );
    const plans = {};
    const que = [];
    result.forEach(async(ds) => {
      que.push(this.decodePlanFromJSON(ds));
    });
    const queResult = await Promise.all(que);
    queResult.forEach((plan) => {
      plans[plan.id] = plan;
    });

    runInAction(() => {
      this.plans.merge(plans);
      this.selectedPlans.clear();
      this.isAllSelected = false;
    });
  }

  @action
  async deleteSelected() {
    const selected = this.selectedIds;
    const result = await this.api.deletePlans(this.employee.id, selected);
    runInAction(() => {
      result.forEach((ds) => {
        this.plans.delete(ds.id.toString()); // delete у Map работает только со строкой!
      });
      this.selectedPlans.clear();
      this.isAllSelected = false;
    });
  }

  @action
  async setAudit(employee) {
    const selected = this.selectedFactIds;
    if (employee) {
      await this.api.setAudit(employee.id, selected);
    }
  }
  @action
  unsetAudit() {
    console.log("planningStore: unsetAudit()");
  }
  @action
  getAudit() {
    console.log("planningStore: getAudit()");
  }
  /**
   * Убрать все планы и факты из стора.
   */
  @action
  clear() {
    this.plans.clear();
    this.facts.clear();
    this.selectedPlans.clear();
    this.selectedFacts.clear();
    this.isAllSelected = false;
  }

  /**
   * Указать пользователя для планирования.
   *
   * @param {Employee} employee
   */
  @action
  changeEmployee(employee) {
    this.employee = employee;
    if (!employee) {
      this.clear(); // Если убрали пользователя то уберем и его данные
    } else {
      this.root.points.fetchUserPoints(employee.id);
    }
  }

  /**
   * Указать период планирования.
   *
   * @param {Moment} begin
   * @param {Moment} end
   */
  @action
  changePeriod(begin, end) {
    this.period = { begin, end };
  }

  /**
   * Загрузить планы с бекенда, положить в this.plans.
   */
  @action
  async fetchPlans(employee, begin, end) {
    const plans = {};
    const facts = {};
    this.clear();
    try {
      const response = await this.api.getPlans(employee.id, begin, end);
      if (response.length > 0) {
        const planArray = [];
        const factsArray = [];
        response.forEach((item) => {
          planArray.push(this.decodePlanFromJSON(item));
          if (item.fact) {
            factsArray.push(item.fact);
          }
        });
        const plansFetched = await Promise.all(planArray);
        plansFetched.forEach((plan) => {
          plans[plan.id] = plan;
        });

        factsArray.forEach((factItem) => {
          const { id, planId, start, end, coords, auditIds } = factItem;
          const plan = this.root.planStore.plans.get(planId);
          const fact = this.createFact(
            id,
            plan,
            start,
            end,
            coords,
            false,
            auditIds
          );
          facts[fact.id] = fact;
        });
        runInAction(() => {
          // парсим данные и меняем куском. push в this.plans нельзя, будет в разы медленнее
          this.plans.merge(plans);
          this.facts.merge(facts);
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }

  /**
   * Добавить план на сервер и в стор.
   *
   * @param {Point} point
   * @param {Moment} date
   * @param {Moment} start
   * @param {Moment} finish
   * @param {string} status
   */
  @action
  async addPlan(point, date, start, finish, status = "onapproval") {
    const item = await this.api.createPlan(
      this.employee.id,
      point.code,
      stringifyDate(date),
      start && stringifyTime(start),
      finish && stringifyTime(finish),
      status
    );
    const plan = await this.decodePlanFromJSON(item);
    let storedPlanItem;
    runInAction(() => {
      this.plans.set(plan.id.toString(), plan);
    });
    return storedPlanItem;
  }

  @action
  async savePlan(plan) {
    const ds = await this.api.savePlan(
      plan.id,
      plan.point.id,
      stringifyDate(plan.date),
      plan.start && stringifyTime(plan.start),
      plan.finish && stringifyTime(plan.finish),
      plan.status
    );
    await this.decodePlanFromJSON(ds);
  }

  /**
   * Создать Fact в сторе.
   *
   * @param {number} id
   * @param {Plan||number} plan
   */
  @action
  createFact(id, plan, start, end, coords, doAttachToStore = true, auditIds) {
    // TODO: переписать на модели факта
    const fact = {
      id,
      plan,
      start,
      end,
      coords,
      auditIds,
    };
    if (doAttachToStore === true) {
      this.facts.set(id, fact);
    }
    plan.setFact(fact);
    return fact;
  }
}

export default PlanningStore;
