import { observable, action, runInAction, computed } from "mobx";
import Journal from "../models/Journal";
import JournalFact from "../models/JournalFact";

export default class JournalsStore {
  @observable isPendingData = false;
  @observable pendingPoints = new Map();
  @observable pageIsLoading = false;

  @observable employee;
  @observable point;

  @observable journal;
  @observable journals = new Map();

  @observable fact;
  @observable facts = new Map();
  @observable showTable = false;

  @observable startDate;
  @observable endDate;

  @observable pageLength = 100;
  @observable page = 1;
  @observable pages = 0;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.isPendingData = true;
    await this.getJournals();
    runInAction(() => {
      this.isPendingData = false;
    });
  }

  @action
  async getJournals() {
    const journalsData = await this.api.getJournals();
    if (journalsData) {
      journalsData.forEach((journalData) => {
        this.addJournal(journalData);
      });
    }
    runInAction(() => {
      this.isPendingData = false;
    });
  }

  @action
  addJournal(data) {
    const journal = new Journal(data, this);
    if (journal) {
      this.journals.set(journal.id, journal);
      return journal;
    }
    return null;
  }

  @action
  setDates({ startDate, endDate }) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  changeJournal(journal) {
    this.journal = this.journals.get(journal.id);
  }

  @action
  addFact(factData) {
    const fact = new JournalFact(factData, this);
    this.facts.set(fact.id, fact);
  }

  @action
  async loadPage(page = 1) {
    if (!this.pageIsLoading) {
      this.page = page;
      this.pageIsLoading = true;
      this.page = page;
      this.facts.clear();
      this.fact = undefined;

      const factsResponse = await this.api.getJournalReports(
        page,
        this.pageLength,
        this.employee && [this.employee.id],
        this.journal.id,
        this.point && [this.point.id],
        this.startDate,
        this.endDate
      );

      const { pagesCount, reports } = factsResponse;
      reports.forEach((fact) => {
        this.addFact(fact);
      });

      runInAction(() => {
        this.pages = pagesCount;
        this.pageIsLoading = false;
      });
      return this.pages;
    }
  }

  @action
  setEmployee(employee) {
    if (this.employee && employee.id === this.employee.id) {
      this.employee = undefined;
    } else {
      this.employee = employee;
    }
  }

  @action
  setPoint(point) {
    if (this.point && point.id === this.point.id) {
      this.point = undefined;
    } else {
      this.point = point;
    }
  }

  @action
  setFact(id) {
    const fact = this.facts.get(id);
    if (fact) {
      fact.getReport();
    }
    this.fact = fact;
  }

  @computed
  get hasFacts() {
    return this.facts.size && !this.hasPendingFacts;
  }

  @computed
  get hasPendingFacts() {
    let pending = false;
    this.facts.forEach((fact) => {
      if (fact.isPending) {
        pending = true;
      }
    });
    return pending;
  }

  @computed
  get canRequest() {
    return !!this.startDate && !!this.endDate && !!this.journal;
  }

  @computed
  get visibleJournal() {
    let fact;
    if (this.facts.size) {
      fact = Array.from(this.facts.values())[0];
    }
    return fact && fact.journal;
  }

  @computed
  get factArray() {
    return Array.from(this.facts.values());
  }

  @computed
  get selectedEmployeeIds() {
    return this.employee ? [this.employee.id] : [];
  }

  @computed
  get selectedPointIds() {
    return this.point ? [this.point.id] : [];
  }

  @computed
  get journalsArray() {
    return Array.from(this.journals.values());
  }

  @computed
  get isPending() {
    return this.isPendingData || this.pendingPoints.size > 0;
  }

  @computed
  get isPendingForm() {
    return this.isPendingData;
  }
}
