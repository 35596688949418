import { observable, action } from "mobx";
import Directory from "../models/Directory";
import Revisions from "../models/Revisions";

/**
 * User Store.
 */
export default class DirStore {
  @observable dirs = new Map();

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async get(id, revision) {
    let dir = this.dirs.get(id);
    if (!dir) {
      dir = new Revisions(this);
      this.dirs.set(id, dir);
    }
    const rev = dir.getRevision(revision);
    if (rev) {
      return rev;
    } else {
      return await this.fetchDir(id, revision);
    }
  }

  @action
  async fetchDir(id, revision) {
    const dir = this.fabric({ id, revision, pending: true }, revision);
    const dirData = await this.api.getDir(id, revision);
    dir.fillData(dirData, revision);
    return dir;
  }

  @action
  fabric(dirData, revision) {
    const dir = this.dirs.get(dirData.id);
    const newDir = new Directory(dirData, this);
    dir.addRevision(newDir, revision);
    return newDir;
  }
}
