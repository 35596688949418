import { action, observable, computed, toJS } from "mobx";

import JournalRecord from "./JournalRecord";
import { parseDateTime } from "~/common/datetime";

export default class JournalEvent {
  @observable isPendingData = false;
  @observable answers = new Map();
  @observable records = new Map();

  id;
  datetime;

  constructor(data, report) {
    this.report = report;
    this.journal = this.report.journal;
    this.store = this.report.store;

    const { id, answers, records, title } = data;
    this.id = id;
    this.name = title;
    this.datetime = parseDateTime(data.datetime);
    this.processAnswers(answers);
    this.processRecords(records);
  }

  @action
  processAnswers(answers) {
    answers.forEach((answer) => {
      this.answers.set(`${answer.parameterId}`, answer);
    });
  }

  @action
  processRecords(records) {
    records.forEach((item) => {
      const record = new JournalRecord(item, this);
      this.records.set(`${record.id}`, record);
    });
  }

  @computed
  get fields() {
    const fields = [];
    const page = this.journal.eventFieldset;
    if (page) {
      page.paramsArray.forEach((param) => {
        const answer = this.answers.get(`${param.id}`);
        fields.push({
          param,
          value: answer,
        });
      });
    }
    return fields;
  }

  @computed
  get recordsArray() {
    return Array.from(this.records.values());
  }

  @computed
  get data() {
    return toJS(this.answers);
  }

  @computed
  get canExpand() {
    return this.records.size;
  }

  @computed
  get title() {
    return this.name;
  }
}
