import React, { useRef } from "react";
import { observer } from "mobx-react";
import { IoMdCheckmark, IoMdClose, IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

import FieldSet from "./FieldSet";
import Tab from "./Tab";
import { Button } from "~/common/components";
/**
 * Form.
 */

const Form = observer(({ form }) => {
  if (!form) return null;
  const { 
    currentFieldSet, 
    fieldSetsArray, 
    isValid,
    isChanged, 
    mainFieldSet,
    isPending,
  } = form;
  const tabs = fieldSetsArray.map((tab) => {
    return (
      <Tab key={tab.id} data={tab} />
    );
  });
  let main = null;
  if (mainFieldSet) {
    main = (<FieldSet key={mainFieldSet.id} fieldSet={mainFieldSet} />);
  }
  let fieldSets = null;
  if (currentFieldSet) {
    fieldSets = (<FieldSet key={currentFieldSet.id} fieldSet={currentFieldSet} />);
  }
  const buttons = [
    (
      <Button
        key="ok"
        className={"button inline success"}
        isLoading={isPending}
        disabled={!isValid || !isChanged}
        onClick={form.onSubmit}
        leftIcon={<IoMdCheckmark />}
        text="Save"
      />
    ),
    (
      <Button
        key="no"
        className={"button inline error"}
        onClick={form.onCancel}
        isLoading={isPending}
        disabled={!isChanged}
        leftIcon={<IoMdClose />}
        text="Cancel"
      />
    ),
  ];
  const scrollRef = useRef(null);
  const onScroll = (multiplyer = 1) => {
    const el = scrollRef && scrollRef.current;
    if (el) {
      el.scrollLeft += multiplyer * (0.75 * el.offsetWidth);
    }
  };
  const onScrollLeft = () => {
    onScroll(-1);
  };
  const onScrollRight = () => {
    onScroll(1);
  };

  return (
    <div className={`form-holder${main ? " has-main" : ""}`}>
      <div className="form-tabs-holder">
        <div className="scroll left" onClick={onScrollLeft}>
          <IoMdArrowBack />
        </div>
        <div className="scroll-container" ref={scrollRef}>
          {tabs}
        </div>
        <div className="scroll right" onClick={onScrollRight}>
          <IoMdArrowForward />
        </div>
      </div>
      <div className={`data-holder${!isValid && isChanged ? " invalid" : ""}`}>
        { main && (
          <div className="main-holder">{main}</div>
        )}
        {fieldSets}
      </div>
      <div className="buttons-holder">{buttons}</div>
    </div>
  );
});

export default Form;
