import { observable, action, runInAction, computed, toJS } from "mobx";
import MonitoringFactItem from "./MonitoringFactItem";

/**
 * Fact Model.
 */
export default class MonitoringFact {
  id;
  monId;

  @observable directory;
  @observable monitoring;
  @observable values = new Map();

  @observable hasDir = false;
  @observable hasMon = false;

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = data.id;
    this.monId = data.monitoringId;
    this.setValues(data.values);
    this.getDir(data.directory.id, data.directory.revision);
    this.getMon(this.monId);
  }

  @action
  async getDir(id, rev) {
    const dir = await this.store.rootStore.dirStore.get(id, rev);

    runInAction(() => {
      this.directory = dir;
      this.hasDir = true;
    });
  }

  @action
  async getMon(id) {
    const mon = await this.store.rootStore.monStore.get(id);
    runInAction(() => {
      this.monitoring = mon;
      this.hasMon = true;
    });
  }

  @action
  setValues(data) {
    data.forEach((value) => {
      const item = this.values.get(value.reference);
      if (item) {
        item.addValue(value);
      } else {
        const val = new MonitoringFactItem(value.reference, this.store);
        val.addValue(value);
        this.values.set(value.reference, val);
      }
    });
  }

  @action
  getValue(id) {
    return toJS(this.values.get(id));
  }

  @computed
  get isPending() {
    return !(this.hasMon && this.hasDir);
  }
}
