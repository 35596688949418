import React from "react";

import { observer, inject } from "mobx-react";
import Form from "~/common/components/Form";

@inject("userStore", "profilesStore")
@observer
class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { profilesStore } = this.props;
    const { hasPendingProfiles, chosenProfile } = profilesStore;

    if (hasPendingProfiles) {
      return (
        <div className="points-preloader">
          <div />
        </div>
      );
    }


    const content = (
      <div className="profile">
        {chosenProfile && <Form form={chosenProfile.form} />}
        {!chosenProfile && <div className="empty-plan">
          Choose employee and profile type to view data.
        </div>}
      </div>
    );
    return content;
  }
}

export default Profile;
