import React from "react";
import { DragSource } from "react-dnd";

const itemSource = {
  beginDrag(props) {
    return {
      item: props.item,
      index: props.index,
    };
  },
  canDrag(props, monitor) {
    return props.item.code !== undefined;
  },
};

const collect = function (connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const dragType = "POINT_ITEM";

class PointIerarchyItem extends React.Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  async onSelect(e) {
    e.stopPropagation();
    const { item, onSelect } = this.props;
    if (item.children === undefined) {
      onSelect(item);
    }
  }

  render() {
    const { item, selectedIds, isDragging, connectDragSource } = this.props;
    const { name, code } = item;
    const isSelected =
      selectedIds &&
      selectedIds.find((id) => {
        return id === item.id;
      }) !== undefined;
    return connectDragSource(
      <div
        key={code}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={`tree-item box content ${code ? "is-point" : ""} ${
          isSelected ? "selected" : ""
        }`}
        onClick={this.onSelect}
      >
        <div className="point-name">{name}</div>
        {code && <div className="point-code">{code}</div>}
      </div>
    );
  }
}

export default DragSource(dragType, itemSource, collect)(PointIerarchyItem);
