import React from "react";
import { observer, inject } from "mobx-react";
import { IoMdEye } from "react-icons/io";

import "react-tabs/style/react-tabs.css";

import ParamsCheckboxTable from "./ParamsCheckboxTable";
import { Button } from "~/common/components";
import FromTo from "~/common/components/FromTo";
import EmployeeAutocompleteSelect from "~/modules/employees/components/EmployeeAutocompleteSelect";
import PointsTree from "~/modules/points/components/PointsTree";

@inject("userStore", "employeesStore", "pointsStore", "auditStore")
@observer
class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.onGetData = this.onGetData.bind(this);
    this.onSetDates = this.onSetDates.bind(this);
    this.onSelectPoint = this.onSelectPoint.bind(this);

    this.state = {
      focusedInput:     undefined,
      isLoadingFile:    false,
      loadingFileError: false,
    };
  }

  onGetData() {
    const { galleryStore } = this.props;
    galleryStore.fetchData();
  }

  onSetDates({ startDate, endDate }) {
    const { galleryStore } = this.props;
    galleryStore.setDates({ startDate, endDate });
  }

  onSelectPoint(point) {
    const { galleryStore } = this.props;
    galleryStore.setPoint(point);
  }

  /**
   * Handle Employee selection.
   *
   * @param {object} selectedOption
   */
  onEmployeeChange(employee) {
    this.props.galleryStore.changeEmployee(employee);
  }

  render() {
    const { galleryStore, employeesStore } = this.props;
    const { canRequest, startDate, endDate, selectedPointIds } = galleryStore;

    return (
      <div className="request-form">
        <div className="buttons">
          <Button
            className={"button inline success"}
            disabled={!canRequest}
            onClick={this.onGetData}
            leftIcon={<IoMdEye />}
            text="View data"
          />
        </div>
        <FromTo
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onSetDates}
        />
        <EmployeeAutocompleteSelect
          value={galleryStore.employee}
          options={employeesStore.employeesArray}
          onChange={this.onEmployeeChange}
        />
        <PointsTree
          onSelect={this.onSelectPoint}
          selectedIds={selectedPointIds}
        />
        <div className="params-table-holder">
          <ParamsCheckboxTable galleryStore={galleryStore} />
        </div>
      </div>
    );
  }
}

export default RequestForm;
