import React from "react";
import Select from "react-select";
import { observer, inject } from "mobx-react";
/**
 * Profile Select Box
 */
@inject("profilesStore")
@observer
class ProfileTypeSelect extends React.Component {
  render() {
    const { value, onChange, options } = this.props;
    return (
      <div className="profile-holder">
        <div className="profile-select-holder">
          <Select
            className="select-user"
            name="profile"
            placeholder="Select profile type..."
            value={value}
            onChange={onChange}
            options={options}
          />
        </div>
      </div>
    );
  }
}

export default ProfileTypeSelect;
