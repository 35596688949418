import React from "react";
import PropTypes from "prop-types";
import SplitPane from "react-split-pane";

import { observer, inject } from "mobx-react";

import RequestForm from "./RequestForm";
import DataModule from "./DataModule";

@inject(
  "userStore",
  "employeesStore",
  "pointsStore",
  "auditStore",
  "dataViewerStore"
)
@observer
class DataViewer extends React.Component {
  static get propTypes() {
    return {
      dataViewerStore: PropTypes.any,
    };
  }
  render() {
    const { dataViewerStore } = this.props;
    const { isPending, isPendingForm } = dataViewerStore;
    return (
      <div className="data-viewer">
        <SplitPane split="vertical" defaultSize={"20em"} primary="first">
          {isPendingForm && <RequestForm />}
          {isPending && ( // TODO: нужно бы унести в Loading компонент
            <div className="points-preloader">
              <div />
            </div>
          )}
          {!isPending && <DataModule />}
        </SplitPane>
      </div>
    );
  }
}

export default DataViewer;
