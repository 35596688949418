import { observable, action, computed } from "mobx";

/**
 * Plan Store.
 */
export default class PlanStore {
  @observable plans = new Map();

  constructor(root) {
    this.root = root;
  }

  @action
  addPlan(plan) {
    let realPlan;
    if (plan) {
      this.plans.set(plan.id, plan);
      realPlan = this.plans.get(plan.id);
    }
    return realPlan;
  }

  getPlan(id) {
    return this.plans.get(id);
  }

  @action
  removePlan(plan) {
    this.plans.delete(plan.id);
  }

  @action
  clearPlans() {
    this.plans.clear();
  }

  @computed
  get hasPlans() {
    return this.plans.size > 0;
  }
}
