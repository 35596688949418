/**
 * Утилитарные функции работы с датами.
 */

import moment from "moment";

const DATE_TIME = "YYYY-MM-DDTHH:mm:ss:000Z";
const DATE_FORMAT_RU = "DD.MM.YYYY";
const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm:ss";

/**
 * Parse time string HH-mm-ss to Moment
 *
 * @param {string} str
 */
const parseTime = (str) => {
  return moment(str, TIME_FORMAT);
};

/**
 * Parse date string YYYY-MM-DD to Moment
 *
 * @param {string} str
 */
const parseDate = (str) => {
  return moment(str, DATE_FORMAT);
};

const parseDateTime = (str) => {
  return moment(str, DATE_TIME);
};

const parseDateTimeZ = (str) => {
  return moment(`${str} +0000`, `${DATE_TIME} Z`);
};
/**
 * Dump moment.Moment to YYYY-MM-DD string.
 *
 * @param {moment.Moment} date
 */
const stringifyDate = (date) => {
  return date.format(DATE_FORMAT);
};

const stringifyDateRU = (date) => {
  return date.format(DATE_FORMAT_RU);
};

/**
 * Dump moment.Moment to YYYY-MM-DD string.
 *
 * @param {moment.Moment} date
 */
const stringifyTime = (time) => {
  return time.format(TIME_FORMAT);
};

export {
  parseTime,
  parseDate,
  parseDateTimeZ,
  parseDateTime,
  stringifyDate,
  stringifyTime,
  stringifyDateRU,
};
