import React from "react";

import { observer, inject } from "mobx-react";

import {
  IoMdSave,
  IoMdPerson,
  IoMdCheckmarkCircleOutline,
  IoMdRadioButtonOff,
  IoMdCheckmark,
  IoMdClose,
} from "react-icons/io";

import { Button } from "~/common/components";
import EmployeeTree from "../employees/components/EmployeeTree";
import ProfileTypeSelect from "./components/ProfileTypeSelect";
import Profile from "./Profile";

@inject("userStore", "profilesStore")
@observer
class Profiles extends React.Component {
  constructor(props) {
    super(props);

    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.onSelectProfile = this.onSelectProfile.bind(this);
    this.onShowProfile = this.onShowProfile.bind(this);
    this.onToggleProfileFilter = this.onToggleProfileFilter.bind(this);

    this.state = {
      filterByProfile: false,
      modalIsVisible:  false,
    };
  }

  onEmployeeChange(employee) {
    const { profilesStore } = this.props;
    profilesStore.setEmployee(employee);
  }

  onSelectProfile(profile) {
    const { profilesStore } = this.props;
    profilesStore.setProfileType(profile);
  }

  onShowProfile() {
    const { profilesStore } = this.props;
    profilesStore.showProfile();
  }
  onToggleProfileFilter() {
    this.setState({
      filterByProfile: !this.state.filterByProfile,
    });
  }

  onToggleModal() {
    this.setState({
      modalIsVisible: !this.state.modalIsVisible,
    });
  }
  onConfirmModal() {
    this.onToggleModal();
    // const { profilesStore } = this.props;
    // profilesStore.deleteProfile(employeeId, typeId);
  }

  render() {
    const { profilesStore, userStore } = this.props;
    const { filterByProfile } = this.state;
    const { selectedType, typesArray, canShowProfile, hasPendingProfiles, isPendingEmployeeIds, profileEmployeeIds } = profilesStore;
    const perms = userStore.permissions;
    const content = (
      <div className="content">
        <div className="left">
          {perms.get("core.modules.profiles.can_download") && (
            <div className="buttons">            
              <Button
                className={"button onapproval"}
                leftIcon={<IoMdSave />}
                disabled={true}
                text="Download"
              />
            </div>
          )}
          <div className="buttons" key="setAudit">
            <Button
              className={"button success set-audit"}
              leftIcon={<IoMdPerson />}
              disabled={!canShowProfile}
              isLoading={hasPendingProfiles}
              text="Show profile"
              onClick={this.onShowProfile}
            />
          </div>
          <ProfileTypeSelect
            value={selectedType}
            options={typesArray}
            onChange={this.onSelectProfile}
          />
          <div className="buttons">
            <Button
              className={"button inline"}
              disabled={!selectedType}
              isLoading={isPendingEmployeeIds}
              onClick={this.onToggleProfileFilter}
              leftIcon={filterByProfile ? <IoMdCheckmarkCircleOutline /> : <IoMdRadioButtonOff />}
              text="Filter by profile"
            />
          </div>
          <EmployeeTree
            filterIds={filterByProfile ? profileEmployeeIds : []}
            selectedIds={profilesStore.employeeIds}
            onSelect={this.onEmployeeChange}
          />
        </div>
        <div className="right">
          <Profile />
        </div>
        {this.state.modalIsVisible && (
          <div className="overlay">
            <div className="modal-user-picker">
              <div className="modal-header">
                Вы уверены что хотите удалить профиль?
                <div className="close" onClick={this.onToggleModal}>
                  <IoMdClose />
                </div>
              </div>
              <Button
                className={"button inline"}
                onClick={this.onConfirmModal}
                leftIcon={<IoMdCheckmark />}
                text="Confirm"
              />
            </div>
          </div>
        )}
      </div>
    );
    return content;
  }
}

export default Profiles;
