import { observable, action, computed } from "mobx";
import Field from "./Field";

/**
 * FieldSet Model.
 */
export default class FieldSet {
  id;
  name;
  @observable fields = new Map();

  @observable form;
  @observable store;

  constructor(page, form, store) {
    this.store = store;
    this.form = form;

    this.id = `${page.id}`;
    this.name = page.name;
    this.initFields(page.parameters);
  }

  @action
  setCurrent() {
    this.form.setCurrentId(this.id);
  }

  @action
  setValues(values = {}) {
    this.fields.forEach((field) => {
      const val = values[field.id];
      field.setValue(val);
    });
  }

  @action
  onCancel() {
    this.fields.forEach((field) => {
      field.onCancel();
    });
  }

  @action
  save() {
    this.fields.forEach((fs) => {
      fs.save();
    });
  }

  @action
  initFields(paramsData) {
    if (paramsData) {
      this.fields = new Map();
      paramsData.forEach((paramData) => {
        if (paramData) {
          const param = new Field(paramData, this.store);
          this.fields.set(param.id, param);
        }
      });
    }
  }

  @computed
  get data() {
    const data = {};
    this.fields.forEach((field) => {
      data[field.id] = field.output;
    });
    return data;
  }

  @computed
  get fieldsArray() {
    const array = [];
    // бывают fieldset без параметров
    if (this.fields) {
      this.fields.forEach((field) => {
        array.push(field);
      });
    }
    return array;
  }

  @computed
  get isCurrent() {
    return (this.form.currentFieldSetId === this.id);
  }

  @computed
  get isChanged() {
    let changed = false;
    this.fields.forEach((field) => {
      changed = changed || field.isChanged;
    });
    return changed;
  }

  @computed
  get isValid() {
    let valid = true;
    this.fields.forEach((field) => {
      valid = valid && field.isValid;
    });
    return valid;
  }
}
