import { observable } from "mobx";

import Coordinates from "~/common/models/Coordinates";

/**
 * Point data model.
 */
export default class Point {
  id;
  code;
  name;
  coords;
  store;
  data;
  isAssigned;
  @observable attributes = new Map();

  constructor(
    { id, code, name, label, coords, isAssigned, attributes },
    store
  ) {
    this.store = store;

    this.id = id;
    this.code = code;
    this.name = name || label;
    this.coords = new Coordinates(coords);
    this.isAssigned = isAssigned;

    if (attributes) {
      this.attributes.replace(attributes);
    }
  }

  setData(data) {
    this.data = data;
  }
}
