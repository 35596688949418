import { observable, action, computed, runInAction } from "mobx";

/**
 * User Store.
 */
// TODO: это не стор, это модель. А Должен быть стор, использующий модель User
export default class UserStore {
  @observable email;
  @observable uid;
  @observable token;
  @observable permissions = new Map();

  constructor(root) {
    this.root = root;
  }

  /**
   * Successful login flag.
   */
  @computed get isLoggedIn() {
    return this.token && this.uid;
  }

  /**
   * Logins user.
   *
   * @param {string} email
   * @param {string} password
   * @returns null
   */
  @action
  async login(email, password) {
    const result = await this.root.api.login(email, password);

    runInAction(() => {
      this.email = email;
      this.uid = result.userId;
      this.token = result.token;
      if (result.permissions) {
        result.permissions.forEach((permission) => {
          this.permissions.set(permission, true);
        });
      }
    });

    // TODO: К логину это не имеет прямого отношения, это должно быть не здесь
    // а в реакции на успешный логин
    await Promise.all([this.root.employees.init(), this.root.points.init()]);
    this.root.dataViewerStore.init();
    this.root.monitoringViewerStore.init();
    this.root.planningStore.init();
    this.root.auditStore.init();
    this.root.journalsStore.init();
    this.root.profilesStore.init();
    this.root.actionPlanStore.init();
  }

  /**
   * Проверяет наличие разрешения у пользователя.
   *
   * @param {string} permission разрешение для проверки
   * @returns {boolean} есть ли у пользователя запрашиваемое разрешение
   */
  hasPermission(...perms) {
    return perms
      .map((perm) => {
        return !!this.permissions.get(perm);
      })
      .every((p) => {
        return p === true;
      });
  }

  @action
  logout() {
    this.email = undefined;
    this.uid = undefined;
    this.token = undefined;
    this.permissions.clear();
  }
}
