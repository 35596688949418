import React from "react";

import { IoIosCheckmarkCircleOutline } from "react-icons/io";

import { observer, inject } from "mobx-react";
import Table from "~/common/components/Table";

@inject("userStore", "employeesStore", "pointsStore", "auditStore")
@observer
class MonsInfo extends React.Component {
  columns = [
    {
      Header: "ID",
      accessor: "id", // String-based value accessors!
      show: true,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Info",
      accessor: "info",
      show: false,
      Aggregated: (row) => {
        return null;
      },
    },
    {
      Header: "ID",
      id: "paramId",
      show: false,
      accessor: (d) => {
        return d.param.id;
      },
    },
    {
      Header: "Parameter",
      id: "paramName",
      accessor: (d) => {
        return d.param.name;
      },
      Aggregated: (row) => {
        return null;
      },
    },
    {
      Header: "Value",
      accessor: "value",
      id: "value",
      Aggregated: (row) => {
        return null;
      },
    },
    {
      Header: "Audit Value",
      id: "auditVal",
      accessor: "auditValue",
      Cell: this.props.renderCell,
      aggregate: (vals) => {
        let hasAudit = undefined;
        vals.forEach((val) => {
          if (val && val.value !== undefined) {
            hasAudit = true;
          }
        });
        return { value: hasAudit };
      },
      Aggregated: (row) => {
        return row.value.value ? null : (
          <div className="audit-correct">
            <IoIosCheckmarkCircleOutline />
          </div>
        );
      },
    },
  ];

  filter(filter, row) {
    const exp = new RegExp(filter.value, "i");
    const contains = exp.test(String(row[filter.id]));
    return contains;
  }

  render() {
    return (
      <div className="quest-info-holder">
        <Table
          defaultFilterMethod={this.filter}
          data={this.props.data}
          columns={this.columns}
        />
      </div>
    );
  }
}

export default MonsInfo;
