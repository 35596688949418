import React from "react";
import { observer, inject } from "mobx-react";

@inject("employeesStore")
@observer
class EmployeeItem extends React.Component {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  async onSelect(e) {
    e.stopPropagation();
    const { item, onSelect, employeesStore } = this.props;
    if (item.children === undefined) {
      const employee = await employeesStore.get(item.id);
      onSelect && onSelect(employee || item);
    }
  }

  render() {
    const { item, selectedIds } = this.props;
    const { name, firstName, lastName } = item;
    const isSelected =
      selectedIds &&
      selectedIds.find((id) => {
        return id === item.id;
      }) !== undefined;

    return (
      <div
        onClick={this.onSelect}
        className={`user-name ${
          item.children === undefined ? "selectable" : ""
        } ${isSelected ? "selected" : ""}`}
      >
        {name} {lastName} {firstName}
      </div>
    );
  }
}

export default EmployeeItem;
