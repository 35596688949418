import React, { useRef } from "react";
import { observer } from "mobx-react";

/**
 * Form.
 */

const Tab = observer(({ data }) => {
  const { name, isCurrent, isValid } = data;
  const tabRef = useRef(null);
  const onClick = () => {
    tabRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    data.setCurrent();
  };
  return (
    <div 
      ref={tabRef} 
      onClick={onClick} 
      className={`tab${isCurrent ? " current" : ""}${!isValid ? " invalid" : ""}`}
    >
      {name}
    </div>
  );
});

export default Tab;
