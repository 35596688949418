import React from "react";
import Select from "react-select";

/**
 * Employees Select Box
 */
const MonitoringAutocompleteSelect = ({ value, onChange, options }) => {
  return (
    <Select
      className="select-quest"
      classNamePrefix="manager-select"
      name="quest"
      placeholder="Select monitoring..."
      value={value}
      valueKey={"id"}
      labelKey={"name"}
      onChange={onChange}
      options={options}
    />
  );
};

export default MonitoringAutocompleteSelect;
