import React from "react";
import { inject, observer } from "mobx-react";

import { IoMdPerson, IoMdLock } from "react-icons/io";

import { Button, Loading } from "~/common/components";
import Workspace from "~/workspace/Workspace";

@inject("rootStore", "userStore")
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: "",
      password: "",
      isLoading: false,
      error: null
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const fontSize =
      parseInt(localStorage.getItem("URT.Manager.settings.fontSize"), 10) ||
      Workspace.DEFAULT_FONTSIZE;
    document.body.style.fontSize = `${fontSize}px`;
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    switch (event.keyCode) {
      case 13:
        this.handleLogin();
        break;
      default:
        break;
    }
  }

  async handleLogin() {
    this.setState({ isLoading: true, error: null });
    try {
      await this.props.userStore.login(this.state.login, this.state.password);
    } catch (error) {
      this.setState({ isLoading: false, error: "Login failed" });
    }
  }

  render() {
    const { isLoading, error } = this.state;
    return (
      <div className="main">
        {isLoading && <Loading />}
        <div className="content">
          <div className="content auth">
            <div className="login">
              <p>Please log in to use the URT.Manager</p>
              <div className="inputHolder">
                <div className="iconHolder">
                  <IoMdPerson />
                </div>
                <input
                  type="text"
                  className="login"
                  value={this.state.login}
                  onChange={e => {
                    return this.setState({ login: e.target.value });
                  }}
                />
              </div>
              <div className="inputHolder">
                <div className="iconHolder">
                  <IoMdLock />
                </div>
                <input
                  type="password"
                  className="password"
                  value={this.state.password}
                  onChange={e => {
                    return this.setState({ password: e.target.value });
                  }}
                />
              </div>
              <Button
                className={"button inline"}
                onClick={this.handleLogin}
                leftIcon={<IoMdPerson />}
                text="Login"
              />
              {error && <p className="error">Error: {error}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
