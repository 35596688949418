import { observable, action, computed, runInAction, toJS } from "mobx";

import { parseDateTimeZ } from "~/common/datetime";

import moment from "moment";

import Plan from "~/common/models/Plan";
// import MapPoint from "../models/MapPoint";
import MapUser from "~/modules/map/data/models/MapUser";
import Fact from "~/common/models/Fact";
/**
 * Map Store.
 */
const startBounds = [
  [180, 180],
  [-180, -180],
];

export default class MapStore {
  @observable daysPending = false;
  @observable currentUser;
  @observable currentMapPoint;
  @observable blockedDays = new Map();

  @observable employee;
  @observable.struct period = { begin: null, end: null }; // период планирования

  @observable users = new Map();
  @observable currentBounds = [
    [180, 180],
    [-180, -180],
  ];

  static makeBounds(oldBounds, lat, lng) {
    const bounds = [
      [oldBounds[0][0], oldBounds[0][1]],
      [oldBounds[1][0], oldBounds[1][1]],
    ];
    if (lat < bounds[0][0]) {
      bounds[0][0] = lat;
    }
    if (lat > bounds[1][0]) {
      bounds[1][0] = lat;
    }
    if (lng < bounds[0][1]) {
      bounds[0][1] = lng;
    }
    if (lng > bounds[1][1]) {
      bounds[1][1] = lng;
    }
    return bounds;
  }

  constructor(root) {
    this.root = root;
    this.api = root.api;

    // test
  }
  @action
  addUserInterval() {
    const trueBegin = moment(this.period.begin).utc(false).startOf("day");
    // .hours(4)
    // .minutes(0)
    // .second(0);
    const trueEnd = moment(this.period.end).utc(false).endOf("day");
    // .hours(23)
    // .minutes(59)
    // .second(59);

    this.getDataForUserWithInterval(this.employee, trueBegin, trueEnd);
    this.period = { begin: null, end: null };
    this.employee = undefined;
  }

  @action
  async getDataForUserWithInterval(user, from, to) {
    try {
      const storedUser = this.users.get(user.id);
      if (storedUser) {
        const dataForInterval = storedUser.hasDataForInterval(from, to);
        if (!dataForInterval) {
          await this.performRequest(storedUser, from, to);
        }
      } else {
        const newUser = new MapUser(user, {}, this);

        await this.performRequest(newUser, from, to);

        runInAction(() => {
          this.users.set(user.id, newUser);
        });
      }
    } catch (error) {
      runInAction(() => {
        console.warn(error);

        // this.setMapError(error.message ? error.message : error);
      });
    }
  }

  async performRequest(mapUser, from, to) {
    const uid = mapUser.user.id;
    const requestData = [
      this.fetchUserPlans(uid, from, to),
      this.fetchUserFacts(uid, from, to),
    ];

    const fetchedData = await Promise.all(requestData);

    const [planData, factData] = fetchedData;
    const userData = [this.getPlans(planData), this.getFacts(factData)];

    const processedData = await Promise.all(userData);

    const [userPlans, userFacts] = processedData;

    mapUser.setPlansForInterval(from, to, userPlans);
    mapUser.setFactsForInterval(from, to, userFacts);
  }

  @action
  async getPlans(data) {
    const planPoints = data.map((plan) => {
      const point = this.root.points.get(plan.pointId);
      if (!point) {
        return this.root.points.fetchPoint(plan.pointId);
      } else {
        return point;
      }
    });
    const realPoints = await Promise.all(planPoints);

    const userPlans = data.map((plan, i) => {
      const newPlanItem = new Plan(
        plan.id,
        plan.assigned,
        realPoints[i],
        plan.start.datetime,
        plan.finish.datetime,
        plan.status,
        plan.date,
        this
      );
      const planItem = this.root.planStore.addPlan(newPlanItem);

      return planItem;
    });
    return userPlans;
  }

  @action
  async getFacts(data) {
    const factPoints = data.map((fact) => {
      const point = this.root.points.get(fact.pointId);
      if (!point) {
        return this.root.points.fetchPoint(fact.pointId);
      } else {
        return point;
      }
    });
    const realPoints = await Promise.all(factPoints);
    const userFacts = data.map((fact, i) => {
      let plan;
      if (fact.planId) {
        plan = this.root.planStore.getPlan(fact.planId);
      }
      const newFactItem = new Fact(
        fact.id,
        plan,
        realPoints[i],
        fact.start,
        fact.finish,
        this
      );

      return newFactItem;
    });
    return userFacts;
  }

  @action
  async fetchEmployeeEvents(uid, from, to) {
    return await this.api.getMapEvents(uid, from, to);
  }

  @action
  async getBlockedDays(date, notPeding) {
    if (this.employee) {
      this.daysPending = !notPeding;
      const uid = this.employee.id;
      const from = moment(date).utc(false).startOf("year");
      const to = moment(date).utc(false).endOf("year");
      const isBlockedData = await this.fetchEmployeeEvents(uid, from, to);
      runInAction(() => {
        Object.keys(isBlockedData).forEach((key) => {
          this.blockedDays.set(key, !isBlockedData[key]);
        });
        this.daysPending = false;
      });
    }
  }

  @action
  async fetchUserPlans(uid, from, to) {
    return await this.api.getPlans(uid, from, to, "approved");
  }

  @action
  async fetchUserFacts(uid, from, to) {
    return await this.api.getFacts(uid, from, to);
  }

  @action
  setCurrentInterval(user, interval) {
    this.users.forEach((user) => {
      user.setInterval();
    });
    const mapUser = this.users.get(user.id);
    mapUser.setInterval(interval);
  }

  @action
  async changeEmployee(employee) {
    this.employee = employee;
    this.period = { begin: null, end: null };
    this.blockedDays = new Map();
    await this.getBlockedDays(moment());
  }

  @action
  changePeriod(begin, end) {
    this.period = { begin, end };
  }

  @computed
  get bounds() {
    return toJS(this.currentBounds);
  }

  @computed
  get totalBounds() {
    return null;
  }

  @computed
  get buttonsCount() {
    let count = 0;
    this.users.forEach((user) => {
      count += user.intervals.size;
    });
    return count;
  }

  @computed
  get mapData() {
    const mapData = [];
    let mapBounds = startBounds;
    this.users.forEach((user) => {
      const intervals = user.intervals.map((userInterval) => {
        const isCurrent = user.currentInterval === userInterval;
        let intervalBounds = startBounds;
        const interval = {
          interval: {
            string: userInterval,
            from: parseDateTimeZ(userInterval.split("Z-")[0]),
            to: parseDateTimeZ(userInterval.split("Z-")[1]),
            isCurrent,
          },
          plans: [],
          planCoordinates: [],
          facts: [],
          factCoordinates: [],
        };

        const userPlans = user.plans.get(userInterval);
        if (userPlans) {
          userPlans.slice().forEach((plan) => {
            interval.plans.push(plan);
            if (plan.point && plan.point.coords.asArray) {
              interval.planCoordinates.push(plan.point.coords.asArray);
              mapBounds = MapStore.makeBounds(
                mapBounds,
                plan.point.coords.lat,
                plan.point.coords.lng
              );
              intervalBounds = MapStore.makeBounds(
                intervalBounds,
                plan.point.coords.lat,
                plan.point.coords.lng
              );
            }
          });
        }

        const userFacts = user.facts.get(userInterval);
        if (userFacts) {
          userFacts.slice().forEach((fact) => {
            interval.facts.push(fact);
            if (fact.finish.coords.asArray) {
              interval.factCoordinates.push(fact.finish.coords.asArray);
              mapBounds = MapStore.makeBounds(
                mapBounds,
                fact.finish.coords.lat,
                fact.finish.coords.lng
              );
              intervalBounds = MapStore.makeBounds(
                intervalBounds,
                fact.finish.coords.lat,
                fact.finish.coords.lng
              );
            }
          });
        }
        interval.bounds = intervalBounds;
        return interval;
      });

      mapData.push({
        user: user.user,
        intervals,
      });
    });
    return { data: mapData, bounds: mapBounds };
  }
}
