import { observable, computed } from "mobx";
import moment from "moment";

import Page from "~/common/models/Page";

export default class Journal {
  @observable isPendingFacts = false;

  @observable id;
  @observable name;
  @observable isArchived;
  @observable createdAt = moment();

  @observable fieldset;
  @observable eventFieldset;
  @observable recordFieldset;

  @observable recordTitleParameterId;
  @observable eventTitleParameterId;

  constructor(data, store) {
    this.store = store;

    this.id = data.id;
    this.name = data.name;
    this.isArchived = data.isArchived;
    this.createdAt = data.createdAt;

    if (data.fieldset) {
      this.fieldset = new Page(data.fieldset, this.store, this);
    }
    if (data.eventFieldset) {
      this.eventFieldset = new Page(data.eventFieldset, this.store, this);
    }
    if (data.recordFieldset) {
      this.recordFieldset = new Page(data.recordFieldset, this.store, this);
    }

    this.recordTitleParameterId = data.recordTitleParameterId;
    this.eventTitleParameterId = data.eventTitleParameterId;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get isPending() {
    return this.isPendingFacts;
  }
}
