import React, { useMemo } from "react";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import { IoMdArrowUp, IoMdArrowDown } from "react-icons/io";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, type, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type={type || "checkbox"} ref={resolvedRef} {...rest} />;
  }
);

const CheckboxTable = ({
  selectType,
  columns,
  loading,
  data,
  className,
  toggleSelection,
  PaginationComponent,
  toggleAll,
  hiddenColumns,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const defaultColumn = useMemo(() => {
    return {
      minWidth: 30,
      width:    150,
      maxWidth: 800,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: hiddenColumns || [],
      },
    },
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          // a column for selection
          {
            id:              "selection",
            width:           20,
            disableResizing: true,
            justify:         "center",
            // eslint-disable-next-line
            Header: ({ getToggleAllRowsSelectedProps }) => {
              if (selectType === "radio") {
                return null;
              }
              return (
                <IndeterminateCheckbox
                  type={selectType}
                  {...getToggleAllRowsSelectedProps({ onChange: toggleAll })}
                />
              );
            },
            // eslint-disable-next-line
            Cell: ({ row }) => {
              if (row.original.loading) {
                return (
                  <div className="points-preloader small">
                    <div />
                  </div>
                );
              }
              return (
                <IndeterminateCheckbox
                  type={selectType}
                  {...row.getToggleRowSelectedProps({
                    onChange: () => {
                      toggleSelection(row.original);
                    },
                  })}
                />
              );
            },
          },
          ...columns,
        ];
      });
    },
    useSortBy,
    useRowSelect,
    useResizeColumns,
    useFlexLayout
  );

  // Render the UI for your table
  return (
    <div className={"table-container"}>
      <div className={"table-holder"}>
        <div
          className={`table manager-table checkbox-table ${className}`}
          {...getTableProps()}
        >
          {!loading && (
            <div className={"thead"}>
              {headerGroups.map((headerGroup, i) => {
                return (
                  <div
                    key={i}
                    {...headerGroup.getHeaderGroupProps()}
                    className="tr"
                  >
                    {headerGroup.headers.map((column, j) => {
                      let sort = null;
                      if (column.isSorted) {
                        if (column.isSortedDesc) {
                          sort = (
                            <div className="base-header">
                              <IoMdArrowUp />
                            </div>
                          );
                        } else {
                          sort = (
                            <div className="base-header">
                              <IoMdArrowDown />
                            </div>
                          );
                        }
                      }
                      return (
                        <div
                          key={j}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={"th"}
                        >
                          {column.render("Header")}
                          {sort}
                          <div
                            {...(column.getResizerProps
                              ? column.getResizerProps()
                              : {})}
                            className={`resizer ${
                              column.isResizing ? "isResizing" : ""
                            }`}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
          {!loading && (
            <div className={"tbody"} {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div key={i} {...row.getRowProps()} className="tr">
                    {row.cells.map((cell, j) => {
                      return (
                        <div
                          key={j}
                          {...cell.getCellProps()}
                          className={`td align-${
                            cell.column.align || ""
                          }  justify-${cell.column.justify || ""} `}
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
          {loading && (
            <div className="points-preloader">
              <div />
            </div>
          )}
        </div>
        {PaginationComponent && <PaginationComponent />}
      </div>
    </div>
  );
};

export default CheckboxTable;
