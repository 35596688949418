import Coordinates from "~/common/models/Coordinates";
import { parseDateTime } from "~/common/datetime";
import { observable, action } from "mobx";

/**
 * Fact Model.
 */
export default class Fact {
  id;
  plan;
  point;
  start;
  finish;
  store;
  @observable auditIds = [];

  constructor(id, plan, point, start, finish, store, auditIds) {
    this.store = store;

    this.id = id;
    this.plan = plan;
    this.point = point;

    if (auditIds) {
      this.auditIds = auditIds;
    }

    this.start = {
      datetime: parseDateTime(start.datetime),
      coords: new Coordinates(start.coords),
    };
    this.finish = {
      datetime: parseDateTime(finish.datetime),
      coords: new Coordinates(finish.coords),
    };
  }

  @action
  addAudit(ids) {
    this.auditIds.merge(ids);
  }

  @action
  deleteAudit(id) {
    this.auditIds.remove(id);
  }
}
