import React from "react";
import { observable, action, runInAction, computed, toJS } from "mobx";
import moment from "moment";

import {
  IoMdInformationCircle,
  IoMdList,
  IoMdKeypad,
  IoMdCreate,
  IoMdSwitch,
  IoMdImage,
  IoMdDoneAll,
  IoMdRadioButtonOn,
  IoMdPerson,
  IoMdPin,
  IoMdClock,
  IoMdCalendar,
  IoMdBook,
  IoIosApps,
} from "react-icons/io";

import Quest from "~/common/models/Quest";
import Photo from "~/common/components/Photo";

export default class DataViewerStore {
  @observable isPendingData = true;
  @observable pendingPoints = new Map();

  @observable showTable = false;
  @observable pageIsLoading = false;
  @observable hasData = false;
  @observable quests = new Map();
  @observable point;
  @observable quest;
  @observable totlaItems = 1;

  @observable answers = [];

  @observable page = 1;
  @observable pageLength = 50;

  @observable params = new Map();
  @observable sortedArray = [];

  @observable startDate;
  @observable endDate;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.isPendingData = true;
    await this.getQuests();
    runInAction(() => {
      this.isPendingData = false;
    });
  }

  @action
  async getQuests() {
    const data = await this.api.getDataViewerQuests();

    runInAction(() => {
      data.forEach((questData) => {
        this.quests.set(questData.id, new Quest(questData, this));
      });
    });
  }

  @computed
  get questArray() {
    const quests = [];
    this.quests.forEach((quest) => {
      if (quest.allParams.length > 0) {
        quests.push(quest);
      }
    });
    return quests;
  }

  @computed
  get sorted() {
    return toJS(this.sortedArray);
  }

  @computed
  get paramsArray() {
    if (!this.quest) {
      return [];
    }
    return this.quest.allParams.map((param) => {
      return {
        ...param,
        isSelected: !!this.params.get(param.id),
      };
    });
  }

  @computed
  get selectedParamsArray() {
    return Array.from(this.params.keys());
  }

  @computed
  get canRequest() {
    return !!this.startDate && !!this.endDate && !!this.quest;
  }

  @computed
  get hasAnswers() {
    return this.answers.length > 0;
  }

  @computed
  get pages() {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  @computed
  get pageSize() {
    return Number(this.pageLength);
  }

  @computed
  get tableData() {
    return Array.from(this.answers);
  }

  @computed
  get columns() {
    const columns = [
      {
        Header: "ID",
        accessor: "id", // String-based value accessors!
        show: false,
      },
      {
        Header: <div className="meta-header">#</div>,
        id: "index",
        maxWidth: 50,
        Cell: ({ row }) => {
          return <div>{this.pageSize * (this.page - 1) + row.index + 1}</div>;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPin /> Point
          </div>
        ),
        id: "point",
        accessor: (d) => {
          return d.point.name;
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdPerson /> Author
          </div>
        ),
        id: "author",
        accessor: (d) => {
          return (d.author && d.author.fullName) || "Unknown author";
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Start
          </div>
        ),
        id: "start",
        accessor: (d) => {
          return d.start.format("DD.MM.YYYY HH:mm");
        },
      },
      {
        Header: (
          <div className="meta-header">
            <IoMdClock /> Finish
          </div>
        ),
        id: "finish",
        accessor: (d) => {
          return d.finish.format("DD.MM.YYYY HH:mm");
        },
      },
    ];

    this.params.forEach((param) => {
      let type = param.type;
      if (type === "list") {
        type = param.viewConfig;
      }
      switch (type) {
        case "linked":
        case "list_db":
          type = <IoMdInformationCircle />;
          break;
        case "directoryRecord":
          type = <IoIosApps />;
          break;
        case "number":
          type = <IoMdKeypad />;
          break;
        case "text":
          type = <IoMdCreate />;
          break;
        case "selectfield":
          type = <IoMdList />;
          break;
        case "multiselect":
          type = <IoMdDoneAll />;
          break;
        case "radiogroup":
          type = <IoMdRadioButtonOn />;
          break;
        case "file":
          type = <IoMdImage />;
          break;
        case "boolean":
          type = <IoMdSwitch />;
          break;
        case "date":
          type = <IoMdCalendar />;
          break;
        case "lib-material-link":
          type = <IoMdBook />;
          break;
        default:
          type = "";
          break;
      }

      columns.push({
        Header: (
          <div className="base-header">
            {type} {param.name}
          </div>
        ),
        id: `${param.id}`,
        accessor: (d) => {
          if (
            param.type === "file" &&
            d.data[param.id] &&
            d.data[param.id].value
          ) {
            return <Photo id={d.data[param.id].value} isThumb={true} />;
          }
          return d.data[param.id] && d.data[param.id].value;
        },
      });
    });
    return columns;
  }

  @action
  changePoint(point) {
    this.point = point;
  }

  @action
  onSortedChange(sorted) {
    this.sortedArray.replace(sorted);
  }

  @action
  changeQuest(quest) {
    this.params.clear();
    this.quest = quest;
  }
  @action
  showTheTable(show) {
    this.showTable = show;
  }

  @action
  setDates({ startDate, endDate }) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action
  setParam(paramId) {
    if (this.params.get(paramId)) {
      this.params.delete(paramId);
    } else {
      this.paramsArray.forEach((param) => {
        if (param.id === paramId) {
          this.params.set(param.id, param);
        }
      });
    }
  }

  @action
  setAllParams() {
    if (this.paramsArray.length === this.selectedParamsArray.length) {
      this.params.clear();
    } else {
      this.paramsArray.forEach((param) => {
        this.params.set(param.id, param);
      });
    }
  }

  @action
  async loadPage(pageLength, page = 1) {
    if (!this.pageIsLoading) {
      this.pageIsLoading = true;
      this.page = page;
      if (pageLength) {
        this.pageLength = pageLength;
      }
      await this.getData();
      runInAction(() => {
        this.pageIsLoading = false;
      });
      return this.pages;
    }
  }

  @action
  async getItem(record) {
    const answerData = {};
    record.answers.forEach((fieldData) => {
      answerData[fieldData.parameterId] = fieldData;
    });
    let author = { fullName: "Unknown user" };
    try {
      author = await this.rootStore.employees.get(record.authorId);
    } catch (error) {
      console.warn(error); //eslint-disable-line
    }
    runInAction(() => {
      this.pendingPoints.set(record.pointId, true);
    });
    const point = await this.rootStore.points.addPoint(record.pointId);
    runInAction(() => {
      this.pendingPoints.delete(record.pointId);
    });
    const answer = {
      author,
      start: moment(record.start),
      finish: moment(record.finish),
      id: record.id,
      point,
      data: answerData,
    };
    return answer;
  }

  @action
  async getData() {
    const data = await this.api.getDataViewerReports(
      this.quest.id,
      [], // this.selectedParamsArray,//параметры в запрос не отправляю чтобы если после запроса пользователь включит колонку данные были
      this.startDate.toISOString(),
      this.endDate.toISOString(),
      this.page,
      this.pageLength
    );
    runInAction(() => {
      this.totalItems = data.totalCount;
    });

    const syncItems = [];
    data.records.forEach((record) => {
      syncItems.push(this.getItem(record));
    });
    const items = await Promise.all(syncItems);

    runInAction(() => {
      this.answers.replace(items);
    });
    return true;
  }

  @computed
  get isPending() {
    return this.isPendingData || this.pendingPoints.size > 0;
  }

  @computed
  get isPendingForm() {
    return this.questArray.length > 0;
  }
}
