import React from "react";
import { observer, inject } from "mobx-react";

import FeedStore from "./data/stores/FeedStore";
import Photo from "~/common/components/Photo";
import Table from "~/common/components/Table";

@inject("rootStore")
@observer
class Feed extends React.Component {
  constructor(props) {
    super(props);

    this.store = new FeedStore(props.rootStore);
  }

  componentDidMount() {
    this.store.init();
  }

  componentWillUnmount() {
    this.store.dropTimeout();
  }

  renderImages({ row }) {
    const photos = (row.original && row.original._photos);
    const images = [];
    if (photos && photos.length) {
      photos.forEach((photo) => {
        images.push(
          <Photo
            key={photo.id}
            id={photo.id}
            name={photo.name}
            isThumb={true}
          />
        );
      });
      return <div className="feed-images-holder">{images}</div>;
    } else {
      return null;
    }
  }

  loader({ loading }) {
    return loading ? (
      <div className="points-preloader">
        <div />
      </div>
    ) : null;
  }

  render() {
    const { isPending, columns, tableData } = this.store;
    if (isPending) {
      return (
        <div className="points-preloader">
          <div />
        </div>
      );
    }

    return (
      <div className="feed-holder">
        <Table
          sortable={true}
          multiSort={true}
          loading={isPending}
          data={tableData}
          columns={columns}
          defaultPageSize={10}
          showPagination={true}
          showPageJump={false}
          className="-striped -highlight"
          style={{ width: "100%" }}
          pageSizeOptions={[10, 20, 50]}
          SubComponent={this.renderImages}
          LoadingComponent={this.loader}
        />
      </div>
    );
  }
}

export default Feed;
