import { observable, action, computed } from "mobx";
import { uuid } from "uuidv4";
import FieldSet from "./FieldSet";

/**
 * Form Model.
 */
export default class Form {
  @observable id = uuid();
  @observable fieldSets;
  @observable mainId;
  @observable currentId;
  @observable parent;
  @observable pending = false;
  store;

  constructor(id, fieldSets, store, mainId, parent) {
    this.store = store;
    this.parent = parent;
    this.mainId = mainId;
    if (id) {
      this.id = id;
    }
    this.initFieldSets(fieldSets);

    if (this.fieldSets.size < 2) {
      this.mainId = null;
      this.fieldSets.forEach((fs, key) => {
        this.currentId = key;
      });
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  @action
  setValues(values = {}) {
    this.fieldSets.forEach((fs) => {
      fs.setValues(values);
    });
  }

  @action
  setCurrentId(id) {
    this.currentId = id;
  }

  @action
  setPending(pending = false) {
    this.pending = pending;
  }

  @action
  async onSubmit() {
    if (this.parent) {
      this.setPending(true);
      await this.parent.formSubmit(this.data);
      this.setPending(false);
    }
  }

  @action
  onCancel() {
    this.fieldSets.forEach((fs) => {
      fs.onCancel();
    });
  }

  @action
  save() {
    this.fieldSets.forEach((fs) => {
      fs.save();
    });
  }

  @action
  initFieldSets(pages) {
    if (pages) {
      this.fieldSets = new Map();
      let isFirst = true;
      pages.forEach((page) => {
        if (page) {
          const fs = new FieldSet(page, this, this.store);
          this.fieldSets.set(fs.id, fs);
          if (isFirst && fs.id !== this.mainId) {
            this.currentId = fs.id;
            isFirst = false;
          }
        }
      });
    }
  }

  @computed
  get fieldSetsArray() {
    const array = [];
    // бывают Form без FieldSet
    if (this.fieldSets) {
      this.fieldSets.forEach((fieldSet) => {
        if (fieldSet.id !== this.mainId) {
          array.push(fieldSet);
        }
      });
    }
    return array;
  }

  @computed
  get currentFieldSet() {
    return this.fieldSets.get(this.currentFieldSetId);
  }

  @computed
  get mainFieldSet() {
    return this.fieldSets.get(this.mainId) || null;
  }

  @computed
  get currentFieldSetId() {
    return this.currentId;
  }

  @computed
  get isValid() {
    let valid = true;
    this.fieldSets.forEach((fs) => {
      valid = valid && fs.isValid;
    });
    return valid;
  }

  @computed
  get isPending() {
    return this.pending;
  }

  @computed
  get data() {
    let data = {};
    this.fieldSets.forEach((fs) => {
      data = { ...data, ...fs.data };
    });
    return data;
  }

  @computed
  get isChanged() {
    let changed = false;
    this.fieldSets.forEach((fs) => {
      changed = changed || fs.isChanged;
    });
    return changed;
  }
}
