import Coordinates from "~/common/models/Coordinates";
import { parseDateTime } from "~/common/datetime";
import { computed, observable, action, runInAction } from "mobx";
import AuditFactMonitoring from "./AuditFactMonitoring";
import AuditFactReport from "./AuditFactReport";

/**
 * Fact Model.
 */
export default class AuditFact {
  id;

  @observable author;
  @observable point;

  @observable hasAuthor = false;
  @observable hasPoint = false;

  createdAt;
  start;
  finish;

  @observable reportCount;
  @observable monitoringReports = new Map();
  @observable questReports = new Map();

  visitFactId;

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = data.id;
    this.visitFactId = data.visitFactId;

    this.createdAt = parseDateTime(data.createdAt);
    this.start = {
      datetime: parseDateTime(data.start.datetime),
      coords: new Coordinates(data.start.coords),
    };
    this.finish = {
      datetime: parseDateTime(data.finish.datetime),
      coords: new Coordinates(data.finish.coords),
    };

    this.getAuthor(data.authorId);
    this.getPoint(data.pointId);

    this.setQuestData(data.questReports);
    this.setMonData(data.monitoringReports);
  }

  @action
  setQuestData(data) {
    data.forEach((item) => {
      const report = new AuditFactReport(item, this.store);
      this.questReports.set(report.questId, report);
    });
  }

  @action
  setMonData(data) {
    data.forEach((item) => {
      const monItem = new AuditFactMonitoring(item, this.store);
      this.monitoringReports.set(monItem.monitoringId, monItem);
    });
  }

  @action
  async getAuthor(id) {
    const author = await this.store.rootStore.employees.get(id);
    runInAction(() => {
      this.author = author;
      this.hasAuthor = true;
    });
  }

  @action
  async getPoint(id) {
    const point = await this.store.rootStore.points.addPoint(id);
    runInAction(() => {
      this.point = point;
      this.hasPoint = true;
    });
  }

  @computed
  get data() {
    const visitFact = this.store.visitFacts.get(this.visitFactId);
    const reportCount = visitFact && visitFact.data.reportIds.length;
    return {
      id: this.id,
      auditor: this.author && `${this.author.lastName} ${this.author.firstName}`,
      auditFact: {
        id: this.factId,
        reportCount,
        start: {
          datetime: this.start.datetime.format("DD.MM.YYYY HH:mm"),
        },
        finish: {
          datetime: this.finish.datetime.format("DD.MM.YYYY HH:mm"),
        },
        point: {
          name: this.point && this.point.name,
          code: this.point && this.point.code,
        },
      },
    };
  }

  @computed
  get isPending() {
    return !(this.hasAuthor && this.hasPoint);
  }
}
