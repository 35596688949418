import { observable, action, computed } from "mobx";
import Parameter from "./Parameter";

/**
 * Page Model.
 */
export default class Page {
  id;
  name;
  @observable parameters;
  @observable journal;

  store;

  constructor(data, store, journal) {
    this.store = store;

    this.journal = journal;

    this.id = data.id;
    this.name = data.name;
    this.fillParams(data.parameters);
  }

  @action
  fillParams(paramsData) {
    if (paramsData.length) {
      this.parameters = new Map();
      paramsData.forEach((paramData) => {
        const param = new Parameter(paramData, this.store);
        this.parameters.set(param.id, param);
      });
    }
  }

  @computed
  get paramsArray() {
    const array = [];
    // бывают страницы без параметров
    if (this.parameters) {
      this.parameters.forEach((param) => {
        array.push(param);
      });
    }
    return array;
  }
}
