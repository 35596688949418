import React from "react";

import { observer, inject } from "mobx-react";
import SplitPane from "react-split-pane";

import CheckboxTable from "~/common/components/CheckboxTable";
import Table from "~/common/components/Table";
import PaginationComponent from "~/modules/audits/PaginationComponent";

@inject("monitoringViewerStore", "userStore", "pointsStore")
@observer
class DataModule extends React.Component {
  constructor(props) {
    super(props);

    this.onLoadPage = this.onLoadPage.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);
    this.onLoadReportPage = this.onLoadReportPage.bind(this);
    this.onReportPageChange = this.onReportPageChange.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);

    this.checkboxTable = React.createRef();

    this.state = {
      page: 0,
    };
  }

  toggleSelection(row) {
    this.props.monitoringViewerStore.setCurrentFact(row.id);
    this.props.monitoringViewerStore.showTheReportTable(true);
    if (this.props.monitoringViewerStore.showTable) {
      this.props.monitoringViewerStore.loadReportPage();
    }
  }

  isSelected(key) {
    const fact = this.props.monitoringViewerStore.currentFact;
    return !!fact && fact.id === key;
  }

  async onLoadPage(state) {
    const pages = await this.props.monitoringViewerStore.loadPage(
      state.pageSize,
      state.page + 1
    );
    this.setPages(pages);
  }

  async onLoadReportPage(state) {
    const pages = await this.props.monitoringViewerStore.loadReportPage(
      state.pageSize,
      state.page + 1
    );
    this.setReportPages(pages);
  }

  onPageChange(page, pageSize) {
    this.onLoadPage({ page, pageSize });
  }

  onSortedChange(sorted) {
    this.props.monitoringViewerStore.onSortedChange(sorted);
  }

  onReportPageChange(page, pageSize) {
    this.onLoadReportPage({ page, pageSize });
  }

  setPages() {
    const { monitoringViewerStore } = this.props;
    this.setState({
      pageSize: monitoringViewerStore.pageSize,
      page:     monitoringViewerStore.page - 1,
    });
  }

  setReportPages(reportPages) {
    const { monitoringViewerStore } = this.props;
    this.setState({
      reportPageSize: monitoringViewerStore.reportPageSize,
      reportPage:     monitoringViewerStore.reportPage - 1,
      reportPages,
    });
  }

  loader({ loading }) {
    return loading ? (
      <div className="points-preloader">
        <div />
      </div>
    ) : null;
  }

  render() {
    const { monitoringViewerStore, pointsStore, userStore } = this.props;
    const {
      pageIsLoading,
      hasData,
      isPending,
      factArray,
      factColumns,
      showReportTable,
      pageReportIsLoading,
      reportData,
      columns,
      hasAnswers,
      showTable,
      pages,
    } = monitoringViewerStore;
    const checkboxProps = {
      toggleSelection: this.toggleSelection,
      selectType:      "radio",
    };

    const paginator = (props) => {
      return (
        <PaginationComponent
          {...props}
          onPageChange={this.onPageChange}
          pageSize={50}
          page={this.state.page}
          pages={pages}
          showPageJump={false}
          hideDownloadButton={true}
          userStore={userStore}
        />
      );
    };

    return (
      <div className="data-module">
        {showTable && (
          <SplitPane split="vertical" defaultSize={"30%"} primary="first">
            <div className="audit-table-holder">
              {hasData && !isPending && !pointsStore.isUserPending && (
                <CheckboxTable
                  loading={pageIsLoading}
                  data={factArray}
                  columns={factColumns}
                  showPagination={true}
                  defaultPageSize={50}
                  onFetchData={this.onLoadPage}
                  className="-striped -highlight"
                  LoadingComponent={this.loader}
                  pageSizeOptions={[50]}
                  PaginationComponent={paginator}
                  {...checkboxProps}
                />
              )}
              {!hasData && !pageIsLoading && (
                <div className="empty-plan">No data.</div>
              )}
              {(isPending || pageIsLoading || pointsStore.isUserPending) && (
                <div className="points-preloader">
                  <div />
                </div>
              )}
            </div>
            <div className="audit-fact-holder">
              {showReportTable && (
                <Table
                  sortable={true}
                  multiSort={true}
                  loading={pageReportIsLoading}
                  data={reportData}
                  columns={columns}
                  showPagination={true}
                  defaultPageSize={100}
                  onFetchData={this.onLoadReportPage}
                  className="-striped -highlight"
                  style={{ width: "100%" }}
                  LoadingComponent={this.loader}
                />
              )}
              {!showReportTable && !pageReportIsLoading && (
                <div className="empty-plan">
                  Choose date range and monitoring to view data.
                </div>
              )}
              {(isPending || pageReportIsLoading) && (
                <div className="points-preloader">
                  <div />
                </div>
              )}
            </div>
          </SplitPane>
        )}

        {!hasAnswers && !showTable && (
          <div className="empty-plan">
            Choose date range and monitoring to view data.
          </div>
        )}
      </div>
    );
  }
}

export default DataModule;
