import React from "react";
import SplitPane from "react-split-pane";

import { observer, inject } from "mobx-react";

import RequestForm from "./RequestForm";
import DataModule from "./DataModule";

@inject("monitoringViewerStore")
@observer
class MonitoringViewer extends React.Component {
  render() {
    const { monitoringViewerStore } = this.props;
    const { isPendingData } = monitoringViewerStore;
    return (
      <div className="monitoring-viewer">
        {isPendingData && ( // TODO: нужно бы унести в Loading компонент
          <div className="points-preloader">
            <div />
          </div>
        )}
        {!isPendingData && (
          <SplitPane split="vertical" defaultSize={"20em"} primary="first">
            <RequestForm />
            <DataModule />
          </SplitPane>
        )}
      </div>
    );
  }
}

export default MonitoringViewer;
