import { action, observable, computed } from "mobx";
import Page from "./Page";

/**
 * Quest Model.
 */
export default class Quest {
  id;
  name;
  @observable pages;
  infocardIds;
  monitorings;
  // directory
  store;

  constructor(data, store) {
    this.store = store;

    this.id = data.id;
    if (!data.pending) {
      this.fillData(data);
    }
  }

  @action
  fillData(data) {
    this.name = data.name;
    this.fillPages(data.pages);
    this.fillInfos(data.infocardIds);
    this.fillMons(data.monitorings);
  }

  @action
  fillPages(pagesData) {
    if (pagesData && pagesData.length) {
      this.pages = new Map();
      pagesData.forEach(pageData => {
        const page = new Page(pageData, this.store);
        this.pages.set(page.id, page);
      });
    }
  }

  @action
  fillInfos(infosData) {}
  @action
  fillMons(monsData) {}

  @computed
  get shortRepresentation() {
    return {
      id: this.id,
      name: this.name,
      label: this.label
    };
  }

  @computed
  get allParams() {
    const params = [];
    if (this.pages) {
      this.pages.forEach(page => {
        page.paramsArray.forEach(param => {
          params.push(param);
        });
      });
    }
    return params;
  }

  @computed
  get pagesArray() {
    const array = [];
    this.pages.forEach(page => {
      array.push(page);
    });
    return array;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }
}
