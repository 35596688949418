import React from "react";
import { inject } from "mobx-react";

import DayDrop from "./DayDrop";

@inject("planningStore")
class Day extends React.PureComponent {
  render() {
    const { index } = this.props;
    const { employee } = this.props.planningStore;
    return (
      <div key={index} className={`plan-day ${index % 2 === 1 ? "odd" : ""}`}>
        <DayDrop {...this.props} employee={employee} />
      </div>
    );
  }
}

export default Day; //eslint-disable-line
