import React from "react";
import { IoMdClose } from "react-icons/io";

const Button = (props) => {
  return (
    <div
      className={`${props.className} ${props.disabled ? "disabled" : ""}`}
      title={props.title}
      onClick={!props.disabled && !props.isLoading ? props.onClick : null}
    >
      {props.isLoading && (
        <span className="button-icon-holder">
          <div className="button-preloader">
            <div />
          </div>
        </span>
      )}
      {props.isError && (
        <span className="button-icon-holder">
          <IoMdClose style={{ color: "#f53" }} />
        </span>
      )}
      {!props.isLoading && !props.isError && props.leftIcon && (
        <span className="button-icon-holder">{props.leftIcon}</span>
      )}
      {props.text && <span className="button-text">{props.text}</span>}
      {!props.isLoading && !props.isError && props.rightIcon && (
        <span className="button-icon-holder">{props.rightIcon}</span>
      )}
    </div>
  );
};

export default Button;
