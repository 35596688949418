import React from "react";

import { observer, inject } from "mobx-react";
import Table from "~/common/components/Table";

@inject("userStore", "employeesStore", "pointsStore", "auditStore")
@observer
class FieldsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        Header: "ID",
        id: "id",
        accessor: "param.id", // String-based value accessors!
      },
      {
        Header: "Name",
        accessor: "param.name",
      },
      {
        Header: "Value",
        accessor: "value",
        Cell: this.props.renderCell,
      },
    ];

    if (!props.noAudit) {
      this.columns.push({
        Header: "Audit Value",
        id: "auditVal",
        accessor: "auditValue",
        Cell: this.props.renderCell,
      });
    }
  }

  render() {
    return (
      <div className="inner-holder">
        <Table
          data={this.props.data}
          columns={this.columns}
          defaultPageSize={this.props.data && this.props.data.lengh}
          showPagination={false}
          hiddenColumns={["id"]}
        />
      </div>
    );
  }
}

export default FieldsInfo;
