import { computed, observable, runInAction, action } from "mobx";
import Parameter from "./Parameter";

/**
 * Mon Model.
 */
export default class Monitoring {
  @observable directory;
  id;
  name;
  library;
  params = new Map();

  store;

  constructor(data, dir, store) {
    this.store = store;

    this.id = data.id;
    if (!data.pending) {
      this.setData(data, dir);
    }
  }

  @action
  setData(data, dir) {
    this.name = data.label;
    this.library = data.library;
    this.setParams(data.params);
    if (dir) {
      this.directory = dir;
    } else {
      this.getDir(data.directory);
    }
  }

  @action
  async getDir(dirData) {
    const dir = await this.store.rootStore.dirStore.get(dirData.id);
    runInAction(() => {
      this.directory = dir;
    });
  }

  @action
  setParams(paramsArray) {
    paramsArray.forEach(param => {
      const parameter = new Parameter(param, this.store);
      this.params.set(parameter.id, parameter);
    });
  }

  @computed
  get paramArray() {
    const array = [];
    this.params.forEach(record => {
      array.push(record);
    });
    return array;
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }
}
