import React from "react";

import { IoMdTimer, IoMdFlag } from "react-icons/io";

import { observer, inject } from "mobx-react";
import Table from "~/common/components/Table";

@inject("userStore", "employeesStore", "pointsStore", "auditStore")
@observer
class ReportsInfo extends React.Component {
  columns = [
    {
      Header: "",
      id: "fact",
      columns: [
        {
          Header: "ID",
          accessor: "id", // String-based value accessors!
        },
        {
          Header: "Name",
          accessor: "name",
        },
      ],
    },
    {
      Header: "Point",
      id: "point",
      columns: [
        {
          Header: "Code",
          id: "factPointCode",
          accessor: "point.code",
        },
        {
          Header: "Name",
          id: "factPointName",
          accessor: "point.name",
        },
      ],
    },
    {
      Header: (props) => {
        return (
          <span>
            <IoMdFlag /> Report
          </span>
        );
      },
      id: "report",
      columns: [
        {
          Header: (props) => {
            return (
              <span>
                <IoMdTimer />
                Start
              </span>
            );
          },
          id: "factStartDatetime",
          accessor: (d) => {
            return d.start.datetime.format("DD.MM.YYYY HH:mm");
          },
        },
        {
          Header: (props) => {
            return (
              <span>
                <IoMdTimer />
                Finish
              </span>
            );
          },
          id: "factFinishDatetime",
          accessor: (d) => {
            return d.finish.datetime.format("DD.MM.YYYY HH:mm");
          },
        },
      ],
    },
  ];

  render() {
    const { auditStore } = this.props;
    const infos = [];
    if (auditStore.isPending) {
      return (
        <div className="points-preloader">
          <div />
        </div>
      );
    }
    const visitFact = auditStore.currentVisitFact;
    // const audit = auditStore.currentFact;

    if (visitFact && !visitFact.isPending) {
      const reports = visitFact.reportArray;
      if (reports) {
        reports.forEach((report) => {
          const quest = report.quest;
          infos.push({
            id: report.id,
            name: quest && quest.name,
            start: report.start,
            finish: report.finish,
            point: report.point,
          });
        });
      }
    }
    return (
      <div className="quest-info-holder">
        <Table
          sortable={true}
          multiSort={true}
          showPagination={false}
          defaultPageSize={infos.length}
          data={infos}
          columns={this.columns}
          className="-striped -highlight"
          style={{ width: "100%" }}
        />
      </div>
    );
  }
}

export default ReportsInfo;
