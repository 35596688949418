import React from "react";
import Select from "react-select";

/**
 * Activity Select Box
 */
const ActivityAutocompleteSelect = ({ options, value, onChange }) => {
  return (
    <Select
      className="select-quest"
      classNamePrefix="manager-select"
      name="activity"
      placeholder="Select activity..."
      value={value}
      onChange={onChange}
      options={options}
    />
  );
};

export default ActivityAutocompleteSelect;
