import { computed, observable } from "mobx";
import moment from "moment";

/**
 * Answer Model.
 */
export default class Answer {
  @observable id = null;
  @observable planId = null;
  @observable store = null;

  @observable authorId = null;
  @observable comment = null;
  @observable datetime = null;
  @observable complies = null;

  constructor(data, store) {
    this.store = store;

    this.id = `${data.id}`;
    this.planId = data.activityPlan;
    this.authorId = data.author;
    this.comment = data.comment;
    if (data.datetime) {
      this.datetime = moment(data.datetime, "DD.MM.YYYY");
    }
    this.complies = data.complies;
  }

  @computed
  get plan() {
    return this.store.getActionPLanById(this.planId);
  }

  @computed
  get authorName() {
    const author = this.store.rootStore.employees.getSync(this.authorId);
    const name = (author && author.fullName) || "No author";
    return name;
  }

  @computed
  get value() {
    return this.id;
  }
}
