import { observable, computed, toJS } from "mobx";

import { uuid } from "uuidv4";
/**
 * Point data model.
 */
export default class Coordinates {
  @observable id;
  @observable lat;
  @observable lng;

  constructor(coords) {
    if (coords) {
      const { lat, lng } = coords;
      this.id = uuid();
      this.lat = lat;
      this.lng = lng;
    }
  }

  @computed
  get asArray() {
    if (this.lat && this.lng) {
      return toJS([this.lat, this.lng]);
    } else {
      return undefined;
    }
  }
}
