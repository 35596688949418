/**
 * Employee data model.
 */
export default class Employee {
  id;
  email;
  firstName;
  lastName;
  timezone;

  store;

  constructor(id, email, firstName, lastName, timezone, store = null) {
    this.id = id;
    this.store = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.timezone = timezone;
    this.store = store;
  }

  /**
   * Compute employee's full name.
   */
  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  get label() {
    return this.fullName;
  }

  get value() {
    return this.id;
  }
}
