import React from "react";

import { observer, inject } from "mobx-react";
import ActionPlanCard from "./ActionPlanCard";

@inject("userStore", "actionPlanStore")
@observer
class DataModule extends React.Component {
  render() {
    const { actionPlanStore } = this.props;
    const { plansArray } = actionPlanStore;
    const { pageIsLoading } = actionPlanStore;
    if (pageIsLoading) {
      return (
        <div className="data-module">
          <div className="points-preloader">
            <div />
          </div>
        </div>
      );
    }

    const renderPlans = [[], [], []];

    plansArray.forEach((planData, i) => {
      renderPlans[i % 3].push(
        <ActionPlanCard key={planData.id} planItem={planData} />
      );
    });

    return (
      <div className="action-plan-data-module">
        {plansArray.length > 0 && (
          <div className="plan-column">{renderPlans[0]}</div>
        )}
        {plansArray.length > 0 && (
          <div className="plan-column">{renderPlans[1]}</div>
        )}
        {plansArray.length > 0 && (
          <div className="plan-column">{renderPlans[2]}</div>
        )}
        {plansArray.length === 0 && (
          <div className="empty-plan">
            Choose date range and point to view data.
          </div>
        )}
      </div>
    );
  }
}

export default DataModule;
