import React from "react";
import { observer, inject } from "mobx-react";
import { IoMdFolderOpen } from "react-icons/io";

import Button from "~/common/components/Button";
import PointsListItem from "./PointsListItem";
import PointsTree from "./PointsTree";

@inject("pointsStore")
@observer
class PointsList extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.setSort = this.setSort.bind(this);
  }
  handleChange(e) {
    this.props.pointsStore.setFilter(e.target.value);
  }

  setSort(field) {
    this.props.pointsStore.setSort(field);
  }

  render() {
    const { employee, pointsStore } = this.props;
    const {
      isUserPoints,
      pointsArray,
      filter,
      isUserPending,
      isPending,
      sortField,
      sortDESC,
    } = pointsStore;

    const items = pointsArray.map((point, index) => {
      return <PointsListItem key={point.code} point={point} index={index} />;
    });

    return (
      <div className="point-list-content">
        <div className="buttons">
          <Button
            title="Work day statuses"
            className={`button inline ${
              isUserPoints === "status" ? "active" : ""
            }`}
            onClick={() => {
              if (isUserPoints !== "status") {
                pointsStore.changeIsUserPoints("status");
              }
            }}
            text="Statuses"
          />
          <Button
            className={`button inline ${
              isUserPoints === "user" ? "active" : ""
            } ${employee ? "" : "hidden"}`}
            onClick={() => {
              if (isUserPoints !== "user") {
                pointsStore.changeIsUserPoints("user");
              }
            }}
            text="Employee's"
          />
          <Button
            className={`button inline ${
              isUserPoints === "all" ? "active" : ""
            }`}
            onClick={() => {
              if (isUserPoints !== "all") {
                pointsStore.changeIsUserPoints("all");
              }
            }}
            text="All"
          />
          <Button
            className={`button solo inline ${
              isUserPoints === "tree" ? "active" : ""
            }`}
            onClick={() => {
              if (isUserPoints !== "tree") {
                pointsStore.changeIsUserPoints("tree");
              }
            }}
            leftIcon={<IoMdFolderOpen />}
          />
        </div>
        {isUserPoints !== "tree" && (
          <input
            className="point-search"
            placeholder="Point filter..."
            value={filter}
            onChange={this.handleChange}
          />
        )}
        {(isPending || (isUserPending && isUserPoints)) && ( // TODO: нужно бы унести в Loading компонент
          <div className="points-preloader">
            <div />
          </div>
        )}
        {!isPending &&
          !(isUserPending && isUserPoints) &&
          isUserPoints !== "tree" && (
            <div className="point-item box header">
              <div
                onClick={() => {
                  this.setSort();
                }}
                className={`point-index ${
                  sortField === "index"
                    ? `sorted ${sortDESC ? "desc" : ""}`
                    : ""
                }`}
              >
                #
              </div>
              <div
                onClick={() => {
                  this.setSort("name");
                }}
                className={`point-name ${
                  sortField === "name" ? `sorted ${sortDESC ? "desc" : ""}` : ""
                }`}
              >
                Name
              </div>
              <div
                onClick={() => {
                  this.setSort("code");
                }}
                className={`point-code ${
                  sortField === "code" ? `sorted ${sortDESC ? "desc" : ""}` : ""
                }`}
              >
                Code
              </div>
            </div>
          )}
        {!isPending &&
          !(isUserPending && isUserPoints) &&
          isUserPoints !== "tree" && <div className="items">{items}</div>}
        {!isPending &&
          !(isUserPending && isUserPoints) &&
          isUserPoints === "tree" && <PointsTree />}
      </div>
    );
  }
}

export default PointsList;
