import React, { useMemo, useState } from "react";
import { IoMdCalendar } from "react-icons/io";
import { observer } from "mobx-react";
import { SingleDatePicker } from "react-dates";

const Date = observer((props) => {
  const { config } = props;
  const { label, value, isValid, changeValue, isDisabled, id, required } = config;

  const onChange = (date) => {
    const value = date;
    changeValue(value);
  };
  const [focused, setFocused] = useState(false);
  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  return (
    <div className={`input-div date ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          <IoMdCalendar />
        </div>
        <SingleDatePicker
          date={value}
          onDateChange={onChange}
          disabled={isDisabled}
          noBorder={true}
          placeholder="00.00.0000"
          required={required}
          isOutsideRange={() => {
            return false;
          }}
          showClearDate={!required}
          focused={focused}
          onFocusChange={({ focused }) => {
            setFocused(focused);
          }}
          id={id}
        />
      </div>
    </div>
  );
});

export default Date;
