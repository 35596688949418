import React from "react";
import { observer, inject } from "mobx-react";
import base64 from "base-64";
import { saveAs } from "file-saver";
import { IoMdCloudDownload, IoMdEye } from "react-icons/io";

import "react-tabs/style/react-tabs.css";

import { Button } from "~/common/components";
import FromTo from "~/common/components/FromTo";
import EmployeeTree from "~/modules/employees/components/EmployeeTree";
import PointsTree from "~/modules/points/components/PointsTree";
import ActivityAutocompleteSelect from "./ActivityAutocompleteSelect";

@inject(
  "userStore",
  "employeesStore",
  "pointsStore",
  "auditStore",
  "actionPlanStore"
)
@observer
class RequestForm extends React.Component {
  constructor(props) {
    super(props);

    this.onGetData = this.onGetData.bind(this);
    this.onGetXcel = this.onGetXcel.bind(this);
    this.setDates = this.setDates.bind(this);
    this.onSelectEmployee = this.onSelectEmployee.bind(this);
    this.onSelectPoint = this.onSelectPoint.bind(this);
    this.onActivityChange = this.onActivityChange.bind(this);

    this.state = {
      focusedInput:     undefined,
      isLoadingFile:    false,
      loadingFileError: false,
    };
  }

  onGetData() {
    const { actionPlanStore } = this.props;
    actionPlanStore.loadPage();
  }

  onGetXcel() {
    this.setState({
      isLoadingFile:    true,
      loadingFileError: false,
    });
    const { uid, token } = this.props.userStore;
    const { employee, point, startDate, endDate, actionPlan } =
      this.props.actionPlanStore;
    const from = startDate.toISOString();
    const to = endDate.toISOString();
    const downloadUrl = "../api/v4/journals/searches";
    const options = {
      method:  "POST",
      headers: {
        Authorization: `Basic ${base64.encode(`${uid}:${token}`)}`,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      body: JSON.stringify({
        authors: employee && [employee.id],
        points:  point && [point.id],
        from,
        to,
      }),
    };
    fetch(downloadUrl, options).then(
      (response) => {
        response.blob().then(
          (blob) => {
            saveAs(blob, `${actionPlan.name}.xlsx`);
            this.setState({
              isLoadingFile: false,
            });
          },
          () => {
            this.setState({
              isLoadingFile:    false,
              loadingFileError: true,
            });
          }
        );
      },
      () => {
        this.setState({
          isLoadingFile:    false,
          loadingFileError: true,
        });
      }
    );
  }

  onActivityChange(activity) {
    this.props.actionPlanStore.changeActivity(activity);
  }

  setDates({ startDate, endDate }) {
    const { actionPlanStore } = this.props;
    actionPlanStore.setDates({ startDate, endDate });
  }

  onSelectEmployee(employee) {
    const { actionPlanStore } = this.props;
    actionPlanStore.setEmployee(employee);
  }

  onSelectPoint(point) {
    const { actionPlanStore } = this.props;
    actionPlanStore.setPoint(point);
  }

  render() {
    const { actionPlanStore } = this.props;
    const {
      isLoadingFile,
      loadingFileError,
      canRequest,
      startDate,
      selectedEmployeeIds,
      selectedPointIds,
      endDate,
      activity,
      activitiesArray,
    } = actionPlanStore;

    return (
      <div className="request-form">
        <div className="buttons">
          <Button
            className={"button inline success"}
            onClick={this.onGetData}
            leftIcon={<IoMdEye />}
            text="View data"
          />
          <Button
            className={"button inline active"}
            disabled={!canRequest || isLoadingFile}
            onClick={this.onGetXcel}
            leftIcon={<IoMdCloudDownload />}
            text="Download"
            isLoading={isLoadingFile}
            isError={loadingFileError}
          />
        </div>
        <ActivityAutocompleteSelect
          value={activity}
          options={activitiesArray}
          onChange={this.onActivityChange}
        />
        <FromTo
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.setDates}
        />
        {
          <div className="request-params-holder">
            <EmployeeTree
              onSelect={this.onSelectEmployee}
              selectedIds={selectedEmployeeIds}
            />
            <PointsTree
              onSelect={this.onSelectPoint}
              selectedIds={selectedPointIds}
            />
          </div>
        }
      </div>
    );
  }
}

export default RequestForm;
