import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";

import {
  IoMdLock,
  IoMdCalendar,
  IoMdArchive,
  IoMdPin,
  IoMdClipboard,
  IoMdFiling,
  IoMdPaper,
  IoMdRecording,
  IoMdPerson,
  IoMdAttach,
  IoMdImages,
} from "react-icons/io";
import { FaFont, FaPlus, FaMinus } from "react-icons/fa";

import { Button } from "~/common/components";

@inject("rootStore", "userStore", "settingsStore")
@observer
class LeftMenu extends React.Component {
  static FONTSIZE_STEP = 1;

  constructor(props) {
    super(props);

    this.openModule = this.openModule.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  /**
   * Opens module by it's name.
   *
   * @param {string} name
   * @returns null
   */
  openModule(name) {
    this.props.rootStore.setModule(name);
  }

  handleLogout() {
    this.props.userStore.logout();
  }

  render() {
    const { userStore, settingsStore } = this.props;
    const activeModule = "" || this.props.rootStore.module;
    const buttons = [];

    // feed
    if (userStore.hasPermission("core.modules.can_use_feed")) {
      buttons.push(
        <Button
          key="feed"
          className={`button leftBarButton ${
            activeModule === "feed" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("feed");
          }}
          leftIcon={<IoMdPaper />}
          text={<span className="line">News feed</span>}
        />
      );
    }

    // dataViewer
    if (userStore.hasPermission("core.modules.can_use_data")) {
      buttons.push(
        <Button
          key="dataViewer"
          className={`button leftBarButton ${
            activeModule === "dataViewer" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("dataViewer");
          }}
          leftIcon={<IoMdArchive />}
          text={<span className="line">Data Viewer</span>}
        />
      );
    }

    // monitoringViewer
    if (userStore.hasPermission("core.modules.can_use_data")) {
      buttons.push(
        <Button
          key="monitoringViewer"
          className={`button leftBarButton ${
            activeModule === "monitoringViewer" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("monitoringViewer");
          }}
          leftIcon={<IoMdFiling />}
          text={<span className="line">Monitoring Viewer</span>}
        />
      );
    }

    // Planning
    if (userStore.hasPermission("core.modules.can_use_planning")) {
      buttons.push(
        <Button
          key="plan"
          className={`button leftBarButton ${
            activeModule === "plan" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("plan");
          }}
          leftIcon={<IoMdCalendar />}
          text={<span className="line">Planning</span>}
        />
      );
    }

    // Audits
    if (userStore.hasPermission("core.modules.can_use_audits")) {
      buttons.push(
        <Button
          key="audit"
          className={`button leftBarButton ${
            activeModule === "audits" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("audits");
          }}
          leftIcon={<IoMdClipboard />}
          text={<span className="line">Audits</span>}
        />
      );
    }

    // Map
    if (userStore.hasPermission("core.modules.can_use_map")) {
      buttons.push(
        <Button
          key="map"
          className={`button leftBarButton ${
            activeModule === "map" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("map");
          }}
          leftIcon={<IoMdPin />}
          text={<span className="line">Map</span>}
        />
      );
    }

    if (userStore.hasPermission("core.modules.can_use_journals")) {
      buttons.push(
        <Button
          key="journals"
          className={`button leftBarButton ${
            activeModule === "journals" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("journals");
          }}
          leftIcon={<IoMdRecording />}
          text={<span className="line">Journals</span>}
        />
      );
    }

    if (userStore.hasPermission("core.modules.can_use_profiles")) {
      buttons.push(
        <Button
          key="profiles"
          className={`button leftBarButton ${
            activeModule === "profiles" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("profiles");
          }}
          leftIcon={<IoMdPerson />}
          text={<span className="line">Profiles</span>}
        />
      );
    }

    if (userStore.hasPermission("core.modules.can_use_activities")) {
      buttons.push(
        <Button
          key="action-plan"
          className={`button leftBarButton ${
            activeModule === "action-plan" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("action-plan");
          }}
          leftIcon={<IoMdAttach />}
          text={<span className="line">ActionPlan</span>}
        />
      );
    }

    if (userStore.hasPermission("core.modules.can_use_gallery")) {
      buttons.push(
        <Button
          key="gallery"
          className={`button leftBarButton ${
            activeModule === "gallery" ? "current" : ""
          }`}
          onClick={() => {
            return this.openModule("gallery");
          }}
          leftIcon={<IoMdImages />}
          text={<span className="line">Gallery</span>}
        />
      );
    }

    buttons.push(<div key="spacer" className="spacer" />);

    buttons.push(
      <Button
        key="encreaseFont"
        className={"button leftBarButton font encrease"}
        onClick={() => {
          return settingsStore.changeFontSize(LeftMenu.FONTSIZE_STEP);
        }}
        leftIcon={
          <Fragment>
            <FaFont />
            <div className="sub">
              <FaPlus />
            </div>
          </Fragment>
        }
        text={
          <span className="line">
            Increase font to {settingsStore.fontSize + LeftMenu.FONTSIZE_STEP}
          </span>
        }
      />
    );

    // Common buttons
    buttons.push(
      <Button
        key="decreaseFont"
        className={"button leftBarButton font decrease"}
        onClick={() => {
          return settingsStore.changeFontSize(-1 * LeftMenu.FONTSIZE_STEP);
        }}
        disabled={settingsStore.fontSize <= settingsStore.MINIMUM_FONTSIZE}
        leftIcon={
          <Fragment>
            <FaFont />
            <div className="sub">
              <FaMinus />
            </div>
          </Fragment>
        }
        text={
          <span className="line">
            Decrease font to {settingsStore.fontSize - LeftMenu.FONTSIZE_STEP}
          </span>
        }
      />
    );

    buttons.push(
      <Button
        key="0"
        className={"button login-bar leftBarButton"}
        onClick={this.handleLogout}
        leftIcon={<IoMdLock />}
        text={<span className="line">Log out</span>}
      />
    );

    return <div className="leftBar">{buttons}</div>;
  }
}

export default LeftMenu;
