import React from "react";
import { observable, action, runInAction, computed, toJS } from "mobx";

import {
  IoMdKeypad,
  IoMdPerson,
  IoMdPin,
  IoMdClock,
  IoMdCalendar,
} from "react-icons/io";

const iconMap = {
  Time: <IoMdClock key="Time" />, // eslint-disable-line
  Date: <IoMdCalendar key="Date" />, // eslint-disable-line
  Author: <IoMdPerson key="Author" />, // eslint-disable-line
  "POS name": <IoMdPin key="POS name" />, // eslint-disable-line
  "POS code": <IoMdKeypad key="POS code" />, // eslint-disable-line
};

export default class FeedStore {
  @observable isPending = true;
  @observable items = new Map();
  @observable data;
  @observable config;

  timeout = undefined;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  setPending(pending) {
    this.isPending = pending;
  }

  @action
  dropTimeout() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  @action
  init() {
    if (this.rootStore.user.isLoggedIn) {
      this.fetchData();
    }
  }

  getIcon(text) {
    let icon = null;
    if (iconMap[text]) {
      icon = iconMap[text];
    }
    return icon;
  }

  processColumn(column, isMeta) {
    const meta = isMeta || column.text === "Metadata";
    const icon = meta ? this.getIcon(column.text) : null;
    const data = {
      Header: !meta ? (
        column.text
      ) : (
        <div className="meta-header">
          {icon}
          {column.text}
        </div>
      ),
      id:       column.dataIndex || column.text,
      accessor: column.dataIndex,
      width:    column.width,
    };

    if (column.columns) {
      const columns = [];
      column.columns.forEach((columnData) => {
        const item = this.processColumn(columnData, meta);
        columns.push(item);
      });
      data.columns = columns;
    }

    return data;
  }

  @action
  async fetchData() {
    this.setPending(true);
    const feedData = await this.api.getFeedData();
    this.timeout = setTimeout(() => {
      this.fetchData();
    }, feedData.period || 600000);
    const columns = [];

    feedData.columns.forEach((column) => {
      const data = this.processColumn(column);
      columns.push(data);
    });

    const data = [];

    if (feedData.data) {
      feedData.data.forEach((row) => {
        data.push({ ...row, canExpand: row._photos && row._photos.length });
      });
    }

    runInAction(() => {
      this.config = columns;
      this.data = data;
    });
    this.setPending(false);
  }

  @computed
  get columns() {
    return toJS(this.config);
  }

  @computed
  get tableData() {
    return toJS(this.data);
  }
}
