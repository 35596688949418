import { action, observable, computed, toJS } from "mobx";
import { parseDateTime } from "~/common/datetime";

export default class JournalRecord {
  @observable isPendingData = false;
  @observable answers = new Map();

  id;
  start;

  constructor(data, event) {
    this.event = event;
    this.report = this.event.report;
    this.journal = this.event.journal;
    this.store = this.event.store;

    const { answers, title, id } = data;
    this.id = id;
    this.name = title;
    this.datetime = parseDateTime(data.datetime);
    this.processAnswers(answers);
  }

  @action
  processAnswers(answers) {
    answers.forEach((answer) => {
      this.answers.set(`${answer.parameterId}`, answer);
    });
  }

  @computed
  get fields() {
    const fields = [];
    const page = this.journal.recordFieldset;
    if (page) {
      page.paramsArray.forEach((param) => {
        const answer = this.answers.get(`${param.id}`);
        fields.push({
          param,
          value: answer && answer.value,
        });
      });
    }
    return fields;
  }

  @computed
  get data() {
    return toJS(this.answers);
  }

  @computed
  get canExpand() {
    return this.fields.length;
  }

  @computed
  get title() {
    return this.name;
  }
}
