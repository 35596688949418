import { action, observable, computed } from "mobx";

import JournalEvent from "./JournalEvent";

export default class JournalReport {
  @observable isPendingData = false;
  @observable answers = new Map();
  @observable events = new Map();

  id;

  constructor(data, journalFact) {
    this.journalFact = journalFact;
    this.journal = this.journalFact.journal;
    this.store = this.journal.store;

    const { answers, events } = data;
    this.processAnswers(answers);
    this.processEvents(events);
  }

  @action
  processAnswers(answers) {
    this.answers.set(1, "answer");
    answers.forEach((answer) => {
      this.answers.set(`${answer.parameterId}`, answer);
    });
  }

  @action
  processEvents(events) {
    events.forEach((item) => {
      const event = new JournalEvent(item, this);
      this.events.set(`${event.id}`, event);
    });
  }

  @computed
  get fields() {
    const fields = [];
    const page = this.journal.fieldset;
    if (page) {
      page.paramsArray.forEach((param) => {
        const answer = this.answers.get(`${param.id}`);
        fields.push({
          param,
          value: answer,
        });
      });
    }
    return fields;
  }

  @computed
  get eventsArray() {
    return Array.from(this.events.values());
  }

  @computed
  get canExpand() {
    return this.events.size;
  }

  @computed
  get label() {
    return this.name;
  }
}
