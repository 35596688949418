import { observable, computed, action } from "mobx";
import Page from "~/common/models/Page";

export default class ProfileType {
  @observable isPendingData = false;
  @observable id;
  @observable name;
  @observable mainId;
  @observable pages = new Map();

  constructor(data, store) {
    this.store = store;

    this.name = data.name;
    this.id = `${data.id}`;
    this.mainId = `${data.mainId}`;

    this.processPages(data.pages);
  }

  @action
  processPages(pages) {
    if (pages && pages.length) {
      pages.forEach((page) => {
        const fs = new Page(page, this.store);
        this.pages.set(fs.id, fs);
      });
    }
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get value() {
    return this.id;
  }

  @computed
  get config() {
    const pages = [];
    this.pages.forEach((page) => {
      if (page.id !== this.mainId) {
        pages.push(page);
      }
    });
    return {
      main: this.pages.get(this.mainId) && this.pages.get(this.mainId),
      pages,
    };
  }
}
