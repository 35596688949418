import { observable } from "mobx";

/**
 * Fact Model.
 */
export default class AuditFactMonitoring {
  id;
  monitoringId;
  monitoringReportId;
  auditFactId;
  @observable values = new Map();

  constructor(data, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = data.id;
    this.auditFactId = data.auditFactId;
    this.monitoringId = data.monitoringId;
    this.monitoringReportId = data.monitoringReportId;
    // this.values;
    const vals = {};
    data.values.forEach((val) => {
      if (vals[val.reference]) {
        vals[val.reference][val.parameter.id] = val;
      } else {
        vals[val.reference] = {
          [val.parameter.id]: val,
        };
      }
    });
    this.values.replace(vals);
  }
}
