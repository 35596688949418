import React, { useMemo, useRef } from "react";
import { IoMdImage, IoMdCamera, IoMdTrash, IoMdCloudUpload } from "react-icons/io";
import { observer } from "mobx-react";
import Zoom from "react-medium-image-zoom";

import { Button } from "~/common/components";

const File = observer((props) => {
  const { config } = props;
  const { label, value, isValid, isDisabled, required, isLoading, image } = config;

  const onChange = (e) => {
    const target = e.currentTarget;
    const file = target.files[0];
    config.uploadFile(file);
  };

  const inputEl = useRef(null);

  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  const onClick = () => {
    inputEl.current.click();
  };

  const onClear = () => {
    config.changeValue();
  };

  return (
    <div className={`input-div ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          <IoMdImage />
        </div>
        <div className="image-input-holder" >
          <input
            type="file"
            className="file"
            accept="image/*"
            ref={inputEl}
            readOnly={isDisabled}
            onChange={onChange}
          />
          {!value && !isLoading &&  (
            <IoMdCamera />
          )}
          {isLoading && (
            <div className="points-preloader">
              <div />
            </div>
          )}
          {value && image && !isLoading && (
            <div
              className={"input-image"}
            >
              <Zoom zoomMargin={40}>
                <img
                  src={URL.createObjectURL(image)}
                  className="img"
                  alt={value}
                  style={{
                    maxWidth:   "100%",
                    maxheight:  "100%",
                    display:    "flex",
                    flexShrink: 0,
                  }}
                />
              </Zoom>
            </div>
          )}
          {!isLoading &&  (
            <div className="change-image">
              <Button
                key="upload"
                disabled={isDisabled}
                className={"button inline solo success"}
                onClick={(!isLoading && !isDisabled && onClick) || undefined}
                isLoading={isLoading}
                leftIcon={<IoMdCloudUpload />}
              />
            </div>
          )}
          {!isLoading &&  (
            <div className="clear-image">
              <Button
                key="clear"
                className={"button inline solo error"}
                onClick={(!isLoading && !isDisabled && onClear) || undefined}
                isLoading={isLoading}
                disabled={!value || isDisabled}
                leftIcon={<IoMdTrash />}
              />            
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default File;
