import React from "react";

import { observer, inject } from "mobx-react";
import PaginationComponent from "~/modules/audits/PaginationComponent";
import Table from "~/common/components/Table";

@inject("dataViewerStore", "userStore")
@observer
class DataModule extends React.Component {
  constructor(props) {
    super(props);

    this.onLoadPage = this.onLoadPage.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortedChange = this.onSortedChange.bind(this);

    this.state = { pages: null };
  }

  async onLoadPage(state) {
    if (!this.state.noRequest) {
      const pages = await this.props.dataViewerStore.loadPage(
        state.pageSize,
        state.page + 1
      );
      this.setPages(pages);
    }
  }

  onPageChange(page, pageSize) {
    this.setState({ noRequest: false });
    this.onLoadPage({ page, pageSize });
  }

  onSortedChange(sorted) {
    this.props.dataViewerStore.onSortedChange(sorted);
  }

  setPages(pages) {
    const { dataViewerStore } = this.props;
    this.setState({
      pageSize: dataViewerStore.pageSize,
      page: dataViewerStore.page - 1,
      pages,
    });
  }

  render() {
    const { dataViewerStore, userStore } = this.props;

    const {
      pageSize,
      page,
      pages,
      sorted,
      pageIsLoading,
      tableData,
      columns,
      hasAnswers,
      showTable,
    } = dataViewerStore;
    const paginator = (props) => {
      return (
        <PaginationComponent
          {...props}
          onPageChange={this.onPageChange}
          pageSize={pageSize}
          page={page - 1}
          pages={pages}
          showPageJump={true}
          hideDownloadButton={true}
          userStore={userStore}
        />
      );
    };

    return (
      <div className="data-module">
        {hasAnswers && (
          <Table
            className="-striped -highlight"
            style={{ width: "100%" }}
            sortable={true}
            multiSort={true}
            sorted={sorted}
            onSortedChange={this.onSortedChange}
            loading={pageIsLoading}
            data={tableData}
            columns={columns}
            showPagination={true}
            showPageJump={false}
            onFetchData={this.onLoadPage}
            pages={pages}
            defaultPageSize={pageSize}
            pageSizeOptions={[25, 50, 100]}
            PaginationComponent={paginator}
          />
        )}
        {!hasAnswers && !pageIsLoading && !showTable && (
          <div className="empty-plan">
            Choose date range and quest to view data.
          </div>
        )}
      </div>
    );
  }
}

export default DataModule;
