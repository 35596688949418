import { computed, observable, action } from "mobx";
import { uuid } from "uuidv4";

/**
 * Param Model.
 */
export default class Parameter {
  @observable id = uuid();
  name;
  readOnly;
  required;
  type;

  maxValue;
  minValue;
  viewConfig;
  opts = [];

  isReverse = false;
  @observable valid;
  @observable columns = new Map();
  store;

  constructor(data, store) {
    this.store = store;

    if (data.id) {
      this.id = `${data.id}`;
    }
    this.name = data.name;
    this.readOnly = data.readOnly;
    this.required = data.required;
    this.type = data.type;
    this.valid = data.valid;
    this.isReverse = data.isReverse;

    this.maxValue = data.maxValue;
    this.minValue = data.minValue;
    this.viewConfig = data.viewConfig;
    this.opts = data.options || [];

    this.initColumns(data.columns);
  }

  validate(value) {
    if (this.required && !value) {
      return { valid: false, cause: "Field is required" };
    }
    if (this.type === "number") {
      if (isNaN(Number(value))) {
        return { valid: false, cause: "Value should be Number" };
      }
    }
    return { valid: true, cause: null };
  }

  @action
  initColumns(columnsArray = []) {
    this.columns = new Map();
    columnsArray.forEach((columnData) => {
      const col = new Parameter(columnData, this.store);
      this.columns.set(col.id, col);
    });
  }

  @computed
  get label() {
    return this.name;
  }

  @computed
  get options() {
    const opts = [];
    this.opts.forEach((opt) => {
      opts.push({
        value: opt.value,
        id:    opt.id,
        label: opt.value,
      });
    });
    return opts;
  }

  @computed
  get fieldType() {
    return this.type;
  }

  @computed
  get isValid() {
    return this.valid;
  }
  @computed
  get isDisabled() {
    return this.readOnly;
  }
}
