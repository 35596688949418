import React from "react";
import moment from "moment";
import { observer } from "mobx-react";
import { DateRangePicker } from "react-dates";

import { Button } from "~/common/components";

require("moment/locale/ru"); // eslint-disable-line

@observer
class FromTo extends React.Component {
  constructor(props) {
    super(props);

    this.changeDates = this.changeDates.bind(this);
    this.onToday = this.onToday.bind(this);
    this.onYesterday = this.onYesterday.bind(this);
    this.onThisWeek = this.onThisWeek.bind(this);
    this.onLastWeek = this.onLastWeek.bind(this);
    this.onMonth = this.onMonth.bind(this);
    this.onYear = this.onYear.bind(this);

    this.onThisWeek();
    this.state = {
      focusedInput: undefined,
    };
  }

  changeDates(startDate, endDate) {
    this.props.onDatesChange({ startDate, endDate });
  }

  onToday() {
    const today = moment().startOf("day")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(
      today,
      moment(today).endOf("day")
        .add(moment().utcOffset(), "minutes")
    );
  }

  onYesterday() {
    const today = moment().startOf("day")
      .add(moment().utcOffset(), "minutes");
    const yesterday = today
      .add(-1, "days")
      .startOf("day")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(yesterday, yesterday);
  }

  onThisWeek() {
    const start = moment().startOf("week")
      .add(moment().utcOffset(), "minutes");
    const finish = moment().endOf("week")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(start, finish);
  }

  onLastWeek() {
    const start = moment()
      .add(-7, "days")
      .startOf("week")
      .add(moment().utcOffset(), "minutes");
    const finish = moment()
      .add(-7, "days")
      .endOf("week")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(start, finish);
  }

  onMonth() {
    const start = moment()
      .startOf("month")
      .add(moment().utcOffset(), "minutes");
    const finish = moment().endOf("month")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(start, finish);
  }

  onYear() {
    const start = moment().startOf("year")
      .add(moment().utcOffset(), "minutes");
    const finish = moment().endOf("year")
      .add(moment().utcOffset(), "minutes");
    this.changeDates(start, finish);
  }

  render() {
    const { startDate, endDate, onDatesChange } = this.props;

    return (
      <div className="from-to-form">
        <div className="buttons">
          <Button
            className={"button inline"}
            onClick={this.onToday}
            text="Today"
          />
          <Button
            className={"button inline"}
            onClick={this.onYesterday}
            text="Yesterday"
          />
          <Button
            className={"button inline"}
            onClick={this.onThisWeek}
            text="This week"
          />
        </div>
        <div className="buttons">
          <Button
            className={"button inline"}
            onClick={this.onLastWeek}
            text="Last week"
          />
          <Button
            className={"button inline"}
            onClick={this.onMonth}
            text="Month"
          />
          <Button
            className={"button inline"}
            onClick={this.onYear}
            text="Year"
          />
        </div>
        <DateRangePicker
          startDate={startDate}
          startDateId="startDate" // PropTypes.string.isRequired,
          endDate={endDate}
          endDateId="endDate" // PropTypes.string.isRequired,
          isOutsideRange={(day) => {
            return false;
          }}
          firstDayOfWeek={1}
          numberOfMonths={1}
          minimumNights={0}
          displayFormat="DD.MM.YYYY"
          onDatesChange={onDatesChange} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => {
            return this.setState({ focusedInput });
          }}
          small={true}
        />
      </div>
    );
  }
}

export default FromTo;
