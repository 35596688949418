import { observable, action, runInAction, computed } from "mobx";
import Profile from "../models/Profile";
import ProfileType from "../models/ProfileType";

export default class JournalsStore {
  @observable isPendingData = false;
  @observable isPendingProfile = false;
  @observable pendingProfiles = new Map();

  @observable employeeProfiles = new Map();
  @observable profiles = new Map();

  @observable chosenType;
  @observable profileTypes = new Map();

  @observable employee;
  
  @observable pendingEmployeeIds = false;
  @observable employeeIdsByKind = [];


  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async init() {
    this.isPendingData = true;
    await this.getProfileTypes();
    runInAction(() => {
      this.isPendingData = false;
    });
  }

  @action
  async getProfileTypes() {
    const typesData = await this.api.getProfileTypes();
    this.processProfileTypes(typesData);
  }

  @action
  processProfileTypes(data) {
    data.forEach((item) => {
      const profileType = new ProfileType(item, this);
      this.profileTypes.set(profileType.id, profileType);
    });
  }

  @action
  setEmployee(employee) {
    this.employee = employee.id;
  }

  @action
  setProfileType(type) {
    this.chosenType = type;
    this.getEmployeeIds();
  }

  @action
  async showProfile() {
    this.pendingProfiles.set(`${this.chosenType}-${this.employee}`, true);
    await this.getProfile(this.chosenType, this.employee);
    this.pendingProfiles.delete(`${this.chosenType}-${this.employee}`);
  }

  @action
  async getProfile(type, employeeId) {
    const profileData = await this.api.getProfile(employeeId, type.id);
    this.processProfile(type, employeeId, profileData);
  }

  @action
  async setProfile(employeeId, typeId, data) {
    await this.api.setProfile(employeeId, typeId, data);
  }

  @action
  async deleteProfile(employeeId, typeId) {
    await this.api.deleteProfile(employeeId, typeId);
  }

  @action
  async getEmployeeIds() {
    this.setPendingEmployeeIds(true);
    const ids = await this.api.getEmployeeIds(this.selectedTypeId);
    this.setEmployeeIds(ids);
    this.setPendingEmployeeIds(false);
  }

  @action
  processProfile(type, employeeId, data) {
    if (data) {
      const profile = new Profile(data, employeeId, type, this);
      this.addProfile(type, employeeId, profile);
    }
  }

  @action
  addProfile(type, employeeId, profile) {
    this.profiles.set(profile.id, profile);
    let employeeProfiles = this.employeeProfiles.get(employeeId);
    if (!employeeProfiles) {
      this.employeeProfiles.set(employeeId, new Map());
      employeeProfiles = this.employeeProfiles.get(employeeId);
    }
    employeeProfiles.set(type.id, profile.id);
  }
  
  @action
  setPendingEmployeeIds(pending = false) {
    this.pendingEmployeeIds = pending;
  }

  @action
  setEmployeeIds(ids = []) {
    this.employeeIdsByKind = ids;
  }

  @computed
  get isPending() {
    return this.isPendingData;
  }

  @computed
  get profileEmployeeIds() {
    return this.employeeIdsByKind.slice();
  }

  @computed
  get isPendingEmployeeIds() {
    return this.pendingEmployeeIds;
  }

  @computed
  get typesArray() {
    return Array.from(this.profileTypes.values());
  }

  @computed
  get canShowProfile() {
    return !!this.chosenType && !!this.employee;
  }

  @computed
  get chosenTypeConfig() {
    return this.chosenType && this.chosenType.config;
  }

  @computed
  get chosenProfile() {
    const employeeProfiles = this.employeeProfiles.get(this.employee);
    const profileId = employeeProfiles && employeeProfiles.get(this.selectedTypeId);
    return this.profiles.get(profileId);
  }


  @computed
  get selectedType() {
    return this.chosenType;
  }

  @computed
  get selectedTypeId() {
    return this.chosenType && `${this.chosenType.id}`;
  }

  @computed
  get hasPendingProfiles() {
    return !!this.pendingProfiles.size;
  }

  @computed
  get employeeIds() {
    return [this.employee];
  }
}
