import Coordinates from "~/common/models/Coordinates";
import { parseDateTime } from "~/common/datetime";
import { computed, observable, action, runInAction } from "mobx";
import AuditFact from "./AuditFact";

/**
 * Fact Model.
 */
export default class Audit {
  id;
  factId;
  @observable author;
  @observable point;
  @observable fact;

  @observable hasAuthor = false;
  @observable hasPoint = false;

  @observable isPendingFact = true;

  createdAt;
  start;
  finish;

  constructor(id, factId, authorId, pointId, createdAt, start, finish, store) {
    this.store = store;
    this.api = this.store.api;

    this.id = id;
    this.factId = factId;
    this.getAuthor(authorId);
    this.getPoint(pointId);
    this.getFact(this.id);
    this.createdAt = parseDateTime(createdAt);

    this.start = {
      datetime: parseDateTime(start.datetime),
      coords: new Coordinates(start.coords),
    };
    this.finish = {
      datetime: parseDateTime(finish.datetime),
      coords: new Coordinates(finish.coords),
    };
  }

  @action
  async getAuthor(id) {
    const author = await this.store.rootStore.employees.get(id);
    runInAction(() => {
      this.author = author;
      this.hasAuthor = true;
    });
  }

  @action
  async getPoint(id) {
    const point = await this.store.rootStore.points.addPoint(id);
    runInAction(() => {
      this.point = point;
      this.hasPoint = true;
    });
  }

  @action
  async getFact(id) {
    const factData = await this.api.getAuditFact(id);
    const fact = new AuditFact(factData, this.store);
    runInAction(() => {
      this.fact = fact;
      this.isPendingFact = false;
    });
  }

  @computed
  get data() {
    return {
      id: this.id,
      auditor: `${this.author.lastName} ${this.author.firstName}`,
      auditFact: {
        id: this.factId,
        reportCount: this.fact && this.fact.data.auditFact.reportCount,
        start: {
          datetime: this.start.datetime.format("DD.MM.YYYY HH:mm"),
        },
        finish: {
          datetime: this.finish.datetime.format("DD.MM.YYYY HH:mm"),
        },
        point: {
          name: this.point.name,
          code: this.point.code,
        },
      },
    };
  }

  @computed
  get isPending() {
    return this.isPendingFact || !(this.hasAuthor && this.hasPoint);
  }
}
