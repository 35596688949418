import React from "react";

import { observer } from "mobx-react";
import Text from "~/common/components/fields/Text";
import Select from "~/common/components/fields/Select";
import Date from "~/common/components/fields/Date";
import Number from "~/common/components/fields/Number";
import File from "~/common/components/fields/File";
import Bool from "~/common/components/fields/Bool";
import TableParam from "~/common/components/fields/Table";

const processField = (fieldConfig) => {
  let component = (<div key={fieldConfig && fieldConfig.id} >{fieldConfig && fieldConfig.type}</div>);
  if (fieldConfig) {
    if (fieldConfig.type === "list") {
      component = (<Select key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "text") {
      component = (<Text key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "number") {
      component = (<Number key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "file") {
      component = (<File key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "boolean") {
      component = (<Bool key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "table") {
      component = (<TableParam key={fieldConfig.id} config={fieldConfig} />);
    } else if (fieldConfig.type === "date") {
      component = (<Date key={fieldConfig.id} config={fieldConfig} />);
    }
  }

  return component;
};

@observer
class FieldSet extends React.Component {
  render() {
    const { fieldSet } = this.props;
    const { fieldsArray } = fieldSet;
    const fields = [];
    if (fieldsArray) {
      fieldsArray.forEach((fieldConfig) => {
        fields.push(processField(fieldConfig));
      });
    }
    const content = (
      <div className="page">
        {fields}
      </div>
    );
    return content;
  }
}

export default FieldSet;
export { processField };
