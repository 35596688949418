import React, { Component } from "react";
import classnames from "classnames";
import base64 from "base-64";
import { observer } from "mobx-react";
import { saveAs } from "file-saver";

import {
  IoMdCloudDownload,
  IoMdArrowBack,
  IoMdArrowForward,
} from "react-icons/io";

import { Button } from "~/common/components";

@observer
class PaginationComponent extends Component {
  constructor(props) {
    super();

    this.onDownloadButtonClicked = this.onDownloadButtonClicked.bind(this);
    this.applyPage = this.applyPage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.getSafePage = this.getSafePage.bind(this);

    this.state = {
      page: props.page,
    };
  }

  /**
   * Скачать файл с бекенда при нажатии на "Download"
   */
  onDownloadButtonClicked() {
    // TODO: эти два надо спросить у пользователя через popup
    const filename = "audits";
    const format = "xlsx";
    const downloadUrl = `/api/v4/audits/facts/${filename}.${format}`;
    const { uid, token } = this.props.userStore;
    fetch(downloadUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${base64.encode(`${uid}:${token}`)}`,
        Accept: "application/vnd.ms-excel",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        saveAs(blob, "audits.xlsx");
      });
    });
  }

  getSafePage(page) {
    let p = page;
    if (Number.isNaN(page)) {
      p = this.props.page;
    }
    return Math.min(Math.max(p, 0), this.props.pages - 1);
  }

  changePage(page) {
    const p = this.getSafePage(page);

    this.setState({ page: p });
    if (this.props.page !== p) {
      this.props.onPageChange(p, this.props.pageSize);
    }
  }

  applyPage(e) {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === "" ? this.props.page : page);
  }

  render() {
    const {
      pages,
      hideDownloadButton,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      showPageJump,
      onPageSizeChange,
      className,
    } = this.props;

    const canNext = page + 1 < pages;
    const canPrevious = page > 0;

    return (
      <div
        className={classnames(className, "-pagination")}
        style={this.props.style}
      >
        {!hideDownloadButton && (
          <Button
            className={"button active"}
            onClick={this.onDownloadButtonClicked}
            text="Download"
            leftIcon={<IoMdCloudDownload />}
          />
        )}
        <Button
          className={"button dload"}
          leftIcon={<IoMdArrowBack />}
          onClick={() => {
            if (!canPrevious) return;
            this.changePage(page - 1);
          }}
          disabled={!canPrevious}
        />
        <div className="-center">
          <div className="-pageInfo">
            {this.props.pageText}
            {showPageJump ? (
              <span className="-pageJump">
                <input
                  aria-label={this.props.pageJumpText}
                  type={page === "" ? "text" : "number"}
                  onChange={(e) => {
                    const val = e.target.value;
                    const page = val - 1;
                    if (val === "") {
                      return this.setState({ page: val });
                    }
                    this.setState({ page: this.getSafePage(page) });
                  }}
                  value={this.state.page === "" ? "" : this.state.page + 1}
                  onBlur={this.applyPage}
                  onKeyPress={(e) => {
                    if (e.which === 13 || e.keyCode === 13) {
                      this.applyPage();
                    }
                  }}
                />
              </span>
            ) : (
              <span className="-currentPage">{page + 1}</span>
            )}
            {" of "}
            <span className="-totalPages">{pages || 1}</span>
          </div>
          {showPageSizeOptions && (
            <div className="select-wrap -pageSizeOptions">
              <select
                aria-label={this.props.rowsSelectorText}
                onChange={(e) => {
                  return onPageSizeChange(Number(e.target.value));
                }}
                value={pageSize}
              >
                {pageSizeOptions.map((option, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={i} value={option}>
                      {`${option} ${this.props.rowsText}`}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </div>
        <Button
          className={"button dload"}
          leftIcon={<IoMdArrowForward />}
          onClick={() => {
            if (!canNext) return;
            this.changePage(page + 1);
          }}
          disabled={!canNext}
        />
      </div>
    );
  }
}

export default PaginationComponent;
