import React from "react";
import { observer, inject } from "mobx-react";

import GalleryStore from "./data/stores/GalleryStore";
import Photo from "~/common/components/Photo";
import RequestForm from "./components/RequestForm";
import SplitPane from "react-split-pane";
import DataModule from "./components/DataModule";

@inject("rootStore")
@observer
class Gallery extends React.Component {
  constructor(props) {
    super(props);

    this.store = new GalleryStore(props.rootStore);
  }

  componentDidMount() {
    this.store.init();
  }

  renderImages({ row }) {
    const photos = row.original && row.original._photos;
    const images = [];
    if (photos && photos.length) {
      photos.forEach((photo) => {
        images.push(
          <Photo
            key={photo.id}
            id={photo.id}
            name={photo.name}
            isThumb={true}
          />
        );
      });
      return <div className="Gallery-images-holder">{images}</div>;
    } else {
      return null;
    }
  }

  loader({ loading }) {
    return loading ? (
      <div className="points-preloader">
        <div />
      </div>
    ) : null;
  }

  render() {
    const { isPending, isPendingForm } = this.store;
    return (
      <div className="data-viewer gallery">
        <SplitPane split="vertical" defaultSize={"20em"} primary="first">
          {isPendingForm && <RequestForm galleryStore={this.store} />}
          {isPending && ( // TODO: нужно бы унести в Loading компонент
            <div className="points-preloader">
              <div />
            </div>
          )}
          {!isPending && <DataModule galleryStore={this.store} />}
        </SplitPane>
      </div>
    );
  }
}

export default Gallery;
