import { observable, action, runInAction } from "mobx";
import Monitoring from "../models/Monitoring";

/**
 * User Store.
 */
export default class MonStore {
  @observable mons = new Map();
  @observable isPending = false;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async get(id) {
    const mon = this.mons.get(id);
    if (mon) {
      return mon;
    } else {
      return await this.fetchMon(id);
    }
  }

  @action
  fabric(monData) {
    const newMon = new Monitoring(monData, false, this);
    this.mons.set(monData.id, newMon);
    return newMon;
  }

  @action
  async fetchMon(id) {
    if (!this.mons.get(id)) {
      this.isPending = true;
      const mon = this.fabric({ id, pending: true });
      const monData = await this.api.getMon(id);
      mon.setData(monData);
      runInAction(() => {
        this.isPending = false;
      });
      return mon;
    } else {
      return this.mons.get(id);
    }
  }
}
