import * as mobx from "mobx";
import Employee from "../models/Employee";

const { observable, action, runInAction, computed, toJS } = mobx;

export default class EmployeesStore {
  @observable isPending = false;
  @observable employees = new Map();

  @observable pendingItems = [];

  @observable userLevels = new Map();

  constructor(root) {
    this.api = root.api;
    this.root = root;

    if (root.user && root.user.isLoggedIn) {
      this.fetchEmployeers();
    }
  }

  @action
  async init() {
    if (this.root.user.isLoggedIn) {
      await this.fetchEmployeers();
    }
  }

  /**
   * Get Employee from store.
   *
   * @param {number} id
   */

  @action
  async get(id) {
    const employee = this.employees.get(id);
    if (employee) {
      return employee;
    } else {
      return await this.fetchEmployee(id);
    }
  }

  @action
  getSync(id) {
    const employee = this.employees.get(id);
    return employee;
  }

  @action
  async fetchEmployee(id) {
    this.pendingItems.push(id);
    try {
      const employeeData = await this.api.getEmployee(id);
      const { email, firstName, lastName, timezone } = employeeData;
      const quest = this.factory(id, email, firstName, lastName, timezone);
      return quest;
    } catch (error) {
      console.warn(error);
      runInAction(() => {
        this.pendingItems.remove(id);
      });
    }
  }

  /**
   * Create new Employee model instance.
   *
   * @param {number} id
   * @param {string} email
   * @param {string} firstName
   * @param {string} lastName
   */
  @action
  factory(id, email, firstName, lastName, timezone) {
    const employee = new Employee(
      id,
      email,
      firstName,
      lastName,
      timezone,
      this
    );
    this.employees.set(id, employee);
    this.pendingItems.remove(id);
    return employee;
  }

  @action
  processUserTree(data) {
    const obj = this.processUserLevel("root", data);
    this.userLevels.replace(obj);
  }

  @action
  processUserLevel(id, array) {
    const children = [];
    array.forEach((item) => {
      if (!item.leaf) {
        const obj = this.processUserLevel(item.name, item.data);
        children.push(obj);
      } else {
        const user = this.employees.get(item.userId);
        if (user) {
          children.push(user);
        } else {
          // children.push({ ...item, id: item.userId });
        }
      }
    });
    return { children, name: id };
  }

  @computed
  get usersRoot() {
    return toJS(this.userLevels);
  }

  /**
   * Fetch all employeers, create models and store them.
   */
  @action
  async fetchEmployeers() {
    try {
      this.isPending = true;
      const users = await this.api.getUsers();
      const userTree = await this.api.getEmploeesTree();

      users.forEach((emp) => {
        this.factory(
          emp.id,
          emp.email,
          emp.firstName,
          emp.lastName,
          emp.timezone
        );
      });

      this.processUserTree(userTree);

      runInAction(() => {
        this.isPending = false;
      });
      return this.employees;
    } catch (error) {
      runInAction(() => {
        this.isPending = false;
      });
      console.error(error);
    }
  }

  /**
   * Get employees as Array.
   */
  @computed
  get employeesArray() {
    return Array.from(this.employees.values());
  }

  @computed
  get hasPendingItems() {
    return this.pendingItems.length > 0 || this.isPending;
  }
}
