import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import SplitPane from "react-split-pane";

import {
  IoMdTimer,
  IoMdFlag,
  IoMdInformationCircleOutline,
  IoIosCheckmarkCircleOutline,
} from "react-icons/io";

import { observer, inject } from "mobx-react";

import ReportsInfo from "./ReportsInfo";
import MonsInfo from "./MonsInfo";
import PageInfo from "./PageInfo";
import CheckboxTable from "~/common/components/CheckboxTable";

import "react-tabs/style/react-tabs.css";

@inject(
  "userStore",
  "employeesStore",
  "pointsStore",
  "auditStore",
  "questStore",
  "monStore"
)
@observer
class Audits extends React.Component {
  isSelected = (key) => {
    const fact = this.props.auditStore.currentFact;
    return fact && fact.id === key;
  };
  columns = [
    {
      Header:   "ID",
      accessor: "id", // String-based value accessors!
      maxWidth: 30,
    },
    {
      Header:   "Employee",
      accessor: "auditor",
    },
    {
      Header:  "Point",
      columns: [
        {
          Header:   "Code",
          id:       "auditFactPointCode",
          accessor: "auditFact.point.code",
          maxWidth: 60,
        },
        {
          Header:   "Name",
          id:       "auditFactPointName",
          accessor: "auditFact.point.name",
        },
      ],
    },
    {
      Header: () => {
        return (
          <span>
            <IoMdFlag /> Audit Fact
          </span>
        );
      },
      id:      "auditFact",
      columns: [
        {
          Header: () => {
            return (
              <span>
                <IoMdTimer />
                Start
              </span>
            );
          },
          id:       "auditFactStartDatetime",
          accessor: "auditFact.start.datetime",
        },
        {
          Header: () => {
            return (
              <span>
                <IoMdTimer />
                Finish
              </span>
            );
          },
          id:       "auditFactFinishDatetime",
          accessor: "auditFact.finish.datetime",
        },
      ],
    },
    // {
    //   Header: "Report count",
    //   id: "auditFactReportCount",
    //   accessor: "auditFact.reportCount",
    // },
  ];
  constructor(props) {
    super(props);

    this.checkboxTable = React.createRef();

    this.toggleSelection = this.toggleSelection.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.getQuests = this.getQuests.bind(this);
    this.getMons = this.getMons.bind(this);
  }

  toggleSelection(row) {
    this.props.auditStore.setCurrentFact(row.id);
  }

  renderParam(param, val, auditVal) {
    let value = <div className="value">{val || ""}</div>;
    const hasAudit = !!(auditVal && auditVal.value);
    let aVal = hasAudit ? (
      <div className="audit-value">{auditVal && auditVal.value}</div>
    ) : null;
    if (param.type === "list") {
      let valuesRender = null;
      let aValRender = null;
      if (val) {
        valuesRender = val.split(",").map((val, i) => {
          return (
            <div key={i} className="list-value">
              {val}
            </div>
          );
        });
      }
      if (auditVal) {
        aValRender = auditVal.value.split(",").map((val, i) => {
          return (
            <div key={i} className="list-value">
              {val}
            </div>
          );
        });
      }
      value = <div className="value">{valuesRender}</div>;
      aVal = <div className="audit-value">{aValRender}</div>;
    } else if (param.type === "file") {
      let valuesRender = null;
      let aValRender = null;
      if (val) {
        valuesRender = (
          <div className="photo-value">
            <img alt={`${val}`} src={`../photo/${val}`} />
          </div>
        );
      }

      if (auditVal && auditVal.value) {
        aValRender = (
          <div className="photo-value">
            <img alt={`${aVal}`} src={`../photo/${auditVal.value}`} />
          </div>
        );
      }
      value = <div className="value">{valuesRender}</div>;
      aVal = <div className="audit-value">{aValRender}</div>;
    }
    return (
      <div
        key={param.id}
        id={param.id}
        className={`infoField ${hasAudit ? "" : "correct"}`}
      >
        <div className="label">{param.name}</div>
        {value}
        {hasAudit && aVal}
      </div>
    );
  }

  getQuests() {
    const { auditStore } = this.props;
    const data = [];
    const visitFact = auditStore.currentVisitFact;
    const audit = auditStore.currentFact;
    if (visitFact && audit) {
      const auditFact = audit.fact;

      if (visitFact && !visitFact.isPending) {
        const reports = visitFact.reportArray;
        if (reports) {
          reports.forEach((report) => {
            if (report.values.size > 0) {
              let allReportCorrect = true;

              const quest = report.quest;
              const auditFactReport = auditFact.questReports.get(quest.id);
              let auditVals = undefined;
              if (auditFactReport) {
                if (auditFactReport.values.size > 0) {
                  allReportCorrect = false;
                }
                auditVals = auditFactReport.values;
              }

              const values = report.valuesObj;

              const pages = [];

              quest.pagesArray.forEach((page) => {
                let allCorrect = true;
                const fields = [];

                page.paramsArray.forEach((param) => {
                  const auditParam = auditVals.get(`${param.id}`);
                  let auditParamVal = undefined;
                  if (auditParam) {
                    allCorrect = false;
                    auditParamVal = auditParam.parameter;
                  }
                  fields.push({
                    param,
                    value:      values[param.id],
                    auditValue: auditParamVal,
                  });
                });

                pages.push({
                  id:   page.id,
                  name: page.name,
                  allCorrect,
                  fields,
                });
              });

              data.push({
                id:     report.id,
                name:   quest && quest.name,
                start:  report.start,
                finish: report.finish,
                allReportCorrect,
                pages,
              });
            }
          });
        }
      }
    }
    return data;
  }

  getMons() {
    const { auditStore } = this.props;
    const data = [];

    const visitFact = auditStore.currentVisitFact;
    const audit = auditStore.currentFact;
    if (visitFact && audit) {
      const auditFact = audit.fact;

      if (visitFact && !visitFact.isPending) {
        const reports = visitFact.reportArray;
        if (reports) {
          reports.forEach((report) => {
            if (report.monitorings.size > 0) {
              report.monArray.forEach((monFact) => {
                const dir = monFact.directory;
                const mon = monFact.monitoring;

                let auditFactMon = undefined;
                if (auditFact && mon) {
                  auditFactMon = auditFact.monitoringReports.get(mon.id);
                }
                const items = [];
                let allMonCorrect = true;
                if (dir) {
                  dir.recordArray.forEach((record) => {
                    const itemId = record[dir.primaryKeyColumn];
                    const itemVals =
                      monFact.getValue(itemId) &&
                      monFact.getValue(itemId).values;
                    let auditRecordValues = undefined;

                    if (auditFactMon) {
                      auditRecordValues = auditFactMon.values.get(itemId);
                    }
                    mon.paramArray.forEach((param) => {
                      let val = undefined;
                      let auditVal = undefined;
                      if (itemVals && itemVals[param.id]) {
                        val = itemVals[param.id].value;
                      }
                      if (auditRecordValues && auditRecordValues[param.id]) {
                        allMonCorrect = false;
                        auditVal = auditRecordValues[param.id].parameter;
                      }

                      items.push({
                        id:   itemId,
                        name: record[dir.nameColumn],
                        info: dir.groups.map((gr) => {
                          return `${record[gr]} `;
                        }),
                        param,
                        value:      val,
                        auditValue: auditVal,
                      });
                    });
                  });
                }

                data.push({
                  id:     monFact.id,
                  name:   mon.name,
                  start:  report.start,
                  finish: report.finish,
                  allMonCorrect,
                  items,
                });
              });
            }
          });
        }
      }
    }
    return data;
  }
  renderCell({ row, column }) {
    const rowData = row.original;
    if (!rowData) {
      return null;
    }
    const isAudit = column.id !== "value";
    const data = isAudit
      ? rowData.auditValue && rowData.auditValue.value
      : rowData.value;
    let render = data || null;
    if (isAudit && data === undefined) {
      render = (
        <div className="audit-correct">
          <IoIosCheckmarkCircleOutline />
        </div>
      );
    }
    if (rowData.param.type === "list") {
      if (data) {
        render = data.split(",").map((val, i) => {
          return (
            <div key={i} className="value">
              <div key={i} className="list-value">
                {val}
              </div>
            </div>
          );
        });
      }
    } else if (rowData.param.type === "file") {
      if (data) {
        render = (
          <div className="value">
            <div className="photo-value">
              <img alt={`${data}`} src={`../photo/${data}`} />
            </div>
          </div>
        );
      }
    }
    return render;
  }

  render() {
    const { auditStore, employeesStore, questStore, monStore } = this.props;
    if (auditStore.isPending || employeesStore.hasPendingItems) {
      return (
        <div className="points-preloader">
          <div />
        </div>
      );
    } else {
      const checkboxProps = {
        toggleSelection: this.toggleSelection,
        selectType:      "radio",
      };

      const currentFact = auditStore.currentFact;
      const visitFact = auditStore.currentVisitFact;

      const questData = this.getQuests();
      const monData = this.getMons();

      const questTabPanels = [];
      const questTabs = questData.map((quest) => {
        questTabPanels.push(
          <TabPanel key={quest.id}>
            <PageInfo
              key={quest.id}
              data={quest.pages}
              renderCell={this.renderCell}
            />
          </TabPanel>
        );
        return (
          <Tab key={quest.id}>
            {quest.name}
            {quest.allReportCorrect && (
              <div className="tab-correct">
                <IoIosCheckmarkCircleOutline />
              </div>
            )}
          </Tab>
        );
      });

      const monTabPanels = [];
      const monTabs = monData.map((mon) => {
        monTabPanels.push(
          <TabPanel key={mon.id}>
            <MonsInfo
              key={mon.id}
              data={mon.items}
              renderCell={this.renderCell}
            />
          </TabPanel>
        );
        return (
          <Tab key={mon.id}>
            {mon.name}
            {mon.allMonCorrect && (
              <div className="tab-correct">
                <IoIosCheckmarkCircleOutline />
              </div>
            )}
          </Tab>
        );
      });

      const questInfo = <ReportsInfo />;

      const fact = (
        <Tabs>
          <TabList>
            <Tab>
              <div className="info-icon-tab">
                <IoMdInformationCircleOutline />
              </div>
              Reports Info
            </Tab>
            {questTabs}
            {monTabs}
          </TabList>
          <TabPanel>{questInfo}</TabPanel>
          {questTabPanels}
          {monTabPanels}
        </Tabs>
      );

      const hasTabs = questTabs.length + monTabs.length > 0;
      return (
        <div className="audits-holder">
          <SplitPane split="vertical" defaultSize={"30%"} primary="first">
            <div className="audit-table-holder">
              <CheckboxTable
                data={auditStore.auditFactsData}
                columns={this.columns}
                {...checkboxProps}
              />
            </div>
            <div className="audit-fact-holder">
              <div className="tabs-holder">
                {visitFact &&
                  !(
                    auditStore.isPendingAuditFactData ||
                    monStore.isPending ||
                    questStore.isPending ||
                    !hasTabs
                  ) && <div className="tabs">{fact}</div>}
                {currentFact &&
                  (auditStore.isPendingAuditFactData ||
                    monStore.isPending ||
                    questStore.isPending ||
                    !hasTabs) 
                    && (
                      <div className="points-preloader">
                        <div />
                      </div>
                    )}
              </div>
            </div>
          </SplitPane>
        </div>
      );
    }
  }
}

export default Audits;
