import React, { useCallback, useMemo } from "react";
import {
  IoMdCreate,
  IoMdInformationCircle,
  IoMdKeypad,
  IoMdList,
  IoMdDoneAll,
  IoMdRadioButtonOn,
  IoMdImage,
  IoMdSwitch,
  IoMdCalendar,
  IoMdBook,
  IoMdGrid,
  IoMdAdd,
  IoMdTrash,
  IoMdAddCircle,
} from "react-icons/io";
import { observer } from "mobx-react";

import { Button } from "~/common/components";
import { processField } from "~/common/components/FieldSet";
import Table from "~/common/components/Table";

const TableParam = observer((props) => {
  const { config } = props;
  const { label, isValid, isDisabled, required, columns, tableData } = config;

  const onDeleteLineClick = useCallback(
    (index) => {
      config.onDeleteLine(index);
    },
    [config]
  );

  const onToggleReverse = useCallback(() => {
    config.toggleReverse();
  }, [config]);

  const columnsArray = useMemo(() => {
    const cols = [
      {
        Header: (
          <div onClick={onToggleReverse} className="meta-header">
            #
          </div>
        ),
        id:       "index",
        maxWidth: 25,
        Cell:     ({ row }) => {
          return <div className="num-row">{row.index + 1}</div>;
        },
      },
    ];
    columns.forEach((param) => {
      let type = param.type;
      if (type === "list") {
        type = param.viewConfig;
      }
      switch (type) {
      case "linked":
      case "list_db":
        type = <IoMdInformationCircle />;
        break;
      case "number":
        type = <IoMdKeypad />;
        break;
      case "text":
        type = <IoMdCreate />;
        break;
      case "selectfield":
        type = <IoMdList />;
        break;
      case "multiselect":
        type = <IoMdDoneAll />;
        break;
      case "radiogroup":
        type = <IoMdRadioButtonOn />;
        break;
      case "file":
        type = <IoMdImage />;
        break;
      case "boolean":
        type = <IoMdSwitch />;
        break;
      case "date":
        type = <IoMdCalendar />;
        break;
      case "lib-material-link":
        type = <IoMdBook />;
        break;
      default:
        type = "";
        break;
      }

      cols.push({
        Header: (
          <div className="base-header">
            {type} {param.name}
          </div>
        ),
        id:       `${param.id}`,
        accessor: (d) => {
          return <div className="input-cell">{processField(d[param.id])}</div>;
        },
      });
    });
    cols.push({
      Header: (
        <div className="meta-header delete">
          <IoMdTrash />
        </div>
      ),
      width: "3em",
      id:    "delete",
      Cell:  ({ row }) => {
        const index = row.index;
        return (
          <Button
            key="delete-line"
            className={"button solo inline error delete"}
            onClick={() => {
              !isDisabled && onDeleteLineClick(index);
            }}
            disabled={isDisabled}
            leftIcon={<IoMdTrash />}
          />
        );
      },
    });
    return cols;
  }, [columns, isDisabled, onDeleteLineClick, onToggleReverse]);

  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  const onAddLineClick = () => {
    config.onAddLine();
  };

  const onAddLineTop = () => {
    config.onAddLine(true);
  };

  return (
    <div className={`input-div table ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">
            {required ? "*" : ""}
            {label}
          </div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder">
          <IoMdGrid />
        </div>
        <div onClick={onAddLineTop} className="icon-holder plain">
          <IoMdAddCircle />
        </div>
        <div className="table-input-holder">
          <Table
            className={`-striped -highlight input-table ${valid}`}
            style={{ width: "100%" }}
            sortable={false}
            multiSort={false}
            data={tableData}
            columns={columnsArray}
            showPagination={true}
            showPageJump={false}
          />
        </div>
      </div>
      <div className={"add-line"}>
        <Button
          key="add-line"
          className={"button inline"}
          onClick={(!isDisabled && onAddLineClick) || undefined}
          disabled={isDisabled}
          text="New line"
          leftIcon={<IoMdAdd />}
        />
      </div>
    </div>
  );
});

export default TableParam;
