import React, { useMemo } from "react";
import { IoMdSwitch } from "react-icons/io";
import { observer } from "mobx-react";

const Bool = observer((props) => {
  const { config } = props;
  const { label, value, isValid, changeValue, isDisabled, required } = config;

  const onClick = () => {
    const val = !value;
    changeValue(val);
  };
  
  const valid = useMemo(() => {
    if (isDisabled) {
      return "disabled";
    }
    if (isValid === true) {
      return "valid";
    } else  if (isValid === false) {
      return "invalid";
    }
    return "base";
  }, [isValid, isDisabled]);

  return (
    <div className={`input-div ${valid}`}>
      {label && (
        <div className="label-holder">
          <div className="label">{required ? "*" : ""}{label}</div>
        </div>
      )}
      <div className="input-holder">
        <div className="icon-holder bool">
          <IoMdSwitch />
        </div>
        <div className="bool-body" onClick={isDisabled ? undefined : onClick}>
          <div className="yes-text">Yes</div>
          <div className={`switch${value ? " yes" : ""}${isDisabled ? " disabled" : ""}`}>
            <div className="handle"></div>
          </div>
          <div className="no-text">No</div>
        </div>
      </div>
    </div>
  );
});

export default Bool;
