import { observable, action, runInAction } from "mobx";
import Quest from "../models/Quest";

/**
 * User Store.
 */
export default class QuestStore {
  @observable quests = new Map();
  @observable isPending = false;

  constructor(root) {
    this.rootStore = root;
    this.api = this.rootStore.api;
  }

  @action
  async get(id) {
    const quest = this.quests.get(id);
    if (quest) {
      return quest;
    } else {
      return await this.fetchQuest(id);
    }
  }

  @action
  fabric(questData) {
    const quest = new Quest(questData, this);
    this.quests.set(questData.id, quest);
    return quest;
  }

  @action
  async fetchQuest(id) {
    if (!this.quests.get(id)) {
      this.isPending = true;
      const quest = this.fabric({ id, pending: true });
      const questData = await this.api.getQuest(id);
      quest.fillData(questData);
      runInAction(() => {
        this.isPending = false;
      });
      return quest;
    } else {
      return this.quests.get(id);
    }
  }
}
