import { toJS, computed, observable, action } from "mobx";
import { uuid } from "uuidv4";

/**
 * Value Model.
 */
export default class Value {
  @observable id = uuid();
  @observable param;
  @observable clean;
  @observable dirty;
  store;

  constructor(value, store) {
    this.store = store;

    this.clean = value;
    this.dirty = value;
  }

  @action
  change(value) {
    this.dirty = value;
  }

  @action
  save() {
    this.clean = toJS(this.dirty);
  }

  
  @action
  revert() {
    this.dirty = toJS(this.clean);
  }

  @computed
  get result() {
    return this.clean;
  }

  @computed
  get temp() {
    return this.dirty;
  }

  valueToCheck(input) {
    if (!input) {
      return !!input;
    }
    if (input.id) {
      return `${input.id}`;
    }
    if (input.toISOString) {
      return input.toISOString();
    }
    if (input.map) {
      const ids = input.map((item) => {
        if (item.id) {
          return `${item.id}`;
        } else {
          const arr = Object.keys(item).map((id) => {
            return `${id}=${this.valueToCheck(item[id])}`;
          });
          return arr.join();
        }
      });
      return ids.sort().join();
    }
    return toJS(input);
  }

  @computed
  get isChanged() {
    let changed = true;
    if ((this.dirty && this.clean) || (!this.dirty && !this.clean)) {
      if (this.valueToCheck(this.dirty) === this.valueToCheck(this.clean)) {
        changed = false;
      }
    }
    return changed;
  }
}
