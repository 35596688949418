import React from "react";
import Select from "react-select";
import { observer } from "mobx-react";
/**
 * Filter Select Box
 */
@observer
class FilterSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const { onChange, filterId } = this.props;
    onChange(filterId, value);
  }
  render() {
    const {
      placeholder,
      valueKey,
      labelKey,
      value,
      filterId,
      options,
    } = this.props;
    return (
      <Select
        className="select-filter"
        name="filter"
        placeholder={placeholder}
        isMulti={true}
        valueKey={valueKey}
        labelKey={labelKey}
        value={value[filterId]}
        onChange={this.onChange}
        options={options}
      />
    );
  }
}

export default FilterSelect;
