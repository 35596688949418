class Server {
  constructor(root) {
    // const user = localStorage.getItem("managerUserId");
    // const token = localStorage.getItem("managerUserToken");
    this.root = root;
    this.points = {
      login:             "api/v4/auth/login",
      pointTree:         "manager/api/objects/tree",
      employeeTree:      "manager/api/employees/tree",
      getFeedData:       "manager/api/feed/settings",
      getJournalReports: "api/v4/journals/searches",
      file:              "api/files/upload/",
      loadImage:         (id) => {
        return `photo/${id}`;
      },
      loadThumb: (id) => {
        return `photo/thumbnail/${id}`;
      },
      users: (uid) => {
        return `api/v4/employees/${uid}/subordinates`;
      },
      userPoints: (uid) => {
        return `api/v4/employee/${uid}/points`;
      },
      statuses: () => {
        return "api/v4/planning/workday_statuses";
      },
      planningConfig: () => {
        return "api/v4/planning/config";
      },
      allPoints: "api/v4/planning/points",
      mapEvents: (userId) => {
        return `api/v4/employees/${userId}/visits/events/map`;
      },
      journals: (userId) => {
        return `api/v4/employees/${userId}/journals`;
      },
      journalReport: (reportId) => {
        return `api/v4/journals/reports/${reportId}`;
      },

      point: (pointId) => {
        return `api/v4/points/${pointId}`;
      },
      plans: (userId) => {
        return `api/v4/employee/${userId}/visits/plans`;
      },
      facts: (userId) => {
        return `api/v4/employee/${userId}/visits/facts`;
      },
      savePlan: (userId, id) => {
        return `api/v4/employee/${userId}/visits/plan/${id}/`;
      },
      createPlan: (userId, status) => {
        return `api/v4/employee/${userId}/visits/plans/${status}/`;
      },
      changeStatus: (userId, status) => {
        return `api/v4/employee/${userId}/visits/plans/${status}/`;
      },
      deletePlans: (userId) => {
        return `api/v4/employee/${userId}/visits/plans/`;
      },
      getAuditFacts: () => {
        return "api/v4/audits/facts";
      },
      getPoints: () => {
        return "api/v4/points";
      },
      getVisitFact: (factId) => {
        return `api/v4/visits/facts/${factId}`;
      },
      getReport: (reportId) => {
        return `api/v4/reports/${reportId}`;
      },
      getQuest: (questId) => {
        return `api/v4/quests/${questId}`;
      },
      getEmployee: (id) => {
        return `api/v4/employees/${id}`;
      },
      getDir: (id, revision) => {
        return `api/v4/directories/${id}/revisions/${
          revision ? revision : "latest"
        }`;
      },
      getMon: (id) => {
        return `api/v4/monitorings/${id}`;
      },
      getAuditFact: (id) => {
        return `api/v4/audits/facts/${id}`;
      },
      setAudit: (employeeId) => {
        return `api/v4/employees/${employeeId}/audits`;
      },
      getQuests: (userId) => {
        return `api/v4/employees/${userId}/quests`;
      },
      getMons: () => {
        return "api/v4/monitorings";
      },
      getDataViewerReports: (questId) => {
        return `api/v4/quests/${questId}/reports`;
      },
      getMonViewerReports: (monId) => {
        return `api/v4/monitorings/${monId}/reports`;
      },
      getMonViewerReportData: (monId, reportId) => {
        return `api/v4/monitorings/${monId}/reports/${reportId}`;
      },
      getProfileTypes: () => {
        return "api/v4/profiles/kinds";
      },
      getProfile: (employeeId, profileTypeId) => {
        return `api/v4/employees/${employeeId}/profiles/kinds/${profileTypeId}/instance`;
      },
      getAnswers:     "api/v4/activities/plans-and-answers",
      getEmployeeIds: (id) => {
        return `api/v4/profiles/kinds/${id}/employees`;
      },
      getActivities:  "/mobile/api/activities/list/for_user/",
      getPhotoParams: "manager/api/photo/parameters",
      getPhotos:      "manager/api/photo/photos",
    };
    this.domain = "../";
  }

  async login(email, password) {
    return new Promise((resolve, reject) => {
      if (!email || email === "") {
        reject("Login is required!");
      } else if (!password || password === "") {
        reject("Password is required!");
      } else {
        fetch(`${this.domain}${this.points.login}`, {
          method:  "POST",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        })
          .then((response) => {
            if (response.status === 403) {
              reject("Auth problem");
              // } else if (response.status === 500) {
              //   reject("Internal server error 500");
            }
            return response.json();
          })
          .catch((error) => {
            reject(error.message);
          })
          .then((json) => {
            if (json.success) {
              resolve(json.data);
            } else {
              reject(json.errors.join("&nbsp;"));
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      }
    });
  }

  async getUsers() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.users(this.root.user.uid)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPlans(uid, start, end, status) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.plans(uid)}${
          status ? `/${status}` : ""
        }?user=${this.root.user.uid}&token=${
          this.root.user.token
        }&from=${start.toISOString()}&to=${end.toISOString()}&withDone=yes`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getJournalReports(page, perPage, authors, journal, points, start, end) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getJournalReports}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "POST",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page,
            perPage,
            authors,
            points,
            journal,
            from: start.toISOString(),
            to:   end.toISOString(),
          }),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getFacts(uid, start, end) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.facts(uid)}?user=${
          this.root.user.uid
        }&token=${
          this.root.user.token
        }&from=${start.toISOString()}&to=${end.toISOString()}&withDone=yes`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getMapEvents(uid, start, end) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.mapEvents(uid)}?user=${
          this.root.user.uid
        }&token=${
          this.root.user.token
        }&from=${start.toISOString()}&to=${end.toISOString()}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async loadAnswers({ from, to, employeeIds, pointIds, activityId }) {
    const urlParams = new URLSearchParams();
    urlParams.set("user", this.root.user.uid);
    urlParams.set("token", this.root.user.token);
    from && urlParams.set("from", from.toISOString());
    to && urlParams.set("to", to.toISOString());
    employeeIds &&
      employeeIds.length &&
      urlParams.set("employee", employeeIds[0]);
    pointIds && pointIds.length && urlParams.set("point", pointIds[0]);
    activityId && urlParams.set("activity", activityId);

    return new Promise((resolve, reject) => {
      fetch(`${this.domain}${this.points.getAnswers}?${urlParams}`, {
        method:  "GET",
        headers: {
          Accept:         "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getActivities() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getActivities}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPhotoParams() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getPhotoParams}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPhotos(from, to, params, employees, poses) {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams();
      urlParams.set("user", this.root.user.uid);
      urlParams.set("token", this.root.user.token);
      urlParams.set("timezone", "local");
      from && urlParams.set("date_from", from);
      to && urlParams.set("to", to);
      params && urlParams.set("parameters", JSON.stringify(params));
      employees && urlParams.set("employees", JSON.stringify(employees));
      poses && urlParams.set("poses", JSON.stringify(poses));
      // date_to: 2022-07-29T00:00:00
      // date_from: 2018-07-01T00:00:00
      // timezone: local
      // parameters: [1489]
      // employees: [184]
      // poses: ["RU1607"]
      fetch(`${this.domain}${this.points.getPhotos}?${urlParams}`, {
        method:  "GET",
        headers: {
          Accept:         "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getJournals() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.journals(this.root.user.uid)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getJournalReport(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.journalReport(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  /**
   * Change plans status.
   *
   * @param {number} assignedId
   * @param {Array} ids
   * @param {string} status
   */
  async changeStatus(assignedId, ids, status) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.changeStatus(assignedId, status)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "PUT",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(ids),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async deletePlans(assignedId, ids) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.deletePlans(assignedId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "DELETE",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(ids),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async createPlan(assignedId, pointCode, date, startTime, finishTime, status) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.createPlan(assignedId, status)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "POST",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify([
            {
              pointCode,
              date,
              startTime,
              finishTime,
            },
          ]),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data.plans[0]);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async setAudit(employeeId, factIds) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.setAudit(employeeId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "POST",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            visitFacts: factIds,
          }),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  /**
   * Сохранить план на сервер.
   *
   * @param {number} planId
   * @param {number} pointId
   * @param {Moment} date
   * @param {Moment} startTime
   * @param {Momemt} finishTime
   * @param {string} status
   */
  async savePlan(planId, pointId, date, startTime, finishTime, status) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.savePlan(
          this.root.user.uid,
          planId
        )}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "PUT",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pointId,
            date,
            start:  startTime,
            finish: finishTime,
            status,
          }),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPoints() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getPoints()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data || {});
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getUserPoints(uid) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.userPoints(uid)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data || {});
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPointTree() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.pointTree}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data || []);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getEmploeesTree() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.employeeTree}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data || []);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getStatuses() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.statuses()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data.points || json.data || []);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPlanningConfig() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.planningConfig()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getPoint(pointId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.point(pointId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getAuditFacts() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getAuditFacts()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getVisitFact(factId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getVisitFact(factId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getReport(reportId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getReport(reportId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getEmployee(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getEmployee(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getQuest(questId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getQuest(questId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getDir(id, revision) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getDir(id, revision)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getMon(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getMon(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getAuditFact(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getAuditFact(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getDataViewerQuests() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getQuests(this.root.user.uid)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async getDataViewerReports(questId, params, from, to, page, perPage) {
    return new Promise((resolve, reject) => {
      let paramsString = "";
      if (params.length) {
        paramsString = `&param=${params.join("&param=")}`;
      }
      fetch(
        `${this.domain}${this.points.getDataViewerReports(
          questId
        )}?from=${from}&to=${to}&page=${page}&perPage=${perPage}&user=${
          this.root.user.uid
        }&token=${this.root.user.token}${paramsString}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getMonitoringViewerMons() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getMons()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getMonViewerReports(monId, from, to, page, perPage) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getMonViewerReports(
          monId
        )}?from=${from}&to=${to}&page=${page}&perPage=${perPage}&user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getMonViewerReporData(monId, reportId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getMonViewerReportData(
          monId,
          reportId
        )}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getProfileTypes() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getProfileTypes()}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async setProfile(userId, typeId, data) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getProfile(userId, typeId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "PUT",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async deleteProfile(userId, typeId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getProfile(userId, typeId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "DELETE",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getProfile(userId, type) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getProfile(userId, type)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          } else if (response.status === 404) {
            resolve({});
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getEmployeeIds(typeId) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getEmployeeIds(typeId)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          } else if (response.status === 404) {
            resolve([]);
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async getFeedData() {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.getFeedData}?user=${this.root.user.uid}&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            Accept:         "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            resolve(json.data);
          } else {
            reject(json.error);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
  async loadImage(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.loadImage(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.blob();
        })
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async uploadImage(file) {
    const body = new FormData();
    body.append("file", file);
    return new Promise((resolve, reject) => {
      fetch(`${this.domain}${this.points.file}`, {
        method:  "POST",
        headers: {
          Accept:        "application/json",
          Authorization: `Basic ${btoa(
            `${this.root.user.uid}:${this.root.user.token}`
          )}`,
        },
        body,
      })
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.json();
        })
        .then((json) => {
          resolve(json);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  async loadThumb(id) {
    return new Promise((resolve, reject) => {
      fetch(
        `${this.domain}${this.points.loadThumb(id)}?user=${
          this.root.user.uid
        }&token=${this.root.user.token}`,
        {
          method:  "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            reject("Token expired");
          } else if (response.status === 500) {
            reject("Internal server error 500");
          }
          return response.blob();
        })
        .then((blob) => {
          resolve(blob);
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
}

export default Server;
